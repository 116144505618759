import {Divider} from "@mui/material"
import {isNil} from "lodash"

import {CurrencyField} from "./../../Fields"
import CurrencyOutput from "./../../../Core/utils/CurrencyOutput"

import {Accordion} from "./../../Accordion"

const BudgetInput = (props) => {
    return (
        
        <CurrencyField  
            {...props}
            fullWidth
            margin="normal"
            variant="outlined"
        />
    )
}

const BudgetAccordion = (props) => {


    const hasKnownBudget = () => {
        return (!isNil(props.model.budget_known) && (props.model.budget_known !== "") )
    }

    return (
        <Accordion
                id="accordion-budget"
                summary = {`Budget : ${CurrencyOutput(props.model.value)}`}
                defaultExpanded = {true}
            >
              
              <BudgetInput label = "Known Budget" id = "budget-known" value = {props.model.budget_known ?? props.model.value ?? null}
                setValue = {(name, value) => {
                    props.model.set({"budget_known" : value})
                    props.model.save()
                }}
              />
              <Divider />
              <BudgetInput  
                label = "High Estimate Budget" 
                id = "budget-high-estimate" 
                value = {hasKnownBudget() ? "-" : props.model.budget_high_estimate } 
                disabled = {hasKnownBudget() } 
                setValue = {(name, value) => {
                    if(hasKnownBudget()){
                        return
                    }
                    props.model.set({"budget_high_estimate" : value})
                    props.model.save()
                }}
                />
              <BudgetInput 
                label = "Low Estimate Budget" 
                id = "budget-low-estimate" 
                value = {hasKnownBudget() ? "-" : props.model.budget_low_estimate } 
                disabled = {hasKnownBudget()}
                setValue = {(name, value) => {
                    if(hasKnownBudget()){
                        return
                    }
                    props.model.set({"budget_low_estimate" : value})
                    props.model.save()
                }}
            />
        </Accordion>
    )
}

export {BudgetAccordion}
export default BudgetAccordion