import {useState} from "react"
import {Box, Typography, Alert, Button, IconButton, Stack, Divider} from "@mui/material"
import { Edit } from "@mui/icons-material";
import { AutoP } from "./../Utilities"

import { getCurrentUser } from "../../Core/Redux/app-slice";
import {useSelector} from "react-redux"


import Select from "./../Fields/Select"

import {OPPORTUNITY_STATUS_LOST} from "portal-constants/dist/OpportunityStatuses"

import {NoteInput, Notes} from "./../Notes"

const RecordGroup = (props) => {

    const showActionIcon = props.showActionIcon
    const showNoContent = !props.hasContent

    

    return (
        <Box sx={{
            marginTop: 0,
            marginBottom: 8
        }}>
            <Stack
                 direction = "row"
                 justifyContent="space-between"
                 alignItems =  "center"
            >
                <Typography
                    variant = "h5"
                    gutterBottom
                >
                    {props.title}

                    {showActionIcon && (
                        props.ActionIcon
                    )}   

                </Typography>

                {props.AddControl}

            </Stack>
            <Divider />

            <Box sx={{
                marginTop: 2,
                marginBottom: 2
            }}>
                {showNoContent && props.NoContent }
                {!showNoContent && props.children }
            </Box>
            
        </Box>
    )
}

const DealRecords = (props) => {

    const [showDescriptionForm, setShowDescriptionForm] = useState(false);
    const [showLostForm, setShowLostForm] = useState(false);
    const [showNoteForm, setShowNoteForm] = useState(false);

    const currentUser = useSelector(getCurrentUser)

	console.log("currentUser", currentUser)

    const LossReasons = [
        {
            value : "1",
            title   : "Money"
        },
        {
            value : "2",
            title   : "Time"
        },
        {
            value : "3",
            title   : "People"
        }
    ]

    console.log(props.model.description)
    return (
        <Box
            sx = {{ 
                flexGrow: 1,
                padding: '2rem',
                overflow: "scroll"
            }}
        > 
            
            {props.model.hasStatus(OPPORTUNITY_STATUS_LOST) && (
                <RecordGroup 
                    title="Loss Reason"
                    hasContent = {props.model.loss_reason || showLostForm || false}
                    showActionIcon = { props.model.loss_reason }
                    AddControl = {<IconButton aria-label="edit" size="small" onClick = { () => {
                        setShowLostForm(true)
                    }}>
                        <Edit />
                    </IconButton>}
                    NoContent = {
                        <Alert 
                            severity="error"
                            sx = {{ marginTop: 2 }}
                            action={
                                <Button color="inherit" size="small" onClick = { () => {
                                    setShowLostForm(true)
                                }}>
                                    Add Loss Reason
                                </Button>
                            }
                        >This Opportunity is marked as lost but no reason is recorded. Would you like to add one?</Alert>
                    }
                >
                    {props.model.loss_reason && !showLostForm && (
                        <Stack direction="column" spacing={2}>
                            <Alert 
                                severity="error"
                                sx = {{ marginTop: 2 }}
                            >
                                <Typography fontWeight={700} component="span">Loss Reason: </Typography>{LossReasons.find(r => r.value === props.model.loss_reason).title}
                            </Alert>
                            {props.model.loss_reason_comment && (
                                <Typography>
                                    {props.model.loss_reason_comment}
                                </Typography>
                            )}
                        </Stack>
                    )}

                    {showLostForm && (
                        <>
                        <Select
                            label = "Loss Reason"
                            options = { LossReasons }
                            values = {props.model.loss_reason}
                            setValue =  { (name, value) => {
                                props.model.set({"loss_reason" : value})
                                props.model.save()
                                console.log(props.model)
                            }}
                        />
                        <NoteInput
                            value = {props.model.loss_reason_comment}
                            onSave = { (value) => {
                                props.model.set({"loss_reason_comment" : value})
                                props.model.save()
                                setShowLostForm(false)
                            }}
                            onClose = { () => {
                                setShowLostForm(false)
                            }}
                            label = "Loss Reason Comment"
                        />
                        </>
                    )}
                </RecordGroup>
            )}

            <RecordGroup 
                title="Description" 
                AddControl = {<IconButton aria-label="edit" size="small" onClick = { () => {
                    setShowDescriptionForm(true)
                }}>
                    <Edit />
                </IconButton>}
                hasContent = {props.model.description || showDescriptionForm || false}
                NoContent = {
                    <Alert 
                        severity="info"
                        sx = {{ marginTop: 2 }}
                        action={
                            <Button color="inherit" size="small" onClick = { () => {
                                setShowDescriptionForm(true)
                            }}>
                                Add Description
                            </Button>
                        }
                    >This opportunity has no description.</Alert>
                }

                showActionIcon = { props.model.description}
                ActionIcon = {null}
            >	

                {(props.model.description) && (
                    <Typography>
                        {props.model.description} 
                    </Typography>
                )}

               
                {showDescriptionForm && (
                    <NoteInput
                        value = {props.model.description}
                        onSave = { (value) => {
                            props.model.set("description", value)
                            props.model.save()
                            setShowDescriptionForm(false)
                        }}
                        onClose = { () => {
                            setShowDescriptionForm(false)
                        }}
                    />
                )}

            </RecordGroup>

            <RecordGroup 
                title="Notes" 
                AddControl = {
                    <Button onClick = { () => {
                        setShowNoteForm(true)
                    }}>
                        Add Note
                    </Button>
                }
                NoContent = {<Typography>No Notes</Typography>}
                hasContent = {props.model.notes || showNoteForm || false}
            >
            {props.model.notes && (
                <Notes
					notes = {props.model.notes}
				/>
            )}

             {showNoteForm && (
                    <NoteInput
                        value = {""}
                        onSave = { (value) => {
                            props.model.addNote(value, currentUser)
                            setShowNoteForm(false)
                        }}
                        onClose = { () => {
                            setShowNoteForm(false)
                        }}
                    />
                )}   
            </RecordGroup>

        </Box>

    )
}

export {DealRecords}
export default DealRecords