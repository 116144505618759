import { Row } from "."
import { TableContainer, Table , TableBody, Paper } from "@mui/material"

const InfoTable = (props) => {

    return (
        <TableContainer component={Paper} sx={{
            mb : '2rem'
        }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                    { props.rows.map( (row, index) => (
                        <Row 
                            label = {row.label} 
                            value = {row.value}
                            key = {index}
                        />
                   ) )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default InfoTable
export {InfoTable}
