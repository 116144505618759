//@ts-nocheck
import { Box } from "@mui/material"

import { Frame } from "./Frame"
import {Sidebar} from "./Sidebar"

const SidebarFrame = (props : {
  title : string
  controls ?: JSX.Element
  children ?: JSX.Element|JSX.Element[];
  Sidebar ?: JSX.Element;
}) => {

    const UsedSidebar = (props.Sidebar ?? Sidebar)
    return (
        <Frame>
            <Box sx={{display: "flex", height:"100%"}} id="sidebar-frame">
                
                <UsedSidebar />
                <Box component="main" sx={{ flexGrow: 1, overflow: "hidden", flexDirection: "column", display:"flex"}}>
                    {props.children}
                </Box>
            </Box>
        </Frame>
    )
}

export default SidebarFrame
export {SidebarFrame}