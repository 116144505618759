"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.RetainerPeriods = void 0;
const RetainerPeriods = [{
  value: 0,
  title: "Weekly"
}, {
  value: 1,
  title: "Monthly"
}, {
  value: 2,
  title: "Quarterly"
}, {
  value: 3,
  title: "Yearly"
}];
exports.RetainerPeriods = RetainerPeriods;
var _default = RetainerPeriods;
exports.default = _default;