//@ts-nocheck

// util 
import {useState} from "react"

// data
import {Company} from "portal-datatypes"

// mui
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';

// components
import {Archive, Frame} from "../../Components";

import { Companies as CompanyColumns } from "./../../Components/DataGrids/Columns/Companies"

import { AddItemControl } from './../../Components/Form/DrawerControl';
import { AddCompanyDrawer } from "./../../Components/Forms/AddCompany"
import { EditCompanyDrawer } from "./../../Components/Forms/EditCompany"
import { EditMultipleCompaniesDrawer } from "./../../Components/Forms/EditMultipleCompanies"

import {contactMenu} from "./../../Core/Navigation"


const Companies = ( props : any ) => {
	
	const [visibilityModel, setVisibilityModel] = useState(props.columnVisibilityModel ?? {
		[GRID_CHECKBOX_SELECTION_COL_DEF.field] : true,
		title: true,
		type: true,
		actions: true,
		id : false,
		value : false,
		invoiced : false,
		received : false,
		unpaid : false,
		to_invoice: false
	})

	//const [type] = useState([ProjectTypesTaxonomy[props.match?.params?.type]?.value ?? props.type ?? ProjectStatusesTaxonomy.BUILD])

	let query = {
		type : [props.type ?? -1]
	}
	
	console.log(query)
    return (
		<Frame>
        <Archive
			Model = { Company }
			title = {props.title ?? "Companies"}
	  		titleMenu = {contactMenu.items}
			query = { query }
			controls = {(
				<AddItemControl 
					label = "Add Company"
					form = {AddCompanyDrawer}
				/>
			)}
			gridProps = {{
				EditMultipleItemDrawer : EditMultipleCompaniesDrawer,
				EditItemDrawer : EditCompanyDrawer,
				columns : (props.columns ?? CompanyColumns),
				columnVisibilityModel : visibilityModel,
				onColumnVisibilityModelChange : (newModel) => {
					setVisibilityModel(newModel)
				},
				disableColumnMenu : false,
			}}
        />
		</Frame>
    )
}

export default Companies
export {Companies}