
import {useMenu, Menu as MUIMenu, MenuItem} from "./../Menu"

import { Button, Typography} from "@mui/material"
import { KeyboardArrowDown } from "@mui/icons-material"

const HeaderPickMenu = (props : any) => {

	const {
		variant = "h4"
	} = props


	return (
		<Button
			variant="text"
			disableElevation
			endIcon={<KeyboardArrowDown />}
			onClick={props.onClick}
		>
			<Typography variant={variant}>{props.label}</Typography>
		</Button>
	)
}

const Menu = (props : any ) => {

	const {items, title} = props
    const menu = useMenu()


    return(
        <MUIMenu
            label = {title}
			TriggerComponent = { props.HeaderPickMenu ?? HeaderPickMenu }
            {...menu}
        >
            {items.map( (item : any, key : string) => (
                <MenuItem to={item.path} divider={item.divider ?? false} key={key}>
                    {item.title}
                </MenuItem>
            ) ) }
        </MUIMenu>
    )
}

export default Menu
export {Menu}