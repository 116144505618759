//@ts-nocheck
import {Projects as Columns} from "../../Components/DataGrids/Columns/Projects"
import Projects from "./Projects";
   
import { AddItemControl } from '../../Components/Form/DrawerControl';
import { AddRetainerDrawer } from '../../Components/Forms/AddRetainer';

const RetainerHeaderControls = () => (
	<>
	  <AddItemControl 
		  label = "Add Retainer"
		  form = {AddRetainerDrawer}
	  />
	</>
)

const Retainers = ( props : any ) => {

	
	return (
		<Projects
	  		title = "Retainers"
	  		columns = {Columns}
			controls = {(<RetainerHeaderControls />)}
	  		{...props}
		/>    
  	)
}

export default Retainers
export {Retainers}