//@ts-nocheck
import React from "react"
import {HZNForm} from "../Form/Form"

import {Company as Model} from "portal-datatypes" 
import { withFormDrawer } from "../Form/Drawer"

const AddCompany = (props : any) => {
    return(
      <HZNForm  
          Model = {Model}
          fields = {Model.getFormFields()}
          title = "Create a New Company"
          buttonText = "Add Company"
          {...props}
          action = "save"
          successMessage = "Company Added"
          showAddAnotherButton = {true}
      />
    )
}

const AddCompanyDrawer = withFormDrawer(AddCompany)

export default AddCompany
export {AddCompany, AddCompanyDrawer}