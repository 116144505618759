import {isUndefined, isEmpty, isArray, isFunction} from 'lodash';
import {createGettersFromSchema, withGetRelated, withSchema } from 'portal-model'

import {Routes} from "./Navigation/Routes"
import {createFirebaseSlices, addFirebaseSlicesToStore, populateFromRemote} from "./Redux"
import Features from "./../Features"
import {FireStore} from "./Firebase"

import dataTypes from "portal-datatypes"

let instance = null;

class App {

	constructor(args = {}) {

	    if(instance){
            return instance;
    	}

        this.FireBase = args.FireBase ?? null
        this.Store = args.Store ?? null
    	this.Models = [...dataTypes]

		this.ActionRoutes = {}
		this.routes = []

		this.Auth = args.Auth ?? null
		this.Integrations = args.Integrations ?? null
		
		
        this.composeModels()
        this.createStoreFromFireBase()
		

        instance = this
    	return instance;

  	}

    createStoreFromFireBase(){
        let firebaseSlices = createFirebaseSlices(this.Models, FireStore)
        addFirebaseSlicesToStore(firebaseSlices)
        this.populateFromRemote(firebaseSlices)
    }

    getStore(){
        return this.Store
    }

  	composeModels(){
  		this.Models.forEach( (Model) => {
  			withSchema(Model)
			createGettersFromSchema(Model)
  			withGetRelated(Model)
  		} )
  	}

    getRoutesFromFeatures(){
    
        let routes = []

        Object.keys(Features).forEach( (key) => {
            let feature = Features[key]

            if(isUndefined(feature.routes)){
				return
			}

			if(!isArray(feature.routes)){
				return
			}

			if(isEmpty(feature.routes)){
				return
			}
			routes = routes.concat(feature.routes)
        })

        return routes
    }

	getRoutesFromModels(){

		let routes = []

		this.Models.forEach( (model) => {

			if(isFunction(model.loadRoutes)){
				model.loadRoutes()
			}
				
			if(isUndefined(model.routes)){
				return
			}

			if(!isArray(model.routes)){
				return
			}

			if(isEmpty(model.routes)){
				return
			}

			routes = routes.concat(model.routes)
		})

		return routes
	}
	
	getAppRoutes(){
		return Routes
	}

	getActionTriggerRoutes(){
		return this.ActionRoutes
	}

    async populateFromRemote(firebaseSlices){
        populateFromRemote(firebaseSlices)
    }
}

export default App
