import { Avatar } from "@mui/material"
import { isEmpty } from "lodash"
const CompanyIcon = ( props ) => {
    
    const {company, size = 40} = props

    if(isEmpty(company)){
        return null
    }
    let size_args = {
        width: 40,
        height: 40,
    }

    if(size === "small"){
        size_args = {
            width: 24,
            height: 24,
        }
    } 

    if(company.hasIcon()){
        return (<Avatar src={company.getIconSrc()} alt={company.title} sx={{marginRight: 1, ...size_args}}/>)
    }
    
    return null
}

export {CompanyIcon}
export default CompanyIcon