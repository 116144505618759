"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createGettersFromSchema = createGettersFromSchema;
exports.default = void 0;

var _lodash = require("lodash");

var _pluralize = _interopRequireDefault(require("pluralize"));

function createGettersFromSchema(Model) {
  // no schema? just return the model
  if ((0, _lodash.isUndefined)(Model.schema)) {
    return Model;
  }

  let schema = Model.schema;
  let keys = Object.keys(schema);
  let proto = Model.prototype; //if the schema turned out to be empty, return the normal model

  if (keys.length < 1) {
    return Model;
  }

  const capitalize = s => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  keys.forEach(key => {
    let prop = Model.schema[key];

    if (proto.hasOwnProperty(key)) {
      return;
    }

    if (proto.hasOwnProperty((0, _pluralize.default)(key))) {
      return;
    }

    if (prop.type === "attr") {
      Object.defineProperty(proto, key, {
        get: function () {
          return this.data[key];
        }
      });
    } else if (prop.type === "relationship") {
      Object.defineProperty(proto, (0, _pluralize.default)(key, 0), {
        get: function () {
          return this.getRelated(key);
        }
      });
    } else if (prop.type === "picked") {
      Object.defineProperty(proto, key, {
        get: function () {
          return this.getPickedItems(this.data[key], prop.options);
        }
      });
      Object.defineProperty(proto, "has" + capitalize(key), {
        value: function (value) {
          return this.hasPickedItem(value, this.data[key], prop.options);
        }
      });
      Object.defineProperty(proto, key + "Keys", {
        get: function () {
          return this.getPickedItemsKeys(this.data[key], prop.options);
        }
      });
    }
  });
  return Model;
}

var _default = createGettersFromSchema;
exports.default = _default;