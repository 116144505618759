import { Button } from "@mui/material"
import withDrawerControl from "../../Hooks/withDrawerControl"

const FormDrawerTrigger = (props : {
    label : string,
    toggleDrawer : (event : any) => void,
    drawerOpen: boolean,
    form : React.FunctionComponent<any>,
    item : any,
    formProps ?: any
    ButtonProps ?: object
  }) => {
    
    return (
      <>
        <Button
          variant="contained"
          onClick={ props.toggleDrawer }
		  disableElevation
          {...props.ButtonProps}
        >
          {props.label}
        </Button>
  
        <props.form
          isOpen = { props.drawerOpen } 
          onClose = { props.toggleDrawer } 
          item = {props.item}
          {...props.formProps ?? null}
        />
  
      </>
    )
  }
  
  const AddItemControl = withDrawerControl(FormDrawerTrigger)

  export default FormDrawerTrigger
  export {FormDrawerTrigger, AddItemControl}