import {Route as RouteType} from "./../Core/types"

import {Clients} from '../Views/Companies/Clients'
import {Companies as ListView} from '../Views/Companies/Companies'
import {Company as SingleView} from '../Views/Companies/Company'
import {Prospects} from '../Views/Companies/Prospects'
import {Alumni} from '../Views/Companies/Alumni'
import {Leads} from '../Views/Companies/Leads'
import {Targets} from '../Views/Companies/Targets'
import {Partners} from '../Views/Companies/Partners'
import {Suppliers} from '../Views/Companies/Suppliers'


const Routes: Array<RouteType> = [
    {
        "path" : "/companies/clients",
        "view" : Clients,
        "extraProps" : {
            "type" : 0
        }
    },
    {
        "path" : "/companies/prospects",
        "view" : Prospects,
        "extraProps" : {
            "type" : 2
        }
    },
    {
        "path" : "/companies/leads",
        "view" : Leads,
        "extraProps" : {
            "type" : 3
        }
    },
    {
        "path" : "/companies/targets",
        "view" : Targets,
        "extraProps" : {
            "type" : 4
        }
    },
    {
        "path" : "/companies/partners",
        "view" : Partners,
        "extraProps" : {
            "type" : 6
        }
    },
    {
        "path" : "/companies/suppliers",
        "view" : Suppliers,
        "extraProps" : {
            "type" : 5
        }
    },
    {
        "path" : "/companies/alumni",
        "view" : Alumni,
        "extraProps" : {
            "type" : 1
        }
    },
    {
        "path" : "/companies/:guid",
        "view" : SingleView
    },
    {
        "path" : "/companies",
        "view" : ListView
    },
]

const Companies = {
    routes : Routes
} 

export {Companies}