//@ts-nocheck

// components
import { Prospects as ProspectsColumns } from "./../../Components/DataGrids/Columns/Prospects"

import {Companies} from "./Companies"

const Leads = ( props ) => {

    return (
        <Companies
            title = "Leads"
            columns = {ProspectsColumns}
            {...props}
        />
    )

}

export default Leads
export {Leads}