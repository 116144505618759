import { Typography } from "@mui/material"
import { DataGridPro } from "@mui/x-data-grid-pro"
import ProjectInvoiceColumns from "../DataGrids/Columns/ProjectInvoiceColumns"
import {isUndefined} from "lodash"

const ProjectInvoicesTable = (props : {
    model : any
}) => {
    let {model} = props
    return (
        <>
            <Typography
                component = "h2"
                variant="h4"
                gutterBottom
            >
                Invoices
            </Typography>

            { (isUndefined(model.invoiceCollection) === false) && (
                <DataGridPro
                //@ts-ignore
                rows={model.invoiceCollection}
                columns={ProjectInvoiceColumns}
                pageSize={25}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                autoHeight
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
            />
            ) }
        </>
    )
}

export { ProjectInvoicesTable }
export default ProjectInvoicesTable