//@ts-nocheck
import React from "react"
import {HZNForm} from "../Form/Form"

import {Opportunity as Model} from "portal-datatypes" 
import { withFormDrawer } from "../Form/Drawer"

const AddOpportunity = (props : any) => {
    return(
      <HZNForm  
          Model = {Model}
          fields = {Model.getFormFields()}
          title = "Create a New Opportunity"
          buttonText = "Add Opportunity"
          {...props}
          action = "save"
          successMessage = "Opportunity Added"
          showAddAnotherButton = {true}
      />
    )
}

const AddOpportunityDrawer = withFormDrawer(AddOpportunity)

export default AddOpportunity
export {AddOpportunity, AddOpportunityDrawer}