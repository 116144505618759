"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Invoice = void 0;

require("core-js/modules/es.array.includes.js");

require("core-js/modules/web.dom-collections.iterator.js");

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = require("lodash");

var yup = _interopRequireWildcard(require("yup"));

var _portalModel = require("portal-model");

var _Projects = require("./../Projects");

var _Companies = require("./../Companies");

var _Slice = _interopRequireDefault(require("./Slice.js"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//@ts-nocheck
//external
// core
// feature
class Invoice extends _portalModel.Model {
  // these make typescript stop complaining
  // TODO : Make Model parent typescript and use these better
  static loadRoutes() {//this.routes = require("./Routes").Routes
  }

  static loadSchema() {
    this.schema = require("./Schema.js").Schema;
  } // I'm overriding the constructor at the moment 
  // to stop the getters being get from the data array


  constructor(args) {
    super(args, false);
  }

  get value() {
    var _ref, _this$data$value;

    //@ts-ignore
    return Math.max((_ref = (_this$data$value = this.data.value) !== null && _this$data$value !== void 0 ? _this$data$value : this.data.total_value) !== null && _ref !== void 0 ? _ref : 0, 0);
  }

  get paid() {
    //@ts-ignore
    return this.data.paid_value;
  }

  get due() {
    var _this$data$due_value;

    //@ts-ignore
    if (this.status === "Part written-off") {
      return 0;
    } //@ts-ignore


    if (this.status === "Written-off") {
      return 0;
    } //@ts-ignore


    return Math.max((_this$data$due_value = this.data.due_value) !== null && _this$data$due_value !== void 0 ? _this$data$due_value : 0, 0);
  }

  static attachToProjects() {
    console.log("Hiya");
    console.log(this);
    const invoices = this.getAll()();
    console.log(invoices);
    invoices.toArray().filter(invoice => {
      if ((0, _lodash.isUndefined)(invoice.freeagent)) {
        return false;
      }

      if ((0, _lodash.isUndefined)(invoice.freeagent.project)) {
        return false;
      }

      return true;
    }).forEach(invoice => {
      // get the project to attach based on the freeagent project id
      const projects = _Projects.Project.getByAttr("freeagent.id", invoice.freeagent.project)();

      let project = projects.first(); // if the project doesn't have an invoices object then create it

      if ((0, _lodash.isUndefined)(project.data.invoices)) {
        project.data.invoices = {};
      } // store the invoice object on the project


      project.data.invoices[invoice.id] = true; // if the project doesn't have an invoices object then create it

      if ((0, _lodash.isUndefined)(invoice.data.projects)) {
        invoice.data.projects = {};
      }

      if (invoice.data.projects["undefined"]) {
        delete invoice.data.projects["undefined"];
      }

      console.log(invoice); // store the ID of the project on the invoice

      invoice.data.projects[project.id] = true; // save projects and invoice to redux

      invoice.save();
      project.save();
    });
  }

  static attachToCompanies() {
    console.log("Hiya");
    console.log(this);
    const invoices = this.getAll()();
    console.log(invoices);
    invoices.toArray().filter(invoice => {
      if ((0, _lodash.isUndefined)(invoice.freeagent)) {
        return false;
      }

      if ((0, _lodash.isUndefined)(invoice.freeagent.contact)) {
        return false;
      }

      return true;
    }).forEach(invoice => {
      console.log(invoice); // get the project to attach based on the freeagent project id

      const companies = _Companies.Company.getByAttr("freeagent.id", invoice.freeagent.contact)();

      let company = companies.first();
      console.log(company);

      if ((0, _lodash.isUndefined)(company)) {
        return;
      } // if the project doesn't have an invoices object then create it


      if ((0, _lodash.isUndefined)(company.data.invoices)) {
        company.data.invoices = {};
      } // store the invoice object on the project


      company.data.invoices[invoice.id] = true; // if the project doesn't have an invoices object then create it

      if ((0, _lodash.isUndefined)(invoice.data.company)) {
        invoice.data.company = {};
      }

      if (invoice.data.company["undefined"]) {
        delete invoice.data.company["undefined"];
      } // console.log(project)
      // store the ID of the project on the invoice


      invoice.data.company[company.id] = true; // save projects and invoice to redux

      company.save();
      invoice.save();
    });
  }

  static dedupe() {
    console.log("dedupe");
    const invoices = this.getAll()();
    console.log(invoices.toArray());
    let filtered = {};
    let to_remove = {};
    invoices.toArray().forEach(invoice => {
      if ((0, _lodash.isUndefined)(filtered[invoice.freeagent.id])) {
        filtered[invoice.freeagent.id] = invoice;
      } else {
        to_remove[invoice.id] = invoice;
      }
    });
    console.log(filtered);
    let new_invoices = {};
    Object.keys(filtered).forEach(key => {
      new_invoices[filtered[key].id] = filtered[key];
    });

    for (let key in to_remove) {
      console.log(to_remove[key]);
      to_remove[key].delete();
    }
  }

  get title() {
    let ref = this.convert_mixed_reference_to_numbers_only(this.data.reference);
    return "#" + ref.toString().padStart(5, "0");
  }

  get issued_on() {
    return this.data.dated_on;
  }

  convert_mixed_reference_to_numbers_only(ref) {
    const regex = /([0-9]+)/gm;
    let m;
    let calculated;

    while ((m = regex.exec(ref)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      } // The result can be accessed through the `m`-variable.


      m.forEach((match, groupIndex) => {
        calculated = match;
      });
    }

    return parseInt(calculated).toString();
  }

  static attachToCompanies() {
    const invoices = this.getAll()().toArray();
    console.log(invoices);
    let promises = invoices.filter(invoice => {
      if ((0, _lodash.isUndefined)(invoice.data.company)) {
        return false;
      }

      return true;
    }).map(invoice => {
      const company = _Companies.Company.get(invoice.data.company)();

      if ((0, _lodash.isEmpty)(company.data.invoices)) {
        company.data.invoices[0] = invoice.id;
        return company.save();
      } else if ((0, _lodash.isUndefined)(company.data.invoices[0])) {
        company.data.invoices[0] = invoice.id;
        return company.save();
      } else if ((0, _lodash.isObject)(company.data.invoices[0])) {
        console.log(company.data.invoices[0]);
        company.data.invoices[0] = invoice.id;
        let r = company.save();
        console.log("updated", company);
        return r;
      } else if (company.data.invoices.length > 0) {
        if (!company.data.invoices.includes(invoice.id)) {
          company.data.invoices.push(invoice.id);
          console.log("addInvoice");
          return company.save();
        }
      }
    });
    Promise.all(promises);
  }

}

exports.Invoice = Invoice;
(0, _defineProperty2.default)(Invoice, "schema", void 0);
(0, _defineProperty2.default)(Invoice, "routes", void 0);
(0, _defineProperty2.default)(Invoice, "urlPath", "invoices");
(0, _defineProperty2.default)(Invoice, "form", {
  reference: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'reference',
      name: 'reference',
      label: "Reference",
      placeholder: "0321",
      helperText: "Invoice Reference",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  total_value: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'value',
      name: 'total_value',
      label: "Value",
      placeholder: "34.22",
      helperText: "Invoice Value",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  company: {
    type: "relationship",
    form: true,
    field: {
      type: "autocomplete",
      id: 'company',
      name: 'company',
      label: "Company",
      placeholder: "Acme Inc.",
      helperText: "company name",
      order: 1,
      model: _Companies.Company,
      options: () => {
        return _Companies.Company.getAll();
      }
    }
  },
  project: {
    type: "relationship",
    form: true,
    field: {
      type: "autocomplete",
      id: 'Project',
      name: 'project',
      label: "Project",
      placeholder: "Acme Inc.",
      helperText: "Client Project",
      order: 1,
      model: _Projects.Project,
      options: () => {
        return _Projects.Project.getAll();
      }
    }
  }
});
(0, _defineProperty2.default)(Invoice, "slice", _Slice.default);
var _default = Invoice;
exports.default = _default;