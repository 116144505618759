import { useState } from "react"
import {Stack, Typography, Divider, Alert} from "@mui/material"

import { Button } from "../../UI"
import Layout from "./Layout"

import { Auth } from "../../Core/Auth"
import { Formik, Form, Field } from "formik"
import { TextField } from 'formik-mui';
import * as Yup from 'yup';

const Login = () => {


    const [formValid, setFormValid] = useState(true)
    const [formError, setFormError] = useState(false)

    return (
        <Layout>
                    <Typography>
                        Access your project details, support contacts, hosting, servers billing and anything else you need when dealing with Powered By Coffee</Typography>
                    <Button
                        onClick = {Auth.GoogleLoginRedirect}
                    >
                        Login With Google
                    </Button>
                    <Divider />
                    <Formik
                        initialValues={{
                            'login-email': '',
                            'login-password': '',
                        }}
                        onSubmit={async (values, formik ) => {
                            let resp = await Auth.LoginWithEmail(values['login-email'], values['login-password'])

                            if(resp.status === false){
                                setFormValid(false)
                                setFormError(resp.error.message)
                            }

                        }}
                        validationSchema = {Yup.object().shape({
                            'login-email': Yup.string().email('Invalid email').required('Required'),
                            'login-password': Yup.string().required('Required')
                        } ) }
                    >
                    {({ submitForm, isSubmitting }) => (
                        <Form> 
                            <Stack direction="column" spacing={2}>
                                { !formValid && (
                                    <Alert severity="error">
                                        {formError}
                                    </Alert>
                                ) }

                                <Field component={TextField} label="Email" id="login-email" name="login-email" />
                                <Field component={TextField} label="Password" type="password" id="login-password" name = "login-password" />
                                <Stack direction="row" justifyContent = "space-between">
                                    <Button
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                    >
                                        Login
                                    </Button>
                                    <Button variant="text">Reset password</Button>
                                </Stack>
                            </Stack>
                        </Form>
                    ) }
                    </Formik>
        </Layout>        
    )
}

export {Login}
export default Login