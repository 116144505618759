"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = filterObject;

var _falsyValue = _interopRequireDefault(require("./falsyValue"));

function filterObject(func, items) {
  const result = {};
  Object.keys(items).forEach(key => {
    if (func) {
      if (func(items[key], key)) {
        result[key] = items[key];
      }
    } else if (!(0, _falsyValue.default)(items[key])) {
      result[key] = items[key];
    }
  });
  return result;
}