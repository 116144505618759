import { CurrencyFormat } from "./Formats"
import { TextField } from "@mui/material"

const CurrencyField = (props) => {

	const {
		setValue,
		...restProps
	} = props
    return (   
        <TextField 
            {...restProps}
            margin="normal"
            InputProps={{
                inputComponent: CurrencyFormat,
            }}
            onChange = { (e) => {
                setValue(props.name, e.target.value)
            }}
        />
    )
}

export {CurrencyField}
export default CurrencyField