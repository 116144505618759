import { Helmet } from "react-helmet"

export const withHelmet = (WrappedComponent, modifyFunction = null) => {
	
	return (props) => {
		let helmetData = modifyFunction?.(props) ?? props
	
        return (
			<>
				<Helmet>
            		<title>{helmetData.pageTitle} - PBC Portal</title>
            		<meta name="description" content={helmetData.pageTitle} />
        		</Helmet>
				<WrappedComponent 
            		{...props}
        		/>
			</>
		)

    }
}

export const withHelmetFromModel = (WrappedComponent) => {
	
	return withHelmet(WrappedComponent,  (props) => {
		console.log("helmetprops", props)
		return {
			pageTitle : props?.model?.title
		}
	})
}


export default withHelmet