//@ts-nocheck
import React from "react"
import {HZNForm} from "../Form/Form"

import {Contact as Model} from "portal-datatypes" 
import { withFormDrawer } from "../Form/Drawer"

const AddContact = (props : any) => {
    return(
      <HZNForm  
          Model = {Model}
          fields = {Model.getFormFields()}
          title = "Create a New Contact"
          buttonText = "Add Contact"
          {...props}
          action = "save"
          successMessage = "Contact Added"
          showAddAnotherButton = {true}
      />
    )
}

const AddContactDrawer = withFormDrawer(AddContact)

export default AddContact
export {AddContact, AddContactDrawer}