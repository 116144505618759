import {isNil} from "lodash"

const DateColumnType = {
    type: 'date',
    valueGetter: ( row  ) => {

        if(isNil(row.value)){
            return '-'
        }
        
        return new Date(row.value)
    }
}

export {DateColumnType}
export default DateColumnType