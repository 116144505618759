// ui
import { Box, Stack} from "@mui/material"
import { Frame } from "./Frame"



const Page = (props : {
  title : string
  children ?: JSX.Element | JSX.Element[]
}) => {
	console.log(props)
	
	const {
		children = false
	} = props
  return (
      <Frame>
		<Box sx={{
			flexGrow: "1",
			overflow: "hidden",
			flexDirection: "column",
			display: "flex"
		}}>
     
	

		<Box 
			flexGrow={1} 
			sx = {{
				paddingLeft: 2,
				paddingRight: 2,
				paddingBottom: 2
				
			}}
		>
			<Stack sx={{ height: "100%" }}>

				{ children ? (
					<>{children}</>
				) : (
					<>Nah</>
				)}
      			
			</Stack> 
	  	</Box>
      </Box>
      </Frame>
  )
}

export default Page
export {Page}