import {TextField as MUITextField} from "@mui/material";
import {styled, ThemeProvider } from '@mui/material/styles';
import {theme} from "./Theme"


const StyledTextField = styled(MUITextField)(({ theme }) => ({
    fontFamily : theme.typography.families.input,
    textTransform: "capitalize",
    typography: {
        fontSize: '18px'
    }
}));

export default function TextField(props) {
    
    return (
        <ThemeProvider theme={theme}>
            <StyledTextField 
                {...props} 
            />
        </ThemeProvider>
    )
}

export {TextField}