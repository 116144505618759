import { getIntegrations } from "./../../Core/Integrations"

import { Paper } from "@mui/material"

import Section from "../../Components/Section"
import Integration from "../../Components/Settings/Integration"


export function Connections (props) {
    
    const integrations = getIntegrations()

    return (
    <Paper elevation={2}  sx = {{padding: '2rem'}} >
        <Section
            title = {props.title}
        >
            {Object.keys(integrations).filter( (i) => integrations[i].type === props.type).map( (i, index) => {
                let integration = integrations[i]
                return (
                    <Integration 
                        integration = {integration} 
                        key={index} 
                        loggedIn = {props.loginStatus[integration['name']]} 
                        onLoginStatusChange = {props.onLoginStatusChange}
                    />
                ) 
            })}
        </Section>
    </Paper>
    )
}