import {AppSlice} from './app-slice.js'
import {combineReducers} from "redux"
import {populateFromRemote} from "./populate"
import { isNil } from 'lodash';
import {createBlankStore, store} from "portal-redux"


let FirebaseSlices = {}
let Slices = {}


function createStore () {

    Slices["app"] = AppSlice
	
	let reducers = { 
		app : Slices["app"].reducer
	}

    return createBlankStore(reducers)
}


function getStore(){
    return store
}

function addSlice(name, slice){

    try{

        if(isNil(name)){
            console.error("AddSlice missing a name")
        }

        if(isNil(slice)){
            console.error("AddSlice missing a Slice")
        }

    } catch(error){
        return false
    }

    Slices[name] = slice

}

function createFirebaseSlices(models, FireStore = null){
    models.forEach( (Model) => {
        Model.slice.init({
			"FireStore" : FireStore
		})
        FirebaseSlices[Model.slice.slice.name] = Model.slice
    })

    return FirebaseSlices
}

function addFirebaseSlicesToStore(FirebaseSlices){
    
    // need an object to hold the reducers
	let reducer = {}

	// loop reach slice to get the reducer and add it to the creatSore args
	Object.keys(FirebaseSlices).forEach( (name) => {
		let slice = FirebaseSlices[name].slice
		reducer[name] = slice.reducer
	})

	// loop reach slice to get the reducer and add it to the creatSore args
    // replace reducer replaces completely, so we need to do the whole thing
	Object.keys(Slices).forEach( (name) => {
		let slice = Slices[name]
		reducer[name] = slice.reducer
    })

	store.replaceReducer(combineReducers(reducer))

    // loop the reducers again to do setup
	Object.keys(FirebaseSlices).forEach( (name) => {

		let slice = FirebaseSlices[name]
		slice.setStore(store);
		slice.addFirebaseEventListener()

	})

}

function clearSlices(){
	console.log("clearSlices", FirebaseSlices)
	// loop each slice and hit the clear  Method
	Object.keys(FirebaseSlices).forEach( (name) => {
		let slice = FirebaseSlices[name]
		slice.clear()
    })

	Object.keys(Slices).forEach( (name) => {
		let slice = Slices[name]
		slice?.clear?.()
    })
}

export default store
export {
    createStore, 
    createFirebaseSlices, 
    store, 
    FirebaseSlices,
	clearSlices,
    AppSlice, 
    addFirebaseSlicesToStore, 
    populateFromRemote,
    addSlice,
    getStore
}