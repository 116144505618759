import {Container, Stack, Box, Typography, } from "@mui/material"

import { theme } from "../../UI"

import pbclogo from "../../UI/images/pbc-logo.svg"
import pbc_illustration from "../../UI/images/home-illustration.png"

import { AlertsContainer } from "../../Components/Alerts"

const Layout = (props) => {

    return (
		<>
		<AlertsContainer 
			alterProps = {{"a" : "B"}}
		/>
        <Container sx = {{
            height: "100vh"
        }}>
		

            <Stack 
                direction="row" 
                alignContent = "center"
                justifyContent = "space-around"
                alignItems = "center"
                spacing = {2}
                height = "100%"
            >
                <Box sx = {{
                    width: "50%",
                    padding: "50px"
                }}>
                    <Box 
                        component="img"
                        src={pbc_illustration} 
                        alt="Powered By Coffee" 
                        sx = {{ 
                            
                            width: '100%'
                        }}
                    />
                </Box>
                <Stack direction="column" spacing={3} sx = {{
                    width: "50%"
                }}>
                    <Box 
                        component="img"
                        src={pbclogo} 
                        alt="Powered By Coffee" 
                        sx = {{ maxWidth: '300px'}}
                    />
                    <Typography
                        element = "h1"
                        component={"h1"}
                        variant="h2"
                        fontFamily={theme.typography.families.heading}
                    >
                        Client Portal
                    </Typography>

                    {props.children}
                    
                </Stack>
            </Stack>
        </Container>
		</>
    )
}

export {Layout}
export default Layout