"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Collection", {
  enumerable: true,
  get: function () {
    return _collect.Collection;
  }
});
exports.default = void 0;

require("core-js/modules/web.dom-collections.iterator.js");

var _collect = require("collect.js");

var _filterArray = _interopRequireDefault(require("./helpers/filterArray"));

var _filterObject = _interopRequireDefault(require("./helpers/filterObject"));

_collect.Collection.prototype.toSelectObjects = function () {
  const collectionInstance = this.constructor;
  let collection = [];

  function iterate(list, collection) {
    const childCollection = [];

    if (list instanceof collectionInstance) {
      list.items.forEach(i => iterate(i, childCollection));
      collection.push(childCollection);
    } else if (Array.isArray(list)) {
      list.forEach(i => iterate(i, childCollection));
      collection.push(childCollection);
    } else {
      collection.push(list);
    }
  }

  if (Array.isArray(this.items)) {
    this.items.forEach(item => {
      iterate(item, collection);
    });
  } else {
    collection = this.values().all();
  }

  return collection.map(item => {
    return {
      id: item.id,
      title: item.title
    };
  });
};

_collect.Collection.prototype.whereNotUndefined = function (key) {
  let filteredItems = null;

  function filter(item, index) {
    return typeof item[key] !== "undefined";
  }

  if (Array.isArray(this.items)) {
    filteredItems = (0, _filterArray.default)(filter, this.items);
  } else {
    filteredItems = (0, _filterObject.default)(filter, this.items);
  }

  return new this.constructor(filteredItems);
};

var _default = _collect.Collection;
exports.default = _default;