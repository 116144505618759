//@ts-nocheck
import React from "react"
import {HZNForm} from "../Form/Form"

import {Project as Model} from "portal-datatypes" 
import { withFormDrawer } from "../Form/Drawer"

const AddProject = (props : any) => {
    return(
      <HZNForm  
          Model = {Model}
          fields = {Model.getFormFields()}
          title = "Create a New Project"
          buttonText = "Add Project"
          {...props}
          action = "save"
          successMessage = "Project Added"
          showAddAnotherButton = {true}
      />
    )
}

const AddProjectDrawer = withFormDrawer(AddProject)

export default AddProject
export {AddProject, AddProjectDrawer}