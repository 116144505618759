"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Taxonomy = void 0;

class Taxonomy {
  constructor(data) {
    this.collection = data;
    Object.keys(this.collection).forEach(key => {
      Object.defineProperty(this, key, {
        get: function () {
          return this.collection[key];
        }
      });
      Object.defineProperty(this, this.collection[key].slug.toUpperCase(), {
        get: function () {
          return this.collection[key].value;
        }
      });
      Object.defineProperty(this, this.collection[key].slug, {
        get: function () {
          return this.collection[key];
        }
      });
    });
  }

}

exports.Taxonomy = Taxonomy;
var _default = Taxonomy;
exports.default = _default;