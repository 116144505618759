//@ts-nocheck
import { Paper } from "@mui/material"
import {Page} from "../Components/Page"


const Settings = () => {
    return (
        <Page
            title = "Dashboard"
        >
            <Paper elevation={2}  sx = {{padding: '2rem'}} >
                    dasdas
            </Paper>
        </Page>
    )
}
export default Settings
export {Settings}