"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Note = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _portalModel = require("portal-model");

var _Slice = _interopRequireDefault(require("./Slice.js"));

//@ts-nocheck 
// data
class Note extends _portalModel.Model {
  // these make typescript stop complaining
  // TODO : Make Model parent typescript and use these better
  // I'm overriding the constructor at the moment 
  // to stop the getters being get from the data array
  constructor(args) {
    super(args, false);
  }

  static loadSchema() {
    this.schema = require("./Schema.js").Schema;
  }

  date() {
    return this.data.created_on;
  }

}

exports.Note = Note;
(0, _defineProperty2.default)(Note, "schema", void 0);
(0, _defineProperty2.default)(Note, "slice", _Slice.default);
var _default = Note;
exports.default = _default;