import { GridRowParams, GridValueGetterParams, GridValueSetterParams } from "@mui/x-data-grid-pro";
import {Currency as CurrencyColumnType} from "../ColumnTypes/Currency"

import {Link as LinkColumnType} from "./../ColumnTypes/Link"
import {EditCompanyDrawer} from "./../../Forms/EditCompany"
import ActionSet from "../ActionSets/ActionSet";

import {Picked as PickedColumnType} from "./../ColumnTypes/Picked"
import {Actions as ActionsColumnType} from "./../ColumnTypes/Actions"
//@ts-ignore	
import {CompanyLifeCycleStages} from "portal-constants"
import { convertTitleToLabel } from "./Utils";


const Companies = [
    { 
      field: 'id', 
      headerName: 'ID', 
      width: 70,
      hide: true,
	  filterable: false
    }, 
    { 
      field: 'title', 
      headerName: 'Name', 
      width: 270,
      ...LinkColumnType
        
    },
	{ 
		field: 'domain', 
		headerName: 'Domain', 
		width: 200,
		editable: true,
		valueSetter: async (params: GridValueSetterParams) => {
			params.row.set("domain", params.value)
			await params.row.save();
			console.log(params.row)
			return params.row
		},
	},
	{
		field: 'type', 
		headerName: 'Type', 
		width: 170,
		...PickedColumnType,
		valueOptions: convertTitleToLabel(CompanyLifeCycleStages)
	  },
	  {
		field: 'projects', 
		headerName: 'Projects',
		align: "center",
		headerAlign : "center",
		width: 100,
		tyoe: 'number',
		valueGetter:(params: GridValueGetterParams) => {
			return params.row.projects.length
		},
	  },
	  {
		field: 'invoices', 
		headerName: 'invoices',
		align: "center",
		headerAlign : "center",
		width: 100,
		tyoe: 'number',
		valueGetter:(params: GridValueGetterParams) => {
			return params.row.invoices.length
		},
	  },
    { 
        field: 'value', 
        headerName: 'Value', 
        width: 170,
        
        ...CurrencyColumnType
      },
      { 
        field: 'invoiced', 
        headerName: 'Invoiced', 
        width: 170,
        ...CurrencyColumnType
      },
      { 
        field: 'received', 
        headerName: 'Received', 
        width: 170,
        valueGetter:(params: GridValueGetterParams) => {
          return params.row.paid
        },
        ...CurrencyColumnType
      },
      { 
        field: 'unpaid', 
        headerName: 'Unpaid', 
        width: 170,
        valueGetter:(params: GridValueGetterParams) => {
          return params.row.remaining
        },
        ...CurrencyColumnType
      },
      { 
        field: 'to_invoice', 
        headerName: 'To Invoice', 
        width: 170,
        valueGetter:(params: GridValueGetterParams) => {
          return params.row.to_invoice
        },
        ...CurrencyColumnType
      },
	  {
		...ActionsColumnType,
	 	...{
			getActions: (params: GridRowParams) => [(<ActionSet 
			   {...params} 
			   editForm={EditCompanyDrawer} 
		 	/>)]
	 	}
 	}
  ];
  
  

  export default Companies
  export {Companies}