//@ts-nocheck
// system / external
import {useState} from "react"
import { useHistory } from 'react-router-dom';
import { generatePath } from "react-router";

// mui
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';

// data
import {Project as Model} from "portal-datatypes" 
import { ProjectTypesTaxonomy, ProjectStatusesTaxonomy, ProjectStatuses} from 'portal-constants';

// components

import { Projects as Columns } from "../../Components/DataGrids/Columns/Projects"
import { AddProjectDrawer } from "../../Components/Forms/AddProject"
import { EditProjectDrawer } from "../../Components/Forms/EditProject"
import { EditMultipleProjectsDrawer } from "../../Components/Forms/EditMultipeProjects"
import { AddItemControl } from '../../Components/Form/DrawerControl';
import { Archive, Frame } from '../../Components';
import {workMenu} from "./../../Core/Navigation"

const ProjectHeaderControls = () => (
  <>
    <AddItemControl 
        label = "Add Project"
        form = {AddProjectDrawer}
    />
  </>
)

const Projects = ( props : any ) => {

	const history = useHistory();

	const [visibilityModel, setVisibilityModel] = useState(props.columnVisibilityModel ?? {
		[GRID_CHECKBOX_SELECTION_COL_DEF.field] : true,
		title: true,
		type: true,
		actions: true,
		status: true,
		id : false,
	})

	const [status, setStatus] = useState([ProjectStatusesTaxonomy[props.match?.params?.status]?.value ?? ProjectStatusesTaxonomy.IN_PROGRESS])
	const [type] = useState([ProjectTypesTaxonomy[props.match?.params?.type]?.value ?? props.type ?? ProjectStatusesTaxonomy.BUILD])

	const updateStatus = (status) => {
		setStatus(status)
		let path = generatePath("/projects/:type/status/:status", { 
			"type" : ProjectTypesTaxonomy[type[0]].slug,
			"status" : ProjectStatusTaxonomy[status].slug,
		})
		history.push(path)
	}

	let query = {
		type : props.type,
		status : status,
	}
	
  	return (
		<Frame>
    	<Archive 
			title = {props.title ?? "Projects"}
			Model = { Model }
	  		titleMenu = {workMenu.items}
			statusMenu = { ProjectStatuses }
			query = { query }
			setStatus = {updateStatus}

			controls = {props.controls ?? (<ProjectHeaderControls />)}
			gridProps = {{
				EditMultipleItemDrawer : EditMultipleProjectsDrawer,
				EditItemDrawer : EditProjectDrawer,
				columns : (props.columns ?? Columns),
				columnVisibilityModel : visibilityModel,
				onColumnVisibilityModelChange : (newModel) => {
					setVisibilityModel(newModel)
				},
				disableColumnMenu : false,
			}}
		/>
		</Frame>
  	)
}

export default Projects
export {Projects}

