/**
 * External Dependencies
 */
import {useState} from "react"
import Moment from "moment"

/**
 * MUI Dependencies
 */
import { ListItem, Paper, Stack, Avatar, Typography, ListItemText, Button } from "@mui/material"

/**
 * Internal Dependencies
 */
import NoteInput from "./NoteInput"

export const Note = (props) => {

    let created = Moment(props.note.data.created_on)
    
    const [showEditForm, setShowEditForm] = useState(false)
    
    return (
        <ListItem 
            disableGutters
        >
            <Paper sx={{
                padding: 2,
                width: "100%"
            }}>
                <Stack spacing = {2} sx = {{ "width": "100%"}}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                    >

                        <Stack
                            direction="row"
                            
                            spacing = {1}
                            alignItems={"center"}
                            sx = {{ "width": "100%"}}
                        >
                            <Avatar
                                sx={{ width: 36, height: 36 }}
                                alt = {props.note.users?.displayName}
                                src= {props.note.users?.photo}
                            >S</Avatar> 
                            <Typography
                                variant = "subtitle2"
                                flexGrow={1}
                            >{props.note.users?.displayName}</Typography>

                            <Typography
                                variant = "subtitle2"
                            >
                                {created.startOf("minute").fromNow()}
                            </Typography>
                        </Stack>

                    

                    </Stack> 
                    { !showEditForm && (
                        <ListItemText>
                            {props.note.content}
                        </ListItemText>
                    )}

                    { showEditForm && (
                        <NoteInput
                            value = {props.note.content}
                            onSave = { (value) => {

                                props.note.set({"content" : value})
                                props.note.save();

                                setShowEditForm(false)
                            }}

                            onClose = { () => {
                                setShowEditForm(false)
                            }}
                        />
                    )}

                    { !showEditForm && (
                        <Stack direction="row">
                            <Button size="small" onClick={() => {
                                props.owner.removeNote(props.note.id)
                            }}> Delete </Button>
                            <Button size="small"onClick={() => {
                                setShowEditForm(true)
                            }}> Edit </Button>
                        </Stack>
                    )}
                </Stack>
            </Paper>
        </ListItem>
    )
}

export default Note