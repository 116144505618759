//@ts-nocheck
import {Project as Model} from "portal-datatypes" 
import {Single} from "../../Components/Single"


import {withView} from "../../Hooks/withView"

import {EditProjectDrawer } from "../../Components/Forms/EditProject"


import ProjectOverviewTable from "../../Components/Projects/OverviewTable"
import ProjectInvoicesTable from "../../Components/Projects/InvoicesTable"

import RetainerOverviewTable from "../../Components/Retainers/OverviewTable"
import RetainerCurrentPeriod from  "../../Components/Retainers/RetainerCurrentPeriod"
import RetainerHistory from  "../../Components/Retainers/RetainerHistory"


import {AddRetainerDrawer} from "../../Components/Forms/AddRetainer"
import { AddItemControl } from '../../Components/Form/DrawerControl';

/*
const RetainerExtraControls = () => {
    return (
        <ButtonGroup variant="contained" sx={{
            marginLeft: "1rem"
        }}>
            <Button sx={{backgroundColor:"#f36d00"}}>Harvest</Button>
            <Button sx={{backgroundColor:"#007ABF"}}>FreeAgent</Button>
        </ButtonGroup>
    )
}
*/

const RetainerHeaderControl = () => (
	<>
	  <AddItemControl 
		  label = "Setup Retainer"
		  form = {AddRetainerDrawer}
	  />
	</>
  )

let Project = (props : any) => {

    const {model} = props
    
    return (
        <Single 
            {...props} 
            Model={Model}
            ItemControl = {EditProjectDrawer}
            extraControls = {(<RetainerHeaderControl />)}
        >
            <ProjectOverviewTable model={model}/>

            { model.isRetainer() && (
                <>
				
                    <RetainerCurrentPeriod model={model.retainer?.getCurrentPeriod?.()}/>
                    <RetainerOverviewTable model={model.retainer}/>
                    <RetainerHistory model={model.retainer} /> 
                </>
            )}

            <ProjectInvoicesTable model={model}/>
        </Single>
    )
}



Project = withView(Project, Model)

export default Project
export { Project }