import {Stack, Chip} from "@mui/material"
import {isString, isUndefined} from "lodash"



const ChipList = (props) => {
    
    if(isUndefined(props.types)){
        return null
    }

    if(isString(props.types)){

        return ( <Chip label={ props.types } /> )   

    } else {

        return (
            <Stack direction="row" spacing={1}>
                { props.types.map( (type, index) => (
                    <Chip 
                        key = {index} 
                        label={ type.title }
                        color = {type.color}
                    />
                ))}
            </Stack>
        )
    }

}

export default ChipList
export {ChipList}