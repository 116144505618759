"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.isUndefinedEmptyOrNull = isUndefinedEmptyOrNull;

var _lodash = require("lodash");

function isUndefinedEmptyOrNull(test) {
  return (0, _lodash.isUndefined)(test) || (0, _lodash.isEmpty)(test) || (0, _lodash.isNull)(test);
}

var _default = isUndefinedEmptyOrNull;
exports.default = _default;