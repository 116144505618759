import { Accordion as MUIAccordion, Typography, AccordionDetails, AccordionSummary as MuiAccordionSummary} from "@mui/material"
import { useState } from "react"
import {KeyboardArrowDown} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
    expandIcon = {<KeyboardArrowDown />}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginTop: '20px',
      marginBottom: '20px',
    },
  }));

const Accordion = (props) => {

    let [expanded, setExpaned] = useState(null)

    const isSummaryString = (typeof props.summary === "string")

    return (
        <MUIAccordion  
            disableGutters 
            elevation={0} 
            square 
            defaultExpanded = {true}
            expanded={expanded} 
            onChange={() => setExpaned(!expanded) }
            sx = {{
                backgroundColor: "transparent",
                borderBottom: "1px solid rgba(0,0,0,0.1)"
            }}
        >

            <AccordionSummary        
                sx = {{
                    paddingLeft:'20px',
                    paddingRight:'20px',
                    backgroundColor: "transparent",
                }}
                classes = {{
                    content: {
                        marginTop: '20px',
                        marginBottom: '20px',
                    }
                }}
            >
                {isSummaryString && (
                    <Typography fontWeight={700}>{props.summary}</Typography>
                )}

                {!isSummaryString && (
                    props.summary
                )}
                
            </AccordionSummary>
            
            <AccordionDetails sx = {{
                borderTop: "1px solid rgba(0,0,0,0.1)"
            }}>
                {props.children}
            </AccordionDetails>
        </MUIAccordion>
    )

}

export {Accordion}
export default Accordion