//@ts-nocheck

import {Company as Model} from "portal-datatypes" 
import {EditCompanyDrawer} from "./../../Components/Forms/EditCompany"
import {Single} from "./../../Components/Single"
import {withView} from "../../Hooks/withView";



let Contact = ( props : any ) => {
    
    return (
        <Single 
            {...props} 
            ItemControl = {EditCompanyDrawer}
        >

            hi!
        </Single>
     )
 }

Contact = withView(Contact, Model)

 export default Contact
 export {Contact}