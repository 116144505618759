import {app}  from './Core/bootstrap.js'

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { BrowserRouter as Router, Route} from "react-router-dom";
import { Routing } from './Core/Navigation/Routing'
import { AuthProvider} from './Core/Auth/index.js'
import { KeyboardShortcutProvider} from './Core/KeyboardShortcuts'
import { SearchProvider } from './Components/Search'
import { DndProvider } from 'react-dnd'
import { HTML5Backend as DnDHTML5Backend } from 'react-dnd-html5-backend'
import { Provider as ReduxProvider} from 'react-redux'
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

 
import { GlobalStyles} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { QueryParamProvider } from 'use-query-params';

import { theme } from './UI/Theme';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
	'2996f575e35c85e015027275d42d9d72T1JERVI6NDI4MTcsRVhQSVJZPTE2ODI5NDQ4NzAwMDAsS0VZVkVSU0lPTj0x',
  );

function App() { 
  return (
    
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
            <GlobalStyles styles={{ 
                body: { margin: '0', height:'100%'},
                html: { height:'100%' },
                "*" : { boxSizing: "border-box"}
            }} />
            <DndProvider backend={DnDHTML5Backend}>
                <ReduxProvider store={app.getStore()}>
                    <AuthProvider>
                        <Router>
							<QueryParamProvider ReactRouterRoute={Route}>
                            	<SearchProvider>
                            		<KeyboardShortcutProvider>
                                		<Routing />
                            		</KeyboardShortcutProvider>
                            	</SearchProvider>
							</QueryParamProvider>
                        </Router>
                    </AuthProvider>
                </ReduxProvider>
            </DndProvider>
        </ThemeProvider>
    </LocalizationProvider>

  );
}


export default App;
