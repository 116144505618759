// external
import { 
    GoogleAuthProvider, 
    signInWithRedirect, 
    signInWithEmailAndPassword,
    onAuthStateChanged,
    getRedirectResult
} from "firebase/auth";

// packages
import {LoginStates} from "portal-constants"

// internal
import {FireAuth} from "./../Firebase"
import {store} from "./../Redux"

// component
import {AuthSlice} from "./Slice"

// inti
let GoogleProvider

export function init(){

    // Google Sign In
    GoogleProvider = new GoogleAuthProvider()
    
    // Login / Logout Check
    onAuthStateChanged(FireAuth, AppOnAuthStateChanged)

    // Login Using Google
    getRedirectResult(FireAuth)
        .then(getRedirectResultSuccess)
        .catch(getRedirectResultFailure)
}

export function clearSession(){
    FireAuth.signOut()
}

export function AppOnAuthStateChanged(user) {


    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      store.dispatch(AuthSlice.actions.setAuthState(LoginStates.LOGGED_IN))
      let {displayName, email, uid, photoURL} = user
      store.dispatch(AuthSlice.actions.setUserData( {displayName, email, uid, photoURL} ) )

    } else {
      // User is signed out
      store.dispatch(AuthSlice.actions.setAuthState(LoginStates.LOGGED_OUT))
      store.dispatch(AuthSlice.actions.setUserData({}))
    }
}

export function getRedirectResultSuccess(result) {

    if(!result){
        return
    }

    store.dispatch(AuthSlice.actions.setAuthState(LoginStates.LOGGED_IN))
}


export function getRedirectResultFailure(error){
    // Handle Errors here.
    //const errorCode = error.code;
    //const errorMessage = error.message;
    // The email of the user's account used.
    //const email = error.email;
    // The AuthCredential type that was used.
    //const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
}

export async function LoginWithEmail(email, password){

    let status = false
    let error = null

    await signInWithEmailAndPassword(FireAuth, email, password)
        .then((userCredential) => {
            status = true
        })
        .catch((errorResp) => {
            status = false
            error = {}
            error.code = errorResp.code;
            if(error.code === "auth/user-not-found"){
                error.message = "No user with the email and password combination found"
            } else {
                error.message = errorResp.message;
            }
        });
    
    return {status, error}
}



export function GoogleLoginRedirect(){
    signInWithRedirect(FireAuth, GoogleProvider);
}
