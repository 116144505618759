import {useEffect, useState} from "react"
import { isUndefined } from "lodash";

import { Box, Button, Typography, Link, Stack, CircularProgress} from "@mui/material"

import {FireFunctions} from "./../../Core/Firebase";
import { httpsCallable } from "firebase/functions";
import { useLocation} from "react-router-dom"

export default function Integration(props){

    const [isWorking, setIsWorking] = useState(false);
	const location = useLocation();

	console.log(location)

    const {
        integration,
        loggedIn : isLoggedIn,
    } = props

    useEffect( () => {
        setIsWorking(isUndefined(isLoggedIn))
    }, [isLoggedIn])

    const logout = () => {
        const serviceLogoutCall = httpsCallable(FireFunctions, 'serviceLogout');
        serviceLogoutCall({"service" : integration.name }).then( (result) => {
            props.onLoginStatusChange()
        }).catch((error) => {
            console.error(error)
        })

    }

	let signin_url
	if(integration.signin_url){
		signin_url =  new URL(integration.signin_url.href)
		signin_url.searchParams.set("state", encodeURIComponent(btoa(JSON.stringify({return_path : location.pathname }) ) ))
		console.log(signin_url)
	} else {
		signin_url = null
	}
	


    return (
    <Stack
        spacing = {2}
        direction = {"row"}
        sx = {{
            borderBottom : '1px solid #cccccc',
            ":first-child" : {
                borderTop : '1px solid #cccccc',
            },
            alignItems: "center",
            opacity: (integration.enabled ? 1 : 0.5),
            pointerEvents: (integration.enabled ? "auto" : "none")
        }}
    >
        {integration.icon && (
            <Box 
                sx = {{width:"40px", height:"40px"}}
            >
				<div dangerouslySetInnerHTML={{"__html":integration.icon}} />
			</Box>
        )}
    
        <Typography 
            variant="h5" 
            flexGrow={1}
            sx = {{
                lineHeight : '3'
            }}
        >   
            <Link href ={integration.url}>{integration.label}</Link>
        </Typography>
        <Box >
            <>
            { isWorking ? (
                <CircularProgress size={20} />
            ) : (
                <>
                    { isLoggedIn ? (
                        <Stack direction="row" spacing={2} >
                            <Button variant="contained" size="small" onClick = {logout}>Logout of {integration.name}</Button>
                            <Button variant="contained" size="small">Sync</Button>
                        </Stack>
                    ) : (
                        <Button variant="contained" size="small" href={signin_url}>Login To {integration.name}</Button>
                    )}
                </>
            )}
            </>
        </Box>
    </Stack>
    )
}
