
import { styled } from '@mui/system';
import {Chip} from '@mui/material';

const SearchTag = styled(Chip)( ({ theme }) => ({
    textTransform : "uppercase",
    fontSize: "0.6rem",
    fontWeight: 600,
    letterSpacing: 0.5,
    borderRadius: theme.shape.borderRadius,
    border: "1px solid",
    color: theme.palette.primary.dark
}));

export default SearchTag
export {SearchTag}