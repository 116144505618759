"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.OpportunityStatusesTaxonomy = exports.OpportunityStatuses = exports.OPPORTUNITY_STATUS_LOST = void 0;

var _Taxonomy = _interopRequireDefault(require("./Utils/Taxonomy"));

const OPPORTUNITY_STATUS_LOST = 5;
exports.OPPORTUNITY_STATUS_LOST = OPPORTUNITY_STATUS_LOST;
const OpportunityStatuses = [{
  value: 0,
  title: "Active",
  color: "info",
  slug: "active"
}, {
  value: 1,
  title: "Unqualified",
  color: "neutral",
  slug: "unqualified"
}, {
  value: 2,
  title: "Abandoned",
  color: "neutral",
  slug: "abandoned"
}, {
  value: 3,
  title: "Ghosted",
  color: "neutral",
  slug: "ghosted"
}, {
  value: 4,
  title: "Won",
  color: "success",
  slug: "won"
}, {
  value: OPPORTUNITY_STATUS_LOST,
  title: "Lost",
  color: "error",
  slug: "lost"
}];
exports.OpportunityStatuses = OpportunityStatuses;
let OpportunityStatusesTaxonomy = new _Taxonomy.default(OpportunityStatuses);
exports.OpportunityStatusesTaxonomy = OpportunityStatusesTaxonomy;
var _default = OpportunityStatuses;
exports.default = _default;