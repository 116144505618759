//@ts-nocheck
import React from "react"
import {HZNForm} from "../Form/Form"

import {RetainerPeriod} from "portal-datatypes" 
import { withFormDrawer } from "../Form/Drawer"  

const EditRetainerPeriod = (props : any) => {
    return(
      <HZNForm
        Model = {RetainerPeriod}
        fields = {RetainerPeriod.getFormFields()}
        title = "Edit RetainerPeriod"
        buttonText = "Update RetainerPeriod"
        {...props}
        action = "edit"
        successMessage = "RetainerPeriod Updated"
        showAddAnotherButton = {false}
      />
    )
}

const EditRetainerPeriodDrawer = withFormDrawer(EditRetainerPeriod)

export default EditRetainerPeriod
export {EditRetainerPeriod, EditRetainerPeriodDrawer}