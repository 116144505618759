//@ts-nocheck
import { TextField } from '@mui/material';
import { Formik, useFormikContext } from 'formik';
import {isUndefined} from 'lodash';

import {Container} from "./Container"
import {DatePicker} from '@mui/lab';

import {withFormik} from "./../../Core/Form/useFormik"



import {
    Select as CustomSelect,
    AutoCompleteFromModel as CustomAutoComplete,
    CurrencyField,
    SwitchField
} from "./../Fields"



/*
import {HZNFormikTextField} from "./HZNFormikTextField"
import {HZNFormikTimeFrame} from "./HZNFormikTimeFrame"
import {HZNFormikPriority} from "./HZNFormikPriority"
*/

const HZNFormikTextField = (props:any) => {

    let { name } = props
    let formik = useFormikContext()
    let { values, touched, errors } = formik

    return (
        <TextField 
        {...props}
        value={values[name]}
        onChange={formik.handleChange}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        margin="normal"
        
        />
    )
}






const HZNFormikDate = (props:any) => {

  let { name } = props
  let formik = useFormikContext()
  let { values, touched, errors } = formik

  return (
	<DatePicker
      label="Basic example"
      value={values[name]}
      onChange={(value) => {
        formik.setFieldValue(name, value.format()) // value will be a momentjs object so format it and save
      }}
      error={touched[name] && Boolean(errors[name])}
      helperText={touched[name] && errors[name]}
      renderInput={(params) => <TextField {...params} label={props.label} />}
    />

  )
}


const HZNForm = ( props : any ) => {

	let {
		useModelInitialValues = true
	} = props
  
  const handleSubmit = (values : any, formik : any) => {
    console.log("handleSubmit", values, formik)
    // destructure this object so we get short properties for processing the from 
    // and it removes items set that we dont want passed to storage (actions, next steps, etc )    
    const {
      action, 
      closeForm, 
      ...data
    } = values

    if(action === "save"){

      save(data);
      if(closeForm){
        //props.onClose()
      }

    }

    if(action === "edit"){

      	edit(data);
      	props.onClose()
    }

	if(action === "editMultiple"){

		editMultiple(data);
		//props.onClose()
  }
    
    formik.setStatus("succeeded")
  }

	const edit = (values : any) => {  
		console.log("edit")
		props.item.setMany(values)
		props.item.save();
	}

  	const editMultiple = (values : any) => {
		let promises = props.items.map( async (item) => {
			item.setMany(values)
			await item.save()
		})

		Promise.await(promises);
		return true
	}

	const save = (values : any) => {
		let item = props.Model.init(values)
		console.log("save type", item)
		item.save();
	}

  const AvailableFields = {
    'text'          : HZNFormikTextField,
    'autocomplete'  : withFormik(CustomAutoComplete),
    'select'        : withFormik(CustomSelect),
    'date'          : HZNFormikDate,
    'currency'      : withFormik(CurrencyField),
    'switch'        : withFormik(SwitchField),
  }

  let values 
  if(useModelInitialValues){
  	values = props.Model.getFormInitialValues()
  }

  if(!isUndefined(props.item)){
    values = {...values, ...props.item.getDataForForm()}
  } else {
    values = {...values, ...props.initialValues}
  }



  return (  
    <Formik
      initialValues = { values }
      onSubmit = { props.handleSubmit ?? handleSubmit }
      validationSchema = {props.validation ?? props.Model.getFormFieldsValidation()}
    >
      <Container {...props}  >
        

        { props.fields.map( ( field, index ) => {
          
          delete field.initialValue
          const Field = AvailableFields[field.type]
          
          return (
              <Field
                key = {field.key}
                {...field}
              />
          )
        })}
      </Container >
    </Formik>
    
  )
}

export {HZNForm}
export default HZNForm;