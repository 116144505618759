import { Link as RouterLink } from "react-router-dom";
import { Link as MUILink } from "@mui/material";

const Link = (props) => {
    
    return (
           
            <MUILink 
                component={RouterLink} 
                underline={props.underline ?? "hover"}
                {...props} 
            > 
                    {props.children}
            </MUILink>
    )
}

export default Link
export {Link}