 //@ts-nocheck
 import { useParams } from "react-router-dom";
 import { useSelector } from 'react-redux'
 import { Box, Typography, Grid } from "@mui/material";
 import { AddItemControl } from "./Form/DrawerControl"
 import {Frame} from "./Frame"
 import {Loader} from "./Loader"
 import {CompanyIcon} from "./Companies"


let Single = ( props : any ) => {

    var { guid } = useParams<{guid?: string}>()
    let model = useSelector(props.Model.get(guid))
    
    if(Object.keys(model).length < 1){
        return (
            <Frame>
                <Loader />
            </Frame>
        )
    }

     return (
         <Frame>
         <Box sx = {{padding:'2rem'}}>
            <Grid
                container
                flexDirection = "row"
                alignContent = "center"
                alignItems = "center"
                sx={{marginBottom: 4}}
            >
           
            <CompanyIcon company={model} />

            <Typography
                component = "h1"
                variant="h3"
                flexGrow={1}
             >
                {props.title ?? model.title}
            </Typography>
            <Box
                justifySelf = "flex-end"
            >
                { props.ItemControl && (
                <AddItemControl 
                    label = "Edit" 
                    form = {props.ItemControl}
                    item = {model}
                />
                ) }

                { props.extraControls && (
                    props.extraControls
                )}
            </Box>
            </Grid>

            { props.children }

        </Box>
        </Frame>
     )
 }



export default Single
export {Single}