//@ts-nocheck
import { Typography, ButtonGroup, Chip, MenuItem, ListItemIcon, ListItemText, Box, Stack } from "@mui/material";
import { DataGridPro} from '@mui/x-data-grid-pro';

import { isUndefined } from "lodash";

import {Company as Model} from "portal-datatypes" 
  
import { ProjectInvoiceColumns } from "./../../Components/DataGrids/Columns/ProjectInvoiceColumns"
import { Projects as ProjectColumns } from "./../../Components/DataGrids/Columns/Projects"
import { Opportunities as OpportunityColumns } from "./../../Components/DataGrids/Columns/Opportunities"
import { Contacts as ContactColumns } from "./../../Components/DataGrids/Columns/Contacts"
import {EditCompanyDrawer} from "./../../Components/Forms/EditCompany"
import {AddProjectDrawer} from "./../../Components/Forms/AddProject"
import {AddContactDrawer} from "./../../Components/Forms/AddContact"
import {AddOpportunityDrawer} from "./../../Components/Forms/AddOpportunity"
 
import { CurrencyOutput } from "../../Core/utils/CurrencyOutput"
 
import {Single} from "./../../Components/Single"

import {withView} from "./../../Hooks/withView"



import {InfoTable} from "./../../Components/InfoTable"

import { Menu, useMenu } from "./../../Components/Menu"
import { AddItemControl } from '../../Components/Form/DrawerControl';

import { ProjectIcon, RetainerIcon, ContactIcon, OpportunityIcon, } from "./../../Components/Icons"


let CompanyControls = (props : any) => {
	const menu = useMenu()
	return (
		<ButtonGroup>
			<EditCompanyDrawer />
			
			<Menu
				label = "Add New"
				id="company-additem-menu"
				MenuListProps={{
				  'aria-labelledby': 'add-item-button',
				}}
				
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				{...menu}
			>
				<MenuItem to="">
					<ListItemIcon>
						<ProjectIcon />
					</ListItemIcon>
					<ListItemText>
                	Add Project
					</ListItemText>
            	</MenuItem>
				<MenuItem to="">
					<ListItemIcon>
						<RetainerIcon />
					</ListItemIcon>
					<ListItemText>
                	Add Retainer
					</ListItemText>
            	</MenuItem>
				<MenuItem to="">
					<ListItemIcon>
						<OpportunityIcon />
					</ListItemIcon>
					<ListItemText>
                	Add Opportunity
					</ListItemText>y
            	</MenuItem>
				
				<MenuItem to="">
					<ListItemIcon>
						<ContactIcon />
					</ListItemIcon>
					<ListItemText>
                	Add Contact
					</ListItemText>
            	</MenuItem>
			</Menu>
		</ButtonGroup>
	)
}
const InlineDataGrid = (props) => {
	return (
		<DataGridPro
			//@ts-ignore
			rows={props.rows}
			columns={props.columns}
			pageSize={25}
			rowsPerPageOptions={[5, 10, 25, 50, 100]}
			autoHeight
			disableSelectionOnClick
			sx={{
				marginBottom: 4
			}}
			{...props}
		/>
	)
}
const CustomNoRowsOverlay = (props) => {
	console.log(props)
	return (
		<Stack
			direction={"column"}
			alignContent="center"
			alignItems="center"
			justifyContent="center"
			sx = {{
				height: "100%",
				zIndex: 5,
				position: "relative"
			}}
		>
			<Box sx={{ mt: 1 }}>{props.label}</Box>
			{props.button && (
				<props.button 
					formProps = {props.formProps}
				/>
			)}
		</Stack>
	)
}
const NoProjectsOverlay = (props) => {
	return (
		<CustomNoRowsOverlay 
			label = "No Projects"
			button = {AddProjectControl}
			{...props}
		/>
	)
}
const NoOpportunitiesOverlay = (props) => {
	console.log("NoOpportunitiesOverlay", props)
	return (
		<CustomNoRowsOverlay 
			label = "No Opportunities"
			button = {AddOpportunityControl}
			{...props}
		/>
	)
}
const NoContactsOverlay = (props) => {
	return (
		<CustomNoRowsOverlay 
			label = "No Contacts"
			button = {AddContactControl}
			{...props}
		/>
	)
}
const NoInvoicesOverlay = (props) => {
	return (
		<CustomNoRowsOverlay 
			label = "No Invoices"
		/>
	)
}
const AddProjectControl = (props) => (
	<>
	  <AddItemControl 
		  label = "Add Project"
		  form = {AddProjectDrawer}
		  {...props}
	  />
	</> 
)
const AddOpportunityControl = (props) => (
	<>
		<AddItemControl 
			label = "Add Opportunity"
			form = {AddOpportunityDrawer}
			{...props}
		/>
	</>
)
const AddContactControl = (props) => (
	<>
		<AddItemControl 
			label = "Add Contact"
			form = {AddContactDrawer}
			{...props}
		/>
	</>
)

/**
	Responsible for 
	- outputting the main body of content
	- outputting the <title> etc via helmet

	To do that we need the Model and the Entity


*/



let Company = ( props : any ) => {
    
	console.log("Company Props",  props)

    let { model } = props

	const TypeChip = (props) => {
		return (	<Chip 
			label={ props.title }
			color = {props.color}
		/> )
	}
    return (
		
        	<Single 
            	{...props} 
            	ItemControl = {CompanyControls}
        	>

            <InfoTable   
                rows = {[
                    {
                        "label" : "Type" ,
                        "value" : (<TypeChip title={model.type[0]?.title} color={model.type[0]?.color}/>)
                    },
                    {
                        "label" : "Value" ,
                        "value" : CurrencyOutput(model.value)
                    },
                    {
                        "label" : "Invoiced" ,
                        "value" : CurrencyOutput(model.invoiced)
                    },
                    {
                        "label" : "Recieved" ,
                        "value" : CurrencyOutput(model.paid)
                    },
                    {
                        "label" : "Outstanding" ,
                        "value" : CurrencyOutput(model.due)
                    },
                    {
                        "label" : "To Bill" ,
                        "value" : CurrencyOutput(model.to_invoice)
                    },
                    {
                        "label" : "Primary Domain" ,
                        "value" : model.domain
                    }
                ]}
            />

    
                <Typography
                    component = "h3"
                    variant="h3"
                    gutterBottom
                >
                    Projects
                </Typography>
                <InlineDataGrid
                    //@ts-ignore
                    rows={model.projects}
                    columns={ProjectColumns}
					components={{
						NoRowsOverlay: NoProjectsOverlay,
					}}
					componentsProps={{
						noRowsOverlay: {
							formProps : {
								initialValues : {
									company : model.getID()
								}
							}
						},
					}}
                />

                <Typography
                    component = "h3"
                    variant="h3"
                    gutterBottom
                >
                    Invoices
                </Typography>

                { (isUndefined(model.invoices) === false) && (
                <InlineDataGrid
                    //@ts-ignore
                    rows={model.invoices}
                    columns={ProjectInvoiceColumns}
					components={{
						NoRowsOverlay: NoInvoicesOverlay,
					}}
                />
                ) }

                
                
                {(isUndefined(model.opportunities) === false) && (
                    <>
                        <Typography
                            component = "h3"
                            variant="h3"
                            gutterBottom
                        >
                            Opportunities
                        </Typography>
                        <InlineDataGrid
                            //@ts-ignore
                            rows={model.opportunities}
                            columns={OpportunityColumns}
							components={{
								NoRowsOverlay: NoOpportunitiesOverlay,
							}}
							componentsProps={{
								noRowsOverlay: {
									formProps : {
										initialValues : {
											company : model.getID()
										}
									}
								},
							}}
                        />
                    </>
                ) }

                {(isUndefined(model.contacts) === false) && (
                    <>
                        <Typography
                            component = "h3"
                            variant="h3"
                            gutterBottom
                        >
                            Contacts
                        </Typography>
                        <InlineDataGrid
                            //@ts-ignore
                            rows={model.contacts}
                            columns={ContactColumns}
							components={{
								NoRowsOverlay: NoContactsOverlay,
							}}
							componentsProps={{
								noRowsOverlay: {
									formProps : {
										initialValues : {
											company : model.getID()
										}
									}
								},
							}}
                        />
                    </>
                ) }
        </Single>
     )
 }



Company = withView(Company, Model)

export default Company
export {Company}