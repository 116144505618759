import React from "react"
import {ListItem, ListItemIcon, ListItemText, Collapse, List} from "@mui/material"

import { Link as RouterLink} from 'react-router-dom';

const NavListItem = (props) => {

    const renderLink = React.useMemo(
        () =>
          React.forwardRef(function Link(
            itemProps,
            ref,
          ) {
            return <RouterLink to={props.to} ref={ref} {...itemProps} role={undefined} />;
          }),
        [props.to],
      );

    const Icon = () => { 

        if(!props.icon){
            return null
        }   
    
        return (<props.icon />)
    }

    return (
        <>
        <ListItem button key={props.title} component={renderLink}>
            {props.icon && (
                <ListItemIcon>
                    <Icon />
                </ListItemIcon>
            )}
            <ListItemText primary={props.title} />
        </ListItem>
        { props.children && (
             <Collapse in={true} timeout="auto" unmountOnExit sx={{ pl: 4 }} key={props.title + '_kids'}>
                <List component="div" disablePadding>
                    {props.children.map((item : NavItem, index : number) => (
                    <NavListItem 
                        index={{index}} 
                        title={item.title}
                        to={item.path}
                        key={index}
                        icon = {item.icon}  
                    />
                    ))}
                </List>
            </Collapse>
        )}
        </>
    )
}

export {NavListItem}
export default NavListItem