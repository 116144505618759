//@ts-nocheck
import {Projects as Columns} from "../../Components/DataGrids/Columns/Projects"
import Projects from "./Projects";



const Consulting = ( props : any ) => {
	return (
		<Projects
	  		title = "Consulting"
	  		columns = {Columns}
	  		{...props}
		/>    
  	)
}

export default Consulting
export {Consulting}