import { MenuItem as MUIMenuItem } from "@mui/material";
import { useMemo, forwardRef } from "react";
import { Link as RouterLink} from 'react-router-dom';

const MenuItem = (props) => {

    const {
        divider = false
    } = props

    const renderLink = useMemo(() =>
        forwardRef(function Link(itemProps, ref) {
            return <RouterLink to={props.to} ref={ref} {...itemProps} role={undefined} />;
        }),[props.to]
    );

    return (
        <MUIMenuItem component={renderLink} divider={divider} >
            {props.children}
        </MUIMenuItem>
    )
}

export {MenuItem}
export default MenuItem