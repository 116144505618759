"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.OpportunitySlice = void 0;

var _portalRedux = require("portal-redux");

// selector helping
// firebase slice
const OpportunitySlice = new _portalRedux.FirebaseSlice({
  table: "opportunities",
  name: 'opportunities'
});
exports.OpportunitySlice = OpportunitySlice;
var _default = OpportunitySlice;
exports.default = _default;