import { OpportunityStages, OpportunityTypes, OpportunityStatuses } from 'portal-constants';

import { isEmpty, isNil } from 'lodash';

import { Button, Stack } from '@mui/material';
import { HighlightOff } from '@mui/icons-material';

import {Picker} from "./../../Components";

import { useLocation } from 'react-router-dom';
import useHistoryState from "./../../Hooks/useHistoryState"


const ActivePicker = (props) => {

    let {value} = props

    if(!isNil(value)){
        value = value.map( (v) => {
            return props.options.find( (o) => {
                return ((o.value === v) || (o.value === v.value))
            })
        })
    }

    return (
        <Picker 
            label = { props.label }
            options = { props.options }
            value = {  value  }
            multiple
            onChange = { (value) => {
                console.log(value)
                props.changeFilter({
                    [props.filterKey] : value
                })
            }}
        />
    )
}


// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();  
    return new URLSearchParams(search)
}

const OpportunityFilters = (props) => {

    const [,hist, location] = useHistoryState()

    let urlQuery = new useQuery();

    const {
        filters,
        setFilters
    } = props

    const changeFilter = (values) => {
        let newFilters = {...filters, ...values}
        setFilters(newFilters)
        urlQuery.append("filter", newFilters)
        let params = new URLSearchParams(newFilters)
        hist({}, {...location, ...{"pathname" : location.pathname + "?" + params.toString()}})
    }

    const resetFilter = (values) => {
        let newFilters = {}
        setFilters(newFilters)
        urlQuery.append("filter", newFilters)
        let params = new URLSearchParams(newFilters)
        hist({}, {...location, ...{"pathname" : location.pathname + "?" + params.toString()}})
    }


    return(

        <Stack 
            direction = "row"
            spacing = {2}
            sx = {{
                paddingBottom: 2
            }}
        >
            <ActivePicker 
                label = "Type"
                value = {filters.type ?? null}
                changeFilter = {changeFilter}
                filterKey = "type"
                options = {OpportunityTypes}
            />
            <ActivePicker  
                label = "Status"
                value = {filters.status ?? null}
                changeFilter = {changeFilter}
                filterKey = "status"
                options = {OpportunityStatuses}
            />
            <ActivePicker 
                label = "Stage"
                value = {filters.stage ?? null}
                changeFilter = {changeFilter}
                filterKey = "stage"
                options = {OpportunityStages}
            />

            { !isEmpty(filters) && (
                <Button
                    variant = "outlined"
                    endIcon = {<HighlightOff />}
                    onClick = { () => {
                        resetFilter()
                    }}
                >Reset</Button>
            ) }
        </Stack>
    )
}

export default OpportunityFilters
export {OpportunityFilters}