"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FirebaseSlice", {
  enumerable: true,
  get: function () {
    return _Slice.FirebaseSlice;
  }
});
exports.createBlankStore = createBlankStore;
Object.defineProperty(exports, "defaultReducers", {
  enumerable: true,
  get: function () {
    return _defaultReducers.defaultReducers;
  }
});
Object.defineProperty(exports, "defaultSelectors", {
  enumerable: true,
  get: function () {
    return _defaultSelectors.defaultSelectors;
  }
});
Object.defineProperty(exports, "injectFirestore", {
  enumerable: true,
  get: function () {
    return _Slice.injectFirestore;
  }
});
exports.injectStore = injectStore;
exports.store = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _reduxThunk = _interopRequireDefault(require("redux-thunk"));

var _Slice = require("./Slice");

var _defaultSelectors = require("./defaultSelectors");

var _defaultReducers = require("./defaultReducers");

let store = null;
exports.store = store;

function injectStore(store_obj) {
  exports.store = store = store_obj;
}

function createBlankStore() {
  let reducers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  exports.store = store = (0, _toolkit.configureStore)({
    reducer: { ...reducers
    },
    middleware: [_reduxThunk.default]
  });
  return store;
}

function getStore() {
  return store;
}