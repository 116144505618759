//@ts-nocheck
import React from "react"
import {HZNForm} from "../Form/Form"

import {User as Model} from "portal-datatypes" 
import { withFormDrawer } from "../Form/Drawer"

const AddUser = (props : any) => {
    return(
      <HZNForm  
          Model = {Model}
          fields = {Model.getFormFields()}
          title = "Create a New User"
          buttonText = "Add User"
          {...props}
          action = "save"
          successMessage = "User Added"
          showAddAnotherButton = {true}
      />
    )
}

const AddUserDrawer = withFormDrawer(AddUser)

export default AddUser
export {AddUser, AddUserDrawer}