import { List, ListSubheader, Divider } from "@mui/material"

import {Sidebar, NavListItem} from "../Sidebar"

import { categories as integration_categories } from "./../../Core/Integrations"

export default function SettingsSidebar(props){
    return (
    <Sidebar>
        <List>
                <ListSubheader>
                    Connections
                </ListSubheader>
                <Divider />
                {Object.keys(integration_categories).map( (key, index) => {
                    let type = integration_categories[key]
                    return (
                        <NavListItem title={type.label} to={`/settings/connections/${type.key}`} key={index} />
                    )
                })}
                <Divider />
                <ListSubheader>
                    Team
                </ListSubheader>
                <Divider />
                <NavListItem title="Members" to="/settings/team/members" />
                
        </List>
    </Sidebar>
    )
}