import { TableRow, TableCell } from "@mui/material"

const InfoRow = ( props : {
    label : string,
    value : string,
}) => {

    return (
        <TableRow>
            <TableCell sx={{"width" : '150px'}}>{props.label}</TableCell>
            <TableCell>{props.value}</TableCell>
        </TableRow>
    )
}

export {InfoRow}
export default InfoRow
