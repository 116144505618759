"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.companiesSlice = void 0;

var _portalRedux = require("portal-redux");

const companiesSlice = new _portalRedux.FirebaseSlice({
  table: "companies",
  name: 'companies'
});
exports.companiesSlice = companiesSlice;
var _default = companiesSlice;
exports.default = _default;