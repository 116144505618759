
import {  Box, Stack, Typography} from "@mui/material";

import { useDrop } from 'react-dnd'

import Column from "./Column"

import {OpportunityStatuses} from "portal-constants"

const DragType = (props) => {

    const [collectedProps, dropRef] = useDrop(() => ({
        accept : "CARD",
        collect : (monitor, props) => {
            return {
                ...props,
                isHovered : monitor.isOver()
            }
        },
        drop: (item, monitor) => {
            props.updateStatus(item, props.index)
        }
    }))

    let style = {
        padding: 8,
        backgroundColor: "#f5f5f5",
        border:"1px solid #e1e1e1",
        borderLeft:0,
        
        minWith:"15%",
        maxWith:"25%",
        ":first-of-type" : {
            borderLeft:"1px solid #e1e1e1",
        }
    }

    if(collectedProps.isHovered){
        style.width = "25%"
        style.backgroundColor = "#ffffff"
    }

    return (
        <Box ref={dropRef}
            sx={style}
            flexGrow={1}
        >
            <Typography
                align="center"
            >{props.children}</Typography>
        </Box>
    )
}

const DragTypeSelect = (props) => {

    return (
        <Box sx={{
            position : "fixed",
            bottom: 0,
            left: 0,
            right: 0,
        }}>
        <Stack
            direction="row"
            spacing={0}
            alignContent={"center"}
        >
            { OpportunityStatuses
                .filter( status => (status.value !== 0) )
                .map( (status, index) => ( 
                    <DragType
                        key = {index}
                        index = {status.value}
                        updateStatus = {props.updateStatus}
                    >
                        {status.title}
                    </DragType>
                ) ) 
            }
        </Stack>
        </Box>
    )
}

const Kanban = (props) => {

    const {cards} = props

    

    const [collectedProps, dropRef] = useDrop(() => ({
        accept : "CARD",
        collect : (monitor, props) => {
            return {
                ...props,
                isHovered : monitor.isOver()
            }
        }
    }))

    

    return (
        <Box sx={{ overflow: "hidden"}} ref={dropRef}>

            <Stack spacing={2} direction="row" sx={{width: "100%", overflow: "scroll", padding: 1, marginBottom: 4}}>
                {props.columns.sort( (a,b) => a.order - b.order ).map( (col, index) => (

                    <Column 
                        heading = {col.title} 
                        cards = {cards.filter( (card) => {
                            return card.stage.find( c => c.value === col.value)
                        })}
                        index = {col.value}
                        key = {index}
                        updateStage = {props.updateStage}
                    />

                ))}
            </Stack>

            {collectedProps.isHovered && ( 
                <DragTypeSelect 
                    updateStatus = {props.updateStatus}
                /> 
            )}
            
        </Box>
    )
}
export default Kanban
export {Kanban}