
import {GridRowParams} from "@mui/x-data-grid-pro";
import { EditRetainerPeriodDrawer } from "../../Forms/EditRetainerPeriod";
import ActionSet from "../ActionSets/ActionSet";

import {Currency} from "./../ColumnTypes/Currency"

const Retainers = [
    { field: 'iteration', headerName: 'Iteration', width:120}, 
    { field: 'year', headerName: 'Year'}, 
    { field: 'month', headerName: 'Month', width:120},
    { field: 'hours', headerName: 'Purchased', type: 'number', width:130}, 
    { field: 'rolled_over', headerName: 'Rolled Over',type: 'number',  width:150},
    { field: 'available', headerName: 'Available', type: 'number', width:120},
    { field: 'used', headerName: 'Used', type: 'number', width:120},
    { field: 'unused', headerName: 'Unused', type: 'number' },
    { field: 'overage', headerName: 'Overage', width:120, type: 'number' }, 
    { field: 'rolling_over', headerName: 'Rolling Over', width:140, type: 'number' },
    { field: 'base_cost', headerName: 'Base Cost', width:130, ...Currency },
    { field: 'overage_rate', headerName: 'Overage rate', width:130, ...Currency },
    { field: 'overage_cost', headerName: 'Overage Cost', width:150, ...Currency },
    { field: 'total_cost', headerName: 'Total Cost', width:130, ...Currency },
    { field: 'actions', headerName: 'Actions', width:130, type: "actions",
      getActions: (params: GridRowParams) => [(<ActionSet 
        {...params} 
         editForm={EditRetainerPeriodDrawer} 
      />)]
    }
  ];

  export default Retainers
  export {Retainers}