//@ts-nocheck
import React from "react"
import {HZNForm} from "../Form/Form"

import {Retainer as Model} from "portal-datatypes" 
import { withFormDrawer } from "../Form/Drawer"

const AddRetainer = (props : any) => {
    return(
      <HZNForm
          Model = {Model}
          fields = {Model.getFormFields()}
          title = "Setup Retainer"
          buttonText = "Save"
          {...props}
          action = "save"
          successMessage = "Retainer Added"
          showAddAnotherButton = {false}
      />
    )
}

const AddRetainerDrawer = withFormDrawer(AddRetainer)

export default AddRetainer
export {AddRetainer, AddRetainerDrawer}