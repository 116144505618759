import { createSlice } from '@reduxjs/toolkit'
import { ProcessStates } from 'portal-constants'


const  AppSlice = createSlice({
  name: 'app',
  initialState: {
    populating: {
      state : ProcessStates.NOT_STARTED,
      slices : {}
    },
	alerts:{
	},
    viewability: {
      openTrees : {},
      focused : null
    }
  },
  reducers: {
    setPopulating :  (state, action) => {
      state.populating.state = action.payload
    },
    setPopulatingSlice :  (state, action) => {
      state.populating.slices[action.payload.name] = action.payload.state
    },
    updatePopulatingState: (state, action) => {
      state.populating.slices[action.payload.name] = action.payload.state
    },
    setFocused: (state, action) => {
      state.viewability.focused = action.payload
    },
    setOpen: (state, action) => {
      state.viewability.openTrees[action.payload] = true
    },
    setClosed: (state, action) => {
      delete state.viewability.openTrees[action.payload]
    },
    setUserState :  (state, action) => {
        state.user.status = action.payload
    },
    setUserData :  (state, action) => {
        state.user.data = action.payload
    },
	dismissAlert: (state, action) => {
		console.log("dismissAlert", action)
		delete state.alerts[action.payload]
	},
	addAlert: (state, action) => {
		state.alerts = {...state.alerts, ...action.payload}
	},
	addFlash: (state, action) => {
		
		let payload = {
			...action.payload,
			isFlash : true,
			hasFlashed : false,
		}

		state.alerts = {
			...state.alerts, 
			...{
				[payload.key] :  payload
			}
		}
	},
	recordFlashed: (state, action) => {
		state.alerts[action.payload].hasFlashed = true
	},
	removeFlashed: (state, action) => {

		Object.keys(state.alerts).filter(
			(alert) => {
				return (state.alerts[alert].isFlash && state.alerts[alert].hasFlashed)
			}
		).forEach( (alert) => {
			delete state.alerts[alert]
		})
		
	},
  }
})


const getCurrentUser = ( (state) => {
	return state.auth.user
})

const getCurrentUserID = ( (state) => {
	return state.auth.user?.id
})

const getAlerts = ( (state) => {
	return state.app.alerts
})

const getFlashes = ( (state) => {
	return state.app.alerts.filter((a) => a.isFlash);
})

const getFlashed = ( (state) => {
	return state.app.alerts.filter((a) => (a.isFlashed && a.hasFlashed));
})

export {AppSlice, getCurrentUser, getCurrentUserID, getAlerts, getFlashes, getFlashed}