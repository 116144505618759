//@ts-nocheck

// FireBase
import firebase from "./../../Core/Firebase"
import { useListVals } from 'react-firebase-hooks/database';
import { getDatabase, ref,  child, update } from 'firebase/database';

// Util
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { isNull} from "lodash"

// View
import { Archive } from "../../Components/Archive/Archive"

// MUI Compnents * Styling
import { Autocomplete, TextField, IconButton } from "@mui/material"
import { Check, Save, Sync, VisibilityOff } from '@mui/icons-material';
import { GridValueGetterParams, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { GridRowParams } from '@mui/x-data-grid-pro';


// PBC Components
import { HZNDataGrid } from '../../Components/DataGrids/HZNDataGrid';
import { Project } from "portal-datatypes"


type importedItem = {
    apiResponse : any
}

/*
const useStyles = makeStyles((theme) => ({
    spin: {
        //@ts-ignore
        animation: `$rotate 1000ms linear`
    },
    fadeout: {
        //@ts-ignore
        animation: `$fadeout 2000ms linear`
    },
    "@keyframes rotate": {
        "0%": {
          transform: "rotate(0deg)"
        },
        "100%": {
          transform: "rotate(360deg)"
        }
    },
    "@keyframes fadeout": {
        "0%": {
          opacity: 1
        },
        "100%": {
            opacity: 0
        }
    }
}));
*/

const ProjectSelectAction = (props : {
    options : any,
    harvest_id : string,
    value ?: any
}) => {
    
   // const classes = useStyles();

    let [project, setProject] = useState(null)
    let [isSaving, setIsSaving] = useState(false)
    let [isSaved, setIsSaved] = useState(false)
    let touched = !isNull(project)

    console.log(props.value)

    const save = (event: any) => {
        setIsSaving(true)

        //@ts-ignore
        let project_model = Project.get(project.id)()

        //@ts-ignore
        project_model.data.harvest = project_model.data.harvest ?? {}
        //@ts-ignore
        project_model.data.harvest.id = props.harvest_id
        //@ts-ignore
        project_model.save()

        setIsSaving(false)
        setIsSaved(true)

    }


    return (
        <>
            <ProjectAutoComplete 
                options = {props.options} 
                value = {project ?? props.value ?? null}
                setValue = {setProject} 
            />

            { (touched && !isSaving && !isSaved) && (
                <IconButton onClick = { save } >
                    <Save />
                </IconButton>
            )}

            { (touched && isSaving && !isSaved) && (
                <Sync  />
            )}

            { (touched && !isSaving && isSaved) && (
                <Check />
            )}


        </>
    )
}

const ProjectAutoComplete = ( props : {
    options : any,
    value ?: string | null
    setValue : Function
}) => {
    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            getOptionLabel={ (option:any) => { return option.title} }
            options={ props.options }
            groupBy={(option:any) => option.company}
            value = {props.value ?? null}
            sx={{ 
                width: 200,
                marginTop: "0.5rem"
             }}
            renderInput={(params) => <TextField {...params} label="Project" />}
            onChange = { ( event, value ) => {
                props.setValue(value)
            }}
        />
    )
}



const ConnectHarvestProjects = () => {
    const db = getDatabase(firebase)
    const [snapshots] = useListVals<Array<any>>(
        ref(
            db, 
            "import/harvest/projects"
        )
    );
    
    //@ts-ignore
    let rows:importedItem[] = snapshots.filter( (item:importedItem) => {

        if(!item.apiResponse.is_active){
            return false
        }

        if(isUndefined(item.computed)){
            return true
        }

        if(isUndefined(item.computed.hide)){
            return true
        }

        return !item.computed.hide

    })

    rows = rows.map( (row:any) => {
        
        let found_project = Project.getByAttr("harvest.id", row.apiResponse.id)().items
        if(isEmpty(found_project)){
            return row
        }

        // this feels dumb but we're only passing the apiResponse into the grid sp we need to add this value here so its used
        //@ts-ignore
        row.internal_project = {
            id: found_project[0].id,
            title: found_project[0].title
        }

        return row    
    })
    

    console.log(rows)

    let project_options = useSelector(
        Project.getAll()
    ).toArray().map( (project: any) => {

        let company:string = ""

        if(isUndefined(project.companies[0])){
            company = "-"
        } else {
            // @ts-ignore
            company = project.companies[0].title
        }

        return {
            id : project.id,
            title : project.title,
            company : company
        }
        //@ts-ignore
    }).sort( (a, b) => {

        if(a.company > b.company){
            return 1
        }else if(a.company === b.company){
            return 0
        }
        else if(a.company < b.company){
            return -1
        }

        return 0
    })

   

    const columns:Array<any> = [
        {
            field : "name",
            headerName : "Harvest Project",
            width : 250,
            valueGetter: (params: GridValueGetterParams) => {

                return params.row.apiResponse.name
            }
        },
        {
            field : "client",
            headerName : "Harvest Company",
            width : 250,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.apiResponse.client.name
            }
        },
       
        {
            field : "project",
            headerName : "Project",
            width : 250,
            type : "actions",
            align: "left",
            getActions: (params: GridRowParams) => [(
                <ProjectSelectAction 
                    options={project_options} 
                    value={params.row.internal_project} 
                    harvest_id={params.row.apiResponse.id}
                />
            ),(
                <GridActionsCellItem icon={(<VisibilityOff />)} onClick={ async () => {
                    //const db = getDatabase(firebase)
                    let update_ref = ref(db, "import/harvest/projects")
                    console.log(params)
                    let child_ref = child(update_ref, params.id)
                    update(child_ref, {
                        "computed" : {
                            "hide" : true
                        }
                    })
                }} label="Hide" ></GridActionsCellItem>
            )]
        }
    ]

    return(
        <Archive
            title = "Connect Harvest Projects"
        >
            <HZNDataGrid 
                columns = {columns}
                rows = {rows.map( (row:importedItem) => {return {...row, "id" : row.key} } ) }
            />            
        </Archive>
    )
}
export {ConnectHarvestProjects}
export default ConnectHarvestProjects


