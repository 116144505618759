import {Stack} from "@mui/material"

import {User} from "portal-datatypes"

import {Accordion} from "./../../Accordion"
import {EntityIcon} from "./../../EntityIcon"
import { AutoCompleteFromModel } from "./../../Fields/AutoComplete"

const OwnerAccordion = (props) => {

    if(props.model.users.constructor.name !== "User"){
        return null
    }

    return (
        <Accordion
            id="accordion-deal-owner"
            summary = "Deal Owner"
        >
             <Stack
                direction="row"
                spacing = {2}
                alignItems={"center"}
            >
                <EntityIcon model= {props.model.users} />
                <AutoCompleteFromModel
                    name  = "opp-user-select"
                    model = {User}
                    value = {props.model.users.id}
                    setValue = {(name, value) => {
                        console.log("setValue", value)
                        props.model.set({"user" : value})
                        props.model.save()
                    }}
                />
            </Stack>
        </Accordion>
    )
}

export {OwnerAccordion}
export default OwnerAccordion