//@ts-nocheck
import React from "react"
import {HZNForm} from "../Form/Form"

import {RetainerPeriod as Model} from "portal-datatypes" 
import { withFormDrawer } from "../Form/Drawer"
  
const AddRetainerPeriod = (props : any) => {

    console.log("AddRetainerPeriod", props)
    return(
      <HZNForm
          Model = {Model}
          fields = {Model.getFormFields()}
          title = "Create Retainer Period"
          buttonText = "Save"
          {...props}
          action = "save"
          successMessage = "Retainer Period Added"
          showAddAnotherButton = {false}
      />
    )
}

const AddRetainerPeriodDrawer = withFormDrawer(AddRetainerPeriod)

export default AddRetainerPeriod
export {AddRetainerPeriod, AddRetainerPeriodDrawer} 