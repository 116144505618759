import {Box, Stack, Typography, Divider, BoxProps} from "@mui/material"

import {Menu} from "./Menu"
import {StatusMenu} from "./StatusMenu"
import {ViewSwitcher} from "./ViewSwitcher"
import { alpha, styled } from '@mui/material/styles';

type HeadingProps = BoxProps & {
	title ?: string
	children ?: JSX.Element|JSX.Element[]
	titleMenu ?:  any
	controls ?:  JSX.Element|JSX.Element[]
	statusMenu ?: any
	setStatus ?: Function
	status ?: any,
	enableViewSwitcher?: boolean,
	selectedView?: number,
	setSelectedView?: Function
}



const Heading = (props : HeadingProps) => {

	const {
		enableViewSwitcher = false,
		selectedView,
		setSelectedView
	} = props

	return (
		<Box
			sx = {{
				marginBottom: 2,
				backgroundColor: 'primary.light'
			}}
			id = {props.id}
		>
			<Stack 
				direction={"row"}
				alignContent = "center"
				alignItems = "center"
    			justifyContent = "space-between"
				sx = {{
					"padding":2
				}}
			>
				<Stack
					direction="row"
					alignItems = {"center"}
					spacing={2}
				>
					{(props.title) && (!props.titleMenu) && (
						<Typography component = "h1" variant = "h4" color="primary" >
							{ props.title }
						</Typography>
					)}

					{(props.titleMenu) && (
						<Menu 
							title = { props.title }
							items = { props.titleMenu }
						/>						
					)}

					{(props.statusMenu) && (
						<StatusMenu 
							value = { props.status }
							setStatus = {props.setStatus}
							statuses = {props.statusMenu}
						/>
					)}

					{(enableViewSwitcher) && (
						<ViewSwitcher 
							selectedView={selectedView} 
							setSelectedView={setSelectedView} 
						/>
					)}

				</Stack>
        		<Box>
          			{ props.controls }
        		</Box>
      		</Stack>
			<Divider />
	  </Box>
	)
} 


export default Heading
export {Heading}