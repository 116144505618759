"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.retainerSlice = exports.default = void 0;

var _portalRedux = require("portal-redux");

// selector helping
// firebase slice
const retainerSlice = new _portalRedux.FirebaseSlice({
  table: "retainers",
  name: 'retainers'
});
exports.retainerSlice = retainerSlice;
var _default = retainerSlice;
exports.default = _default;