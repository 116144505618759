import {OverviewTable} from "./../OverviewTable"

import InfoRow from "../InfoRow"
import { AddItemControl } from "../Form/DrawerControl"
import { EditRetainerDrawer } from "../Forms/EditRetainer"

import CurrencyOutput from "../../Core/utils/CurrencyOutput"
import { PercentageOutput } from "../../Core/utils/PercentageOutput"
import {isEmpty} from "lodash"
import {Section} from "../Section"

const RetainerOverviewTable = (props : {
    model : any
}) => {
    let {model} = props


    if(isEmpty(model)){ return null; }

    
    return (
        
        <Section
            title = "Retainer"
            controls = {( <AddItemControl
                label = "Modify Retainer"
                form = {EditRetainerDrawer}
                item = {props.model}
            />)}
        >

            <OverviewTable>
                <InfoRow label="Period" value={props.model.period} />
                <InfoRow label="Hours" value={props.model.hours} />
                <InfoRow label="Overage Rate" value={CurrencyOutput(props.model.overage_rate)}  />
                <InfoRow label="Base Rate" value={CurrencyOutput(props.model.base_rate)}  />
                <InfoRow label="Allowed Rollover %" value = {PercentageOutput(props.model.allowed_rollover)} />
                <InfoRow label="Base Cost" value = {CurrencyOutput(props.model.base_cost)} />
            </OverviewTable>
        </Section>
    )
}

export default RetainerOverviewTable
export {RetainerOverviewTable}
