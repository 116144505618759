"use strict";

require("core-js/modules/web.dom-collections.iterator.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.RetainerPeriod = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var yup = _interopRequireWildcard(require("yup"));

var _Slice = _interopRequireDefault(require("./Slice.js"));

var _portalModel = require("portal-model");

var _moment = _interopRequireDefault(require("moment"));

var _lodash = require("lodash");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//@ts-nocheck
//external
// data
//import {Model} from './../../Core/Model'
class RetainerPeriod extends _portalModel.Model {
  // these make typescript stop complaining
  // TODO : Make Model parent typescript and use these better
  static loadSchema() {
    this.schema = require("./Schema.js").Schema;
  }

  // I'm overriding the constructor at the moment 
  // to stop the getters being get from the data array
  constructor(args) {
    super(args, false);
    this.cached = {};
  }

  get rolled_over() {
    var _this$links$previous$;

    if ((0, _lodash.isUndefined)(this.links.previous)) {
      return 0;
    }

    return (_this$links$previous$ = this.links.previous.rolling_over) !== null && _this$links$previous$ !== void 0 ? _this$links$previous$ : 0;
  }

  get available() {
    var _this$hours, _this$rolled_over;

    return parseInt((_this$hours = this.hours) !== null && _this$hours !== void 0 ? _this$hours : 0) + parseInt((_this$rolled_over = this.rolled_over) !== null && _this$rolled_over !== void 0 ? _this$rolled_over : 0);
  }

  get times() {
    console.log(this);

    if ((0, _lodash.isUndefined)(this.retainers.projects)) {
      return [];
    }

    if ((0, _lodash.isUndefined)(this.retainers.projects.times)) {
      return [];
    }

    return this.cacheCall("times", () => {
      return this.retainers.projects.times.filter(item => {
        let test_date = new _moment.default(item.spent_date).valueOf();

        if (test_date < this.period_start.valueOf()) {
          return false;
        }

        if (test_date > this.period_end.valueOf()) {
          return false;
        }

        return true;
      });
    }, []);
  }

  get used() {
    return this.cacheCall("used", () => {
      return this.times.reduce((total, item) => {
        return total + item.hours;
      }, 0);
    }, 0);
  }

  _used() {
    return this.cached.used;
  }

  get unused() {
    return Math.max(parseInt(this.available) - this.used, 0);
  }

  get overage() {
    return Math.max(this.used - this.available, 0);
  }

  get overage_cost() {
    return this.overage * this.overage_rate;
  }

  get rolling_over() {
    let max_rollover = this.hours / 100 * this.allowed_rollover;
    let rolling_over = Math.min(max_rollover, this.hours - this.used);
    return Math.max(rolling_over, 0);
  }

  get allowed_rollover() {
    var _this$data$allowed_ro;

    return (_this$data$allowed_ro = this.data.allowed_rollover) !== null && _this$data$allowed_ro !== void 0 ? _this$data$allowed_ro : 50;
  }

  get total_cost() {
    return this.base_cost + this.overage * this.overage_rate;
  }

  get year() {
    return (0, _moment.default)(this.period_start).format("YYYY");
  }

  get month() {
    return (0, _moment.default)(this.period_start).format("MMMM");
  }
  /*
  get iteration(){
      return "001"
  }
  */


  get period_start() {
    return this.cacheCall("period_start", () => {
      return (0, _moment.default)(this.data.period_start);
    }, 0);
  }

  get period_end() {
    return this.cacheCall("period_end", () => {
      if (_lodash.isUndefined) {
        return new _moment.default(this.data.period_start).endOf("month");
      }

      return (0, _moment.default)(this.data.period_end);
    }, 0);
  }

  get hours() {
    var _this$data$hours;

    if ((0, _lodash.isUndefined)(this.data.hours)) {
      if (!(0, _lodash.isUndefined)(this.links.previous)) {
        this.data.hours = this.links.previous.hours;
      }
    }

    return Math.max((_this$data$hours = this.data.hours) !== null && _this$data$hours !== void 0 ? _this$data$hours : 0, 0);
  }

}

exports.RetainerPeriod = RetainerPeriod;
(0, _defineProperty2.default)(RetainerPeriod, "slice", _Slice.default);
(0, _defineProperty2.default)(RetainerPeriod, "schema", void 0);
(0, _defineProperty2.default)(RetainerPeriod, "form", {
  period_start: {
    type: "string",
    form: true,
    field: {
      type: "date",
      id: 'period_start',
      name: 'period_start',
      label: "Period Start Date",
      placeholder: "10",
      helperText: "Period Start Date",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  hours: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'hours',
      name: 'hours',
      label: "Hours",
      placeholder: "10",
      helperText: "Hours Purchased This Period",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  base_rate: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'base_rate',
      name: 'base_rate',
      label: "Base Hourly Rate",
      placeholder: "£85",
      helperText: "Cost per Extra Hour Purchased",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  overage_rate: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'overage_rate',
      name: 'overage_rate',
      label: "Overage Rate",
      placeholder: "£85",
      helperText: "Cost per Extra Hour Used",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  base_cost: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'base_cost',
      name: 'base_cost',
      label: "Base Per Period",
      placeholder: "£1,000.",
      helperText: "Cost per Period",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  allowed_rollover: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'allowed_rollover',
      name: 'allowed_rollover',
      label: "Allowed Rollover",
      placeholder: "50",
      helperText: "What % of purchased hours can be rolled over ",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  }
});
var _default = RetainerPeriod;
exports.default = _default;