import {Picker} from "./../Picker"


/**
 * Wrap the statuses object to add a -1 value for "All", order them by value and append a pathname for the menu to use.
 * 
 * @param statuses 
 * @param pathname 
 * @returns 
 */
 const prepStatuses = (statuses:Array<any>) => {

	return [
		...statuses,
		{
			value : -1,
			title : "All"
		}
	].sort( ( a, b) => {
		return a.value - b.value
	} ).map( (status) => {
		return {
			...status,
		}
	})
}

const StatusMenu = (props : {
	statuses: any,
	value: any,
	setStatus ?: Function
}) => {
	
    return (
        <Picker 
            label = "Status"
            options = {prepStatuses(props.statuses)}
            value = {props.value}
            onChange = { (value:number) => {
               props.setStatus?.([value])
            } }
        />
    )
}

export default StatusMenu
export {StatusMenu}