"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.projectsSlice = exports.default = void 0;

var _portalRedux = require("portal-redux");

// selector helping
// firebase slice
const projectsSlice = new _portalRedux.FirebaseSlice({
  table: "projects",
  name: 'projects'
});
exports.projectsSlice = projectsSlice;
var _default = projectsSlice;
exports.default = _default;