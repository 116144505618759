import { useHistory, useLocation } from "react-router-dom"
import { LocationState } from "../Core/types"
import {isUndefined} from "lodash"

export default function useHistoryState(key : string, default_value : any ){

    let history = useHistory()
    let location = useLocation<LocationState>()

    if(isUndefined(location.state)){
      location.state = {} 
    }
    
    let setState = ( value :any, setLocation:any = null) => {
      
      let new_state = {
        ...location.state,
      }
      new_state[key] = value

      let usedLocation = setLocation ?? location

      console.log(usedLocation)
  
      history.push(usedLocation.pathname, new_state )
    }
   
    return [(location.state[key] ?? default_value), setState, location, history];
  }