import {OverviewTable} from "./../OverviewTable"
import InfoRow from "../InfoRow"


const ProjectOverviewTable = (props : {
    model : any
}) => {

    let {model} = props

    return (
        <OverviewTable>
            <InfoRow label="Client" value={model.companies.title} />
            <InfoRow label="Type" value={model.type[0].title}/>
            <InfoRow label="Value" value={model.budget} />
            <InfoRow label="Invoiced" value={model.invoiced}/>
            <InfoRow label="Harvest Project ID" value = {model.harvest_project_id}/>
        </OverviewTable>
    )
}

export default ProjectOverviewTable
export {ProjectOverviewTable}
