import { isUndefined } from "lodash"
import { ChipList } from "./../../ChipList"
import { GridFilterInputSingleSelect, GridFilterInputMultipleSingleSelect} from "@mui/x-data-grid-pro"; 

const parseObjectValue = (value: GridFilterItem) => {
	if (value == null || typeof value !== 'object') {
	  return value;
	}
	return value.value;
};

const makeFilterOperators = () => {



	return [
		{
			value : "is",
			label : "Is",
			getApplyFilterFn: (filterItem) => {
				if (filterItem.value == null || filterItem.value === '') {
					return null;
				}
				return ({ value }): boolean => {
					return value.map( v => v.value).includes( parseObjectValue(filterItem.value) )
				}
			},
			InputComponent: GridFilterInputSingleSelect,
		},
		{
			value : "isnot",
			label : "Is Not",
			getApplyFilterFn: (filterItem) => {
				if (filterItem.value == null || filterItem.value === '') {
					return null;
				}
				return ({ value }): boolean => {
					return !value.map( v => v.value).includes( parseObjectValue(filterItem.value) )
				}
			},
			InputComponent: GridFilterInputSingleSelect,
		},
		{
			value : "isanyof",
			label : "Is Any Of",
			getApplyFilterFn: (filterItem) => {

				
				if (filterItem.value == null || filterItem.value === '') {
					return null;
				}
				return ({ value }): boolean => {
					//return value?.[0]?.value === parseObjectValue(filterItem.value)
					let itemValues = value.map( v => v.value )
					return itemValues.reduce( (prev, current) => {
						// short circit, we found this already
						if(prev){
							return prev
						}

						return filterItem.value.includes(current)

					}, false)

				}
			},
			InputComponent: GridFilterInputMultipleSingleSelect,
		}

	]
}

const Picked = {
	align: "center",
	headerAlign : "center",
	type: 'singleSelect',
    renderCell: (params) => {

        if(isUndefined(params.value)){
            return null;
        }
        return (<ChipList types={params.value} />)
    },
	filterOperators: makeFilterOperators()
}


export {Picked}
export default Picked