import { NavItem } from './../types'


const workMenu:NavItem = {
	title   : "Work",
	items : [
		{
			title : "Current Work",
			path    : "/projects/",
			divider : true,
		},
		{
			title : "Projects",
			path    : "/projects/build",
		},
		{
			title : "Retainers",
			path    : "/projects/retainers"
		},
		{
			title : "Consulting",
			path    : "/projects/consulting"
		},
	]
}

const contactMenu:NavItem = {
	title   : "Contacts",
	items : [
		{
			title : "Companies",
			path    : "/companies",
		},
		{
			title : "People",
			path    : "/contacts",
			divider : true,
		},
		{
			title : "Clients",
			path    : "/companies/clients",
		},
		{
			title : "Prospects",
			path    : "/companies/prospects",
		},
		{
			title : "Leads",
			path    : "/companies/leads",
		},
		{
			title : "Targets",
			path    : "/companies/targets",
		},
		{
			title : "Partners",
			path    : "/companies/partners",
		},
		{
			title : "Suppliers",
			path    : "/companies/suppliers",
		},
		{
			title : "Alumni",
			path    : "/companies/alumni",
		},
	]
}

const Nav:Array<NavItem> = [
    {
        title   : "Dashboard",
        path    : "/dashboard",
    },
    contactMenu,
    workMenu,
    {
        title   : "Finance",
        items : [
            {
                title : "Invoices",
                path    : "/invoices"
            },
        ]
    },
    {
        title   : "Sales",
        items : [
            {
                title : "Opportunities",
                path    : "/opportunities"
            },
            {
                title : "Partners",
                path    : "/companies/partners",
                divider : true
            }
        ]
    },

]


export default Nav
export {Nav, workMenu, contactMenu}