
import { Switch, FormControlLabel } from "@mui/material"

const SwitchField = (props) => {
    console.log(props)
    return (
        <FormControlLabel 
            control={
                <Switch 
                    defaultChecked 
                    checked = {props.value}
                    onChange = { (e) => {
                        props.setValue(props.name, e.target.checked)
                    }}
                />
            } 
            label={props.label} 
        />
    )
}

export {SwitchField}
export default SwitchField