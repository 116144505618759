import {createFirebase} from "./Firebase"
import {createStore} from "./Redux"
import {createAuth} from "./Auth"
import {createIntegrations} from "./Integrations"
import App from './App'


let app = new App({
    FireBase : createFirebase(),
    Store : createStore(),
    Auth : createAuth(),
	Integrations : createIntegrations()
});

export {app}

