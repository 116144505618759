"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "isUndefinedEmptyOrNull", {
  enumerable: true,
  get: function () {
    return _isUndefinedEmptyOrNull.isUndefinedEmptyOrNull;
  }
});

var _isUndefinedEmptyOrNull = require("./isUndefinedEmptyOrNull");