"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ProjectTypesTaxonomy = exports.ProjectTypes = void 0;

var _Taxonomy = _interopRequireDefault(require("./Utils/Taxonomy"));

const ProjectTypes = [{
  value: 0,
  title: "Build",
  slug: "build"
}, {
  value: 1,
  title: "Retainer",
  slug: "retainer"
}, {
  value: 2,
  title: "Consulting",
  slug: "consulting"
}];
exports.ProjectTypes = ProjectTypes;
const ProjectTypesTaxonomy = new _Taxonomy.default(ProjectTypes);
exports.ProjectTypesTaxonomy = ProjectTypesTaxonomy;
var _default = ProjectTypes;
exports.default = _default;