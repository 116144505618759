"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Schema = void 0;

var _portalModel = require("portal-model");

var _portalConstants = require("portal-constants");

var _Companies = require("../Companies");

var _Contact = require("../Contact");

var _Users = require("../Users");

var _Notes = require("../Notes");

const Schema = {
  'id': (0, _portalModel.id)(),
  title: (0, _portalModel.attr)(),
  stage: (0, _portalModel.picked)(_portalConstants.OpportunityStages),
  status: (0, _portalModel.picked)(_portalConstants.OpportunityStatuses),
  type: (0, _portalModel.picked)(_portalConstants.OpportunityTypes),
  value: (0, _portalModel.attr)(),
  date_created: (0, _portalModel.attr)(),
  date_closed: (0, _portalModel.attr)(),
  date_original_expected_close: (0, _portalModel.attr)(),
  date_current_expected_close: (0, _portalModel.attr)(),
  date_last_updated: (0, _portalModel.attr)(),
  notes: (0, _portalModel.foreignKey)(_Notes.Note, "parent"),
  tasks: "",
  company: (0, _portalModel.belongsTo)(_Companies.Company),
  contacts: (0, _portalModel.hasMany)(_Contact.Contact),
  budget_high_estimate: (0, _portalModel.attr)(),
  budget_low_estimate: (0, _portalModel.attr)(),
  budget_known: (0, _portalModel.attr)(),
  description: (0, _portalModel.attr)(),
  loss_reason: (0, _portalModel.attr)(),
  loss_reason_comment: (0, _portalModel.attr)(),
  user: (0, _portalModel.belongsTo)(_Users.User),
  created_by: "",
  tasks: ""
};
exports.Schema = Schema;
var _default = Schema;
exports.default = _default;