import {Route as RouteType} from "./../Core/types"

import {Opportunities as ListView} from '../Views/Opportunities/Opportunities'
import {Opportunity as SingleView} from '../Views/Opportunities/Opportunity'
import {Pipeline as ColsView} from '../Views/Opportunities/Pipeline'


const Routes: Array<RouteType> = [
    {
        "path" : "/opportunities/:guid",
        "view" : SingleView
    },
    {
        "path" : "/opportunities",
        "view" : ListView
    },
    {
        "path" : "/pipeline",
        "view" : ColsView
    },
]

const Opportunities = {
    routes : Routes
} 

export {Opportunities}