import { useSelector } from "react-redux"
import { IconButton, Avatar } from "@mui/material"
import {isEmpty, isNil} from "lodash"

const UserMenuTrigger = ( props ) => {
    
    const user = useSelector((state) => state.auth.user)

	console.log(user.photoURL)
    
    if(isNil(user) || isEmpty(user)){
        return false
    }

    return(
        <IconButton
            onClick={props.onClick}
        >
            <Avatar alt={user.displayName} src={user.photoURL} />
        </IconButton>
    )
}

export default UserMenuTrigger
export {UserMenuTrigger}