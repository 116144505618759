"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Contact", {
  enumerable: true,
  get: function () {
    return _Model.Contact;
  }
});
Object.defineProperty(exports, "Slice", {
  enumerable: true,
  get: function () {
    return _Slice.ContactSlice;
  }
});

var _Model = require("./Model");

var _Slice = require("./Slice");