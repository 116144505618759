import { forwardRef } from "react"
import { TransitionGroup } from "react-transition-group"
import { useSelector, useDispatch } from "react-redux";
import { getAlerts, AppSlice } from "./../../Core/Redux/app-slice"
import { isEmpty } from "lodash";
import { Alert as MUIAlert, Box, IconButton, Fade} from '@mui/material';
import { Close } from '@mui/icons-material';
import { alpha, styled } from '@mui/material/styles';

import classnames from "classnames"

const Alert = forwardRef((props, ref) => {

	const dispatch = useDispatch()

	const {
		isFlash = false,
		isRounded = true
		
	} = props

	if(isFlash){
		dispatch(AppSlice.actions.recordFlashed(props.index))
	}

	return (
		
			<MUIAlert
				ref={ref}
				{...props} 
				variant="filled"
				sx={{
					marginBottom: 2,
					borderRadius : '0px',
					"&:last-child" : {
						marginBottom: 0
					}
				}}
				action={
					<IconButton
						aria-label="close"
						color="inherit"
						size="small"
						onClick={() => {
							dispatch(AppSlice.actions.dismissAlert(props.index))
						}}
					>
						<Close fontSize="inherit" />
					</IconButton>
				}
			>
				{props.children}
			</MUIAlert>
		
	)
})

const RawAlertsContainer = styled(Box)(({ theme }) => ({
	'&.withBorder': {
		marginBottom: 2,
		borderBottom: "1px solid #f5f5f5"
	},
  }));

const AlertsContainer = (props) => {

	const alerts = useSelector(getAlerts)

	const {
		showBorder = false,
		alertProps = {}
	} = props

	if(isEmpty(alerts)){
		return null
	}


	const classes = classnames("alters-container", {
		"withBorder" : showBorder
	})

	return (
		<RawAlertsContainer className={classes}>
			<TransitionGroup>
				
				{Object.keys(alerts).map( (key) => (
					<Fade key={key}>
						<Alert 
							severity={alerts[key].severity} 
							index={key} 
							isFlash={alerts[key].isFlash ?? false}
							hasFlashed={alerts[key].hasFlashed ?? false}
							{...alertProps}
						>
							{alerts[key].message}
						</Alert>
					</Fade>
				))}
				
			</TransitionGroup>
			
		</RawAlertsContainer>
	)
}

export {AlertsContainer}