import {useState} from "react"
import { NumberParam, useQueryParams } from 'use-query-params';

import { DataGridPro, DataGridProProps, GridPanel, GRID_CHECKBOX_SELECTION_COL_DEF, GridSelectionModel} from "@mui/x-data-grid-pro"
import {Box} from "@mui/material"

import useHistoryState from '../../Hooks/useHistoryState';
import {PortalToolbar} from "./Toolbar"

interface HZNDataGridProps extends DataGridProProps{
	EditMultipleItemDrawer ?: JSX.Element
	EditItemDrawer ?: JSX.Element
}

const DEFAULT_PER_PAGE = 25
const DEFAULT_PAGE_NUMBRER = 0

const HZNDataGrid = (props : HZNDataGridProps) => {

	const [query, setQuery] = useQueryParams({
		page: NumberParam,
		pp: NumberParam,
	});

	// use preferences	
	let [density] = useHistoryState("density", "comfortable")

	// move the internal state
	//let [pageSize, setPageSize] = useHistoryState("per_page", DEFAULT_PER_PAGE)
    //let [page, setPage] = useHistoryState("page", 0)

	// data selection
	let [selectedRows, setSelectedRows] = useState<GridSelectionModel>([]);
	
	const {
		initialState = {}, 
		...restProps
	} = props

    return (
        <Box sx={{height:"100%"}}>
            <DataGridPro    

                // pagination parameters
                pagination
                page={query.page ?? DEFAULT_PAGE_NUMBRER}
                onPageChange={(newPage) => {
					setQuery({
						...query,
						page : newPage
					}, "push")
				}}
                pageSize={query.pp ?? DEFAULT_PER_PAGE}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                onPageSizeChange = { (pageSize: number) => {
					setQuery({
						...query,
						pp : pageSize
					}, "push")
                }}

				checkboxSelection
				onSelectionModelChange={(newSelectionModel) => {
					setSelectedRows(newSelectionModel);
				}}
				selectionModel={selectedRows}

                // stop the grid refreshing too quickly
                throttleRowsMs = {500}
                density = {density}
                
				components={{ 
					Toolbar: PortalToolbar,
					Panel : GridPanel
				}}

				componentsProps={{ 
					toolbar: {
						hasSelectedRows : (selectedRows.length > 0),
						rows : selectedRows,
						editMultipleForm : props.EditMultipleItemDrawer ?? null,
						editForm : props.EditItemDrawer ?? null
					},
				}}

				initialState={{ 
					pinnedColumns: { 
						left: [
							GRID_CHECKBOX_SELECTION_COL_DEF.field,
							'title'
						], 
						right: ['actions'] 
					},
					sorting : {
						sortModel: [{ field: 'title', sort: 'asc' }],
					},
					...initialState
				}}


                {...restProps}
            />
        </Box>
    )
  }

export default HZNDataGrid
export {HZNDataGrid}