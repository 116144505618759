import {Route as RouteType} from "./../Core/types"

import {Projects as ListView} from '../Views/Projects/Projects'
import {Builds} from '../Views/Projects/Builds'
import {Retainers as RetainerView} from '../Views/Projects/Retainers'
import {Consulting as ConsultingView} from '../Views/Projects/Consulting'

import {Project as SingleView} from '../Views/Projects/Project'


const Routes: Array<RouteType> = [
    {
        "path" : [
			"/projects/build/status/:status",
			"/projects/build",
		],
        "view" : Builds,
		"extraProps" : {
            "type" : 0
        }
    },

    {
        "path" : "/projects/consulting",
        "view" : ConsultingView,
		"extraProps" : {
            "type" : 2
        }
    },
    {
        "path" : "/projects/retainers",
        "view" : RetainerView,
		"extraProps" : {
            "type" : 1
        }
    },
    {
        "path" : [
			"/projects/all",
			"/projects",
		],
        "view" : ListView
    },
    {
        "path" : "/project/:guid",
        "view" : SingleView
    }
]

const Projects = {
    routes : Routes
} 

export {Projects}