export let categories = {
    "finance" : {
        "key" : "finance",
        "label" : "Finance",
    },
    "time-tracking" : {
        "key" : "time-tracking",
        "label" : "Time Tracking",
    }
}
