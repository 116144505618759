//@ts-nocheck

//external 
import { Switch, Route as RouterRoute, Link as RouterLink, useRouteMatch} from "react-router-dom";
import { useSelector } from "react-redux";

// external-ui
import { Link as MUILink } from "@mui/material";

// packages
import { LoginStates } from 'portal-constants'

// internal
import { Route as RouteType } from "../types"
import { app } from "../bootstrap"
import { getActionRoutes } from "./Routes"
import { AppSlice } from "./../Redux"
import { useDispatch } from "react-redux";


// UI
import { Login } from "../../Views/Auth";


const Place = (props: any) => {
    return (
        <h1>{props.children}</h1>
    )  
}

const Route = (props : any) => {

    //@ts-ignore
    const loginState = useSelector( (state) => state.auth.auth.status )

    if((props.private) === true && (loginState !== LoginStates.LOGGED_IN)){
        return (<Login />)
    }

    if(props.view){
        return ( <RouterRoute path={props.path} {...props} render={ (routeProps) => (<props.view {...routeProps} {...props.extraProps} />) } /> )
    }
    
    return (
        <RouterRoute path={props.path} render={() => ( 
            <Place>{props.title}</Place>
        )} />
    )
}

const Routing = () => {

	const dispatch = useDispatch()
	let actions = getActionRoutes()


	Object.keys(actions).forEach( (action) => {
		// adding the * at the start matches this at anypoint in the url
		let path = "*" + action
		
		// look for a match
		/* eslint-disable react-hooks/rules-of-hooks */
		let match = useRouteMatch(path); 

		// if we matched then pass to the callback
		if(match){
			actions[action](match)
		}
		
	})

	dispatch(AppSlice.actions.removeFlashed())

    return (
        <Switch>

            { app.getRoutesFromModels().map( (item : RouteType, index : number) => {
                
                return ( <Route 
                    key={index} 
                    path={item.path} 
                    view={item.view ?? null} 
                    private={item.private ?? true } 
                    extraProps={item.extraProps ?? null } 
                /> )
            } ) }

            { app.getRoutesFromFeatures().map( (item : RouteType, index : number) => {
                return ( <Route 
                    key={index} 
                    path={item.path} 
                    view={item.view ?? null} 
                    private={item.private ?? true } 
                    extraProps={item.extraProps ?? null } 
					exact = {false}
					strict = {false}
                /> )
            } ) }

            { app.getAppRoutes().map( (item : RouteType, index : number) => {
                return ( <Route 
                    key={index} 
                    path={item.path} 
                    view={item.view ?? null} 
                    private={item.private ?? true } 
                    extraProps={item.extraProps ?? null } 
                /> )
            } ) }

        </Switch>
    )
}

const Link = (props : any ) => (
    <MUILink component={RouterLink} {...props} >
        {props.children}
    </MUILink>
)


export default Routing
export {Routing, Link}