//@ts-nocheck
import React, {useState} from 'react'

const withDrawerControl = (WrappedComponent: any) => {

  return (props : any) => {

    let [drawerOpen, setDrawerOpen] = useState(false)

    const toggleDrawer = (event = null) => {

    if(event){
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
    }

    setDrawerOpen(!drawerOpen)
    
  };


    return (<WrappedComponent {...props} drawerOpen = {drawerOpen} toggleDrawer = {toggleDrawer}/> )
  }
}

export {withDrawerControl}
export default withDrawerControl