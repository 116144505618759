import {DealMeta} from "./"
import {Box, ListItemText, ListItem, List, Button} from "@mui/material"
import {Picker} from "./../"
import { isEqual} from "lodash"
import {OpportunityStatuses, OpportunityStages, OpportunityTypes} from "portal-constants"
import { useSelector } from 'react-redux'

import {KeyboardArrowDown} from "@mui/icons-material"
import {
    RawState, 
    BudgetAccordion, 
    DatesAccordion, 
    OwnerAccordion, 
    ContactsAccordion, 
    CompanyAccordion
} from "./Accordions"

import {User} from "portal-datatypes"

const ActiveStatusPicker = (props) => {

	

    return (
        <Picker 
            label = "Opportunity Status"
            options = {OpportunityStatuses}
            value = { props.value.map( (item) => {
				return item.value
			})}
            onChange = { (value) => {
                props.model.updateStatus(value)
                props.model.save()
            } }
			
        />
    )
}

const DealOwnerPicker = (props) => {

	const selector = User.Query({})
	let users = useSelector( selector, ( left, right) => {
    	return isEqual(left.toArray(), right.toArray())
  	} ).toArray()


	users = users.map( (user) => {
		return {
			value : user.id,
			title : user.title
		}
	})

	const DealOwnerTrigger = (props) => {
		console.log(props)
		return (
			<Button
				variant="text"
				disableElevation
				endIcon={<KeyboardArrowDown />}
				onClick={props.handleOpen}
			>
				{(props.label) ? (<>{props.label}</>) : (<>Unassigned</>)}
			</Button>
		)
	}

    return (
        <Picker 
            label = "Deal Owner"
            options = {users}
            value = { props.value }
            onChange = { (value) => {
				console.log(value)
                props.model.set("user", value)
                props.model.save()
            } }
			TriggerComponent = { DealOwnerTrigger }
        />
    )
}

const ActiveStagePicker = (props) => {

    return (
        <Picker 
            label = "Opportunity Stage"
            options = {OpportunityStages}
			value = { props.value.map( (item) => {
				return item.value
			})}
            onChange = { (value) => {
				props.model.set("stage", value)
                props.model.save()
            } }
        />
    )
}

const ActiveTypePicker = (props) => {
    
    return (
        <Picker 
            label = "Opportunity Type"
            options = {OpportunityTypes}
            value = { props.value.map( (item) => {
				return item.value
			})}
            multiple
            onChange = { (value) => {
                console.log(value)
				props.model.set("type", value)
                props.model.save()
            } }
        />
    )
}

const Summary = (props) => {

	return (
    <Box sx={{padding : "10px"}}>
        <List>
            
			<ListItem secondaryAction={
                <DealOwnerPicker model={props.model} value={[props.model.users.id]} />
            }>
                <ListItemText>Deal Owner:</ListItemText>
            </ListItem>

            <ListItem secondaryAction={
                <ActiveStatusPicker model={props.model} value={props.model.status} />
            }>
                <ListItemText>Status:</ListItemText>
            </ListItem>

            
            <ListItem secondaryAction={
                <ActiveStagePicker model={props.model} value={props.model.stage} />
            }>
                <ListItemText>Stage:</ListItemText>
            </ListItem>

            <ListItem secondaryAction={
                <ActiveTypePicker model={props.model} value={props.model.type} />
            }>
                <ListItemText>Type:</ListItemText>
            </ListItem>
        </List>
    </Box>
	)
}

const DealLeftSidebar = (props) => {

    return (
        <DealMeta>
           
            
            
            <Summary model = {props.model}/>
            <BudgetAccordion model = {props.model} />
            <OwnerAccordion model = {props.model} />
            <CompanyAccordion model = {props.model} />
            <ContactsAccordion model = {props.model} />
            <DatesAccordion model = {props.model} />
            <RawState model = {props.model} />
    
        </DealMeta>
    )

   
}

export {DealLeftSidebar}
export default DealLeftSidebar