"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Company", {
  enumerable: true,
  get: function () {
    return _Companies.Company;
  }
});
Object.defineProperty(exports, "Contact", {
  enumerable: true,
  get: function () {
    return _Contact.Contact;
  }
});
Object.defineProperty(exports, "Invoice", {
  enumerable: true,
  get: function () {
    return _Invoices.Invoice;
  }
});
Object.defineProperty(exports, "Note", {
  enumerable: true,
  get: function () {
    return _Notes.Note;
  }
});
Object.defineProperty(exports, "Opportunity", {
  enumerable: true,
  get: function () {
    return _Opportunity.Opportunity;
  }
});
Object.defineProperty(exports, "Project", {
  enumerable: true,
  get: function () {
    return _Projects.Project;
  }
});
Object.defineProperty(exports, "Retainer", {
  enumerable: true,
  get: function () {
    return _Retainers.Retainer;
  }
});
Object.defineProperty(exports, "RetainerPeriod", {
  enumerable: true,
  get: function () {
    return _RetainerPeriod.RetainerPeriod;
  }
});
Object.defineProperty(exports, "Time", {
  enumerable: true,
  get: function () {
    return _Time.Time;
  }
});
Object.defineProperty(exports, "User", {
  enumerable: true,
  get: function () {
    return _Users.User;
  }
});
exports.default = void 0;

var _Companies = require("./Companies");

var _Invoices = require("./Invoices");

var _Projects = require("./Projects");

var _Retainers = require("./Retainers");

var _Opportunity = require("./Opportunity");

var _RetainerPeriod = require("./Retainers/RetainerPeriod");

var _Time = require("./Time");

var _Contact = require("./Contact");

var _Notes = require("./Notes");

var _Users = require("./Users");

let dataTypes = [_Companies.Company, _Invoices.Invoice, _Projects.Project, _Retainers.Retainer, _Opportunity.Opportunity, _RetainerPeriod.RetainerPeriod, _Time.Time, _Contact.Contact, _Notes.Note, _Users.User];
var _default = dataTypes;
exports.default = _default;