import {Accordion} from "./../../Accordion"

const RawState = (props) => (
    <Accordion
        id="accordion-company"
        summary = "RAW State"
    >
        <pre>
            {JSON.stringify(props.model.data, null, '  ')}
        </pre>
    
    </Accordion>
)

export {RawState}
export default RawState