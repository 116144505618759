import {Stack, Paper, Typography} from "@mui/material"
import { isEmpty } from "lodash"


const CardContainer = (props) => {

    

    const hasChildren = !isEmpty(props.children)
    return (
        <Stack 
            
            spacing={2} 
            direction="column"
        >
            { hasChildren && (<>
                {props.children}
            </>)}

            { !hasChildren && (<>
                <Paper sx={{padding: 2, border: "1px solid rgba(0,0,0,0.2)"}}>
                    <Typography> No Opportunities</Typography>
                </Paper>
            </>)}

            {props.AddControl && (
                <>{ props.AddControl }</>
            )}
        </Stack>
    )
}

export {CardContainer}
export default CardContainer