//@ts-nocheck
import {Invoice as Model} from "portal-datatypes" 
import {Single} from "../../Components/Single"
import withModel from "../../Hooks/withModel"
import withLoaded from "../../Hooks/withLoaded"
import { EditInvoiceDrawer } from "../../Components/Forms/EditInvoice"

import {InfoTable} from "./../../Components/InfoTable"

const RawInvoice = (props : any) => {
    
    const {model} = props

    return (
        <Single 
            {...props} 
            title={`Invoice :  ${model.reference}`}
            ItemControl = {EditInvoiceDrawer}
        >
            <InfoTable 
                rows = {[
                    {
                        label : "Client",
                        value : model.companies.title
                    },
                    {
                        label : "Project",
                        value : model.projects?.title
                    },
                    {
                        label : "Status",
                        value : model.status
                    },
                    {
                        label : "Paid",
                        value : model.value
                    },
                    {
                        label : "Outstanding",
                        value : model.due
                    },
                    {
                        label : "Due On",
                        value : model.due_on
                    },
                ]}
            />
            
        </Single>
    )
}

let Invoice = withLoaded(RawInvoice)
Invoice = withModel(Invoice, Model)

export default Invoice
export { Invoice }