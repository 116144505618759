import { Avatar, Tooltip } from "@mui/material"
import { isEmpty } from "lodash"

const EntityIcon = ( props ) => {
    
    const {model, size = 40} = props

    if(isEmpty(model)){
        return null
    }
    let size_args = {
        width: 40,
        height: 40,
    }

    if(size === "small"){
        size_args = {
            width: 30,
            height: 30,
        }
    } 

    if(model.hasIcon()){
        return (<Tooltip title={model.title}>
            <Avatar src={model.getIconSrc()} alt={model.title} sx={{marginRight: 1, ...size_args}}/>
        </Tooltip>)
    }
    
    return (<Tooltip title={model.title}>
        <Avatar alt={model.title} sx={{marginRight: 1, ...size_args}}>{model.initials()}</Avatar>
    </Tooltip>)

    
}

export {EntityIcon}
export default EntityIcon