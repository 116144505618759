import { CardContent, Typography, Card as MUICard, Stack} from "@mui/material"
import { Link } from "./../Link"
import CurrencyOutput from "./../../Core/utils/CurrencyOutput"

import { useDrag } from 'react-dnd'
import { CompanyIcon } from "../Companies"
import { ChipList } from "../ChipList"

const Card = (props) => {

    const {model} = props


    const [{ isDragging }, drag] = useDrag(() => ({
		// "type" is required. It is used by the "accept" specification of drop targets.
        type: 'CARD',
		// The collect function utilizes a "monitor" instance (see the Overview for what this is)
		// to pull important pieces of state from the DnD system.
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        item : model

  }))    

    return (
        <MUICard ref={drag} sx={{
            opacity: (isDragging ? 0.5 : 1),
            cursor: 'move'
        }}>  
            <CardContent>
                <Stack 
                   spacing={1}
                >
                    <Typography sx={{ fontSize: 14 }} fontWeight={"bold"} color="text.secondary">
                        {CurrencyOutput(parseInt(model.value))}
                    </Typography>
                        
                    
                    <Link to={model.link()} underline="hover">
                            <Typography component={"h3"} variant="h6">
                                {model.title}
                            </Typography>
                    </Link>
                    
                    <Stack 
                        direction="row"
                        alignItems={"center"}
                    >
                       
                        <CompanyIcon size="small" company = {model.companies} /> 
                        <Typography variant="overline" color="text.secondary">
                            {model.companies.title}
                        </Typography>
                    </Stack>
                    <ChipList types={model.type} />
                </Stack>
            </CardContent>

        </MUICard>
    )
}

export default Card
export {Card}