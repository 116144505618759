"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.UserRoles = void 0;
const UserRoles = [{
  value: 0,
  title: "Owner"
}, {
  value: 1,
  title: "Admin"
}, {
  value: 2,
  title: "Manager"
}, {
  value: 3,
  title: "User"
}];
exports.UserRoles = UserRoles;
var _default = UserRoles;
exports.default = _default;