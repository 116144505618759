"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.LoginStates = void 0;
const LoginStates = {
  UNKNOWN: 0,
  LOGGED_OUT: 1,
  LOGGING_IN: 2,
  LOGGED_IN: 3
};
exports.LoginStates = LoginStates;
var _default = LoginStates;
exports.default = _default;