"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.CompanyLifeCycleStages = void 0;
const CompanyLifeCycleStages = [{
  value: 0,
  title: "Client",
  color: "green"
}, {
  value: 1,
  title: "Alumni",
  color: "red"
}, {
  value: 2,
  title: "Prospect",
  color: "indigo"
}, {
  value: 3,
  title: "Lead",
  color: "lightBlue"
}, {
  value: 4,
  title: "Target",
  color: "blue"
}, {
  value: 5,
  title: "Supplier",
  color: "orange"
}, {
  value: 6,
  title: "Partner",
  color: "lime"
}];
exports.CompanyLifeCycleStages = CompanyLifeCycleStages;
var _default = CompanyLifeCycleStages;
exports.default = _default;