import {AppSlice, store} from "./"
import {populateComplete} from "./Events"
import {ProcessStates} from 'portal-constants'

async function populateFromRemote(Slices){

    // save to redux that we are now populating
    store.dispatch(AppSlice.actions.setPopulating(ProcessStates.RUNNING))

    // collect the references to the promises so we can fire an update when everything is resolved
    let populate_promises = []

    // trigger populate fro each slice
    Object.keys(Slices).forEach( (name) => {
        let slice = Slices[name]
        if(slice.auto_populate){
            populate_promises.push(store.dispatch(slice.populate))
        }
    })

    // TODO : add an event that listens to each slice and fires on completion
    Promise.all(populate_promises).then( (data) => {
        store.dispatch(AppSlice.actions.setPopulating(ProcessStates.FINISHED))
        window.dispatchEvent(populateComplete);
    })
}

export default populateFromRemote
export {populateFromRemote}