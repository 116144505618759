import Fuse from 'fuse.js'
import {Company, Project, Opportunity} from "portal-datatypes"

export default function SearchSelector (term) {

    return ( state ) => {
        

        let allowed_types = [
            "companies",
            "projects",
            "opportunities"
        ]

        const ModelMap = {
            "companies" : Company,
            "projects" : Project,
            "opportunities" : Opportunity
        }

        
        
        let items = []
        Object.keys(state).filter( (type) => {
            return (allowed_types.includes(type))
        } ).forEach( (type) => {

            Object.keys(state[type].items).forEach( (key) => {
                items.push({
                    ...state[type].items[key],
                    ...{
                        "data_type" : type
                    }
                })
            })

        })

        // 2. Set up the Fuse instance
        const fuse = new Fuse(items, {
            keys: ['title'],
            includeScore : true,
            includeMatches : true,
            threshold : 0.2
        })


        let results = fuse.search(term)
        
        
        return results.map( (r) => {
            let Model = ModelMap[r.item.data_type]
            r.item = Model.init(r.item)
            
            let indices = r.matches[0].indices

        
            if(indices.length > 1){
                
                r.matches[0].indices = [indices.reduce( (prev, current, index) => {
                

                    let prev_gap = (prev[1] - prev[0])
                    let current_gap = (current[1] - current[0])

                    if(current_gap > prev_gap ){
                        return current
                    } else {
                        return prev
                    }

                }, indices[0])]  
            }
    
            return r
        } )


    }
}

export {SearchSelector}