import {FrontEndServices} from "portal-services/dist/FrontEnd"
import {addActionRoutes} from "./../Navigation/Routes"

import {AppSlice} from "./../Redux/app-slice"

import {store} from "./../Redux"

function createIntegrations(){

	addActionRoutes(createIntegrationRoutes())
	return {
		services : getIntegrations()
	}
}


function getIntegrations(){
	return Object.keys(FrontEndServices).map( (key) => {
        let integration = FrontEndServices[key]
        return integration
    })
}

function onSuccess(match){
	
	store.dispatch(AppSlice.actions.addFlash({
		"key" : "ssss",
		"severity" : "success",
		"message" : "Loggedin To FreeAgent Successfully"
	}))
}

function createIntegrationRoutes(){
	
	return {
		"/integration/:service/auth/connect"	: (match) => console.log("connect", match),
		"/integration/:service/auth/success"	: onSuccess,
		"/integration/:service/auth/error"		: (match) => console.log("error", match)
	}
}

export {createIntegrations, getIntegrations}

