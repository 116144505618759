import {clearSession, init, LoginWithEmail, GoogleLoginRedirect} from "./FireAuth"

import { clearSlices } from "../Redux"

import {User} from "portal-datatypes"
import {app} from "./../bootstrap"

import {AppSlice} from "./../Redux/app-slice"
import {store} from "./../Redux"

export class Auth {

    init = () => {
        init()
    }

    logout = () => { 

        clearSession()
		clearSlices()

		store.dispatch(AppSlice.actions.addFlash({
			"key" : "loggedout",
			"severity" : "success",
			"message" : "Loggedout Successfully"
		}))
    }

    login = () => {}
    isLoggedIn = () => {}
    isLoggingIn = () => {}
    resetPassword = () => {}
    changePassword = () => {}
    getUser = () => {}

    static GoogleLoginRedirect = () => {
        GoogleLoginRedirect()
    }

    static LoginWithEmail = async (email, password) => {
        return await LoginWithEmail(email, password)
    }

	static createUserFromService = async (service_user) => {
		let new_user = User.init({
			...service_user,
			...{
				id : service_user.uid,
			}
		})
		return await new_user.create()
	}
}