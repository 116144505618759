import { DatePicker } from '@mui/x-date-pickers';

import {TextField} from "@mui/material"
import {Accordion} from "./../../Accordion"

const DatesAccordion = (props) => {
    return (
        <Accordion
            id="accordion-dates"
            summary = "Dates"
        >
            <DatePicker 
                label = "Deal Inbound Date"
                value = {props.model.date_created ?? null}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" helperText = "When where we approached about this deal?" />}
                allowSameDateSelection
                clearable
                //inputFormat = {"Do MMMM y"}
                onChange={ (value) => {
                    props.model.setDateValue("date_created", value)
                    props.model.save()
                }}
            />

            <DatePicker 
                label = "Original Expected Close Date"
                value = {props.model.date_original_expected_close ?? null}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal"  helperText = "When did we originally think this would close?" />}
                allowSameDateSelection
                clearable
                //inputFormat = {"Do MMMM y"}
                onChange={ (value) => {
                    props.model.setDateValue("date_original_expected_close", value)
                    props.model.save()
                }}
            />

			<DatePicker 
                label = "Current Expected Close Date"
                value = {props.model.date_current_expected_close ?? null}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal"  helperText = "When do we think this will close currently?" />}
                allowSameDateSelection
                clearable
                //inputFormat = {"Do MMMM y"}
                onChange={ (value) => {
                    props.model.setDateValue("date_current_expected_close", value)
                    props.model.save()
                }}
            />

            <DatePicker 
                value = {props.model.date_closed ?? null}
                label = "Actual Close Date"
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" placeholder="Date Closed"  helperText = "When did this actually close?"/>}
                allowSameDateSelection
                clearable
               // inputFormat = {"Do MMMM y"}
                onChange={ (value) => {
                    props.model.setDateValue("date_closed", value)
                    props.model.save()
                }}
            />

        </Accordion>
    )
}

export {DatesAccordion}
export default DatesAccordion