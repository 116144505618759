import { useState } from "react"

import { Edit, Cancel } from "@mui/icons-material"
import { Box, Button } from "@mui/material"

const EditAction = (props : {
  items : any,
  form: any
}) => {
    
    let [showEdit, setShowEdit] = useState<boolean>(false)
    return (
		<>
			<Box>
				{!showEdit ? (
					<Button
						variant="contained"
						disableElevation
						size="small"
						startIcon = {<Edit />}
						onClick = { () => {
							setShowEdit(true)
						}}
					>
						Edit
					</Button>
				) : (
					<Button
						variant="contained"
						color="secondary"
						disableElevation
						size="small"
						startIcon = {<Cancel />}
						onClick = { () => {
							setShowEdit(false)
						}}
					>
						Cancel
					</Button>
				)}
      		</Box>

			{showEdit && (
				<props.form 
					isOpen = {true}
					onClose = { () => {setShowEdit(false)}}
					items = {props.items}
				/>
			)}
      	</>
    )
}

export default EditAction
export {EditAction}
