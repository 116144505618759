import MenuItem from "@mui/material/MenuItem"
import {PickerDropdownTitle as PickerDropdownTitleProps} from "./types"

export const InDropDownTitle = ( props : PickerDropdownTitleProps ) => {
	return (
		<MenuItem 
			key={-1}
			disabled={true}
			divider
		>
			{props.label}
		</MenuItem>
	)
}

export default InDropDownTitle