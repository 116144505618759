
import { useContext, useRef} from 'react';

import { SearchContext } from "."
import {TextField, InputAdornment, Button} from '@mui/material';

import {Search as SearchIcon} from '@mui/icons-material';

const SearchInput = (props) => {

    const search = useContext(SearchContext)
    let inputRef = useRef(null);

    if(props.hasFocus && inputRef.current){
        inputRef.current.focus()
    }

    return (
        <TextField
            fullWidth
            placeholder='Search'
            value={search.searchTerm}
            focused = {props.hasFocus}
            onChange={ (event) => {
                search.setSearchTerm(event.target.value) 
            }}
            inputRef={inputRef}
            InputProps={{
                autoFocus : true,
                startAdornment: (
                    <InputAdornment position="start">
                    <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <Button
                        variant='outlined'
                        size="small"
                        >ESC</Button>
                    </InputAdornment>
                ),
                onKeyUp: (event) => {
                    if(event.key === "ArrowDown"){
                        props.moveFocusToResults()
                    }
                }
            }}
        />
    )
}

export {SearchInput}
export default SearchInput