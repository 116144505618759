import {MenuItem, Checkbox, ListItemIcon, ListItemText} from "@mui/material"
import {CheckIcon} from "./../Icons"

export const PickerItem = (props : {
	index : number,
	value : number
	onClick : Function
	isSelected : Function
	title : string
	multiple ?: boolean
}) => {

	const {
		value,
		onClick,
		title,
		multiple = false
	} = props	

	const isSelected:boolean = props.isSelected(value)

	return (
		<MenuItem value={value} onClick = { () => { onClick(value) }} divider selected={isSelected}>
				{ (multiple) ? (
					<Checkbox checked={isSelected} size="small"  />
				) : (<>
					{ (isSelected) && (
						<ListItemIcon>
							<CheckIcon fontSize="small"/>
						</ListItemIcon>
					) }
				</>)}
				<ListItemText primary={title} />
		</MenuItem>
	)
}

export default PickerItem