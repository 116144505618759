"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withGetRelated = exports.default = withGetRelated;

var _lodash = require("lodash");

function getRelated(attr) {
  return this.cacheCall(attr, () => {
    let items = [];
    let relationship = this.schema[attr].relationship;
    let keys;

    if (relationship.type === "hasMany") {
      let ids = this.data[attr];

      if ((0, _lodash.isUndefined)(ids)) {
        return items;
      }

      if ((0, _lodash.isUndefined)(relationship.model)) {
        return items;
      }

      if ((0, _lodash.isArray)(ids)) {
        keys = ids;
      } else {
        keys = Object.keys(ids);
      }

      keys.forEach(key => {
        let item = relationship.model.get(key)();

        if (!(0, _lodash.isEmpty)(item)) {
          items.push(item);
        }
      });
    } else if (relationship.type === "foreignKey") {
      let Model = relationship.model;
      items = Model.getByAttrMap(relationship.foreignKey, this.data.id)().toArray();
    } else if (relationship.type === "belongsTo") {
      let Model = relationship.model;
      let id = this.data[attr];

      if ((0, _lodash.isObject)(id)) {
        id = Object.keys(id)[0];
      }

      items = Model.get(id)();
    }

    return items;
  });
}

function withGetRelated(Model) {
  if ((0, _lodash.isUndefined)(Model.schema)) {
    Model.loadSchema();
  }

  let proto = Model.prototype;
  proto.getRelated = getRelated;
}