import { Link } from "@mui/material";
import { GridColDef, GridValueFormatterParams, GridValueGetterParams } from "@mui/x-data-grid-pro";

import { Link as RouterLink } from "react-router-dom";

import { CurrencyOutput } from "./../../../Core/utils/CurrencyOutput"
import {RelatedLink as RelatedLinkColumnType} from "../ColumnTypes/RelatedLink"
import {DateColumnType} from "./../ColumnTypes/Date"

const ProjectInvoiceColumns: GridColDef[] = [
    
    { 
      field: 'title', 
      headerName: 'Ref', 
      width: 100,
      renderCell: (params: GridValueGetterParams) => {
        return (<Link component={RouterLink} to={"/invoices/" + params.row.id}>{params.row.reference}</Link>)
      }    
    },
    { 
		field: 'projects', 
		headerName: 'Project', 
		width: 170,
		...RelatedLinkColumnType
	  },
    { 
        field: 'value', 
        headerName: 'Value', 
        width: 140,
        valueFormatter: (params: GridValueFormatterParams) => {
            return CurrencyOutput(params.value as number)
        }
    },
    {
		field: 'status', 
		headerName: 'Status', 
		width: 170,
	},
    { 
		field: 'paid', 
		headerName: 'Ammount Paid', 
		width: 140,
		valueFormatter: (params: GridValueFormatterParams) => {
			return CurrencyOutput(params.value as number)
		}
  	},
  	{ 
    	field: 'due', 
    	headerName: 'Due', 
    	width: 140,
    	valueFormatter: (params: GridValueFormatterParams) => {
        	return CurrencyOutput(params.value as number)
    	}
	},
	{ 
		field: 'due_on', 
		headerName: 'Due Date', 
		width: 140,
		...DateColumnType
	  },
	  { 
		field: 'issued_on', 
		headerName: 'Issued Date', 
		width: 140,
		...DateColumnType   
	  },
	  { 
		field: 'paid_on', 
		headerName: 'Paid On', 
		width: 140,
		...DateColumnType   
	  },
];

  export default ProjectInvoiceColumns
  export {ProjectInvoiceColumns}