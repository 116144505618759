"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Schema = void 0;

var _portalModel = require("portal-model");

var _portalConstants = require("portal-constants");

const Schema = {
  'uid': (0, _portalModel.id)(),
  'first_name': (0, _portalModel.attr)(),
  'last_name': (0, _portalModel.attr)(),
  'displayName': (0, _portalModel.attr)(),
  'email': (0, _portalModel.attr)(),
  'photoUrl': (0, _portalModel.attr)(),
  'department': (0, _portalModel.picked)(_portalConstants.Departments),
  'role': (0, _portalModel.picked)(_portalConstants.UserRoles),
  'invited': (0, _portalModel.attr)(),
  'deactivated': (0, _portalModel.attr)(),
  'active': (0, _portalModel.attr)()
};
exports.Schema = Schema;
var _default = Schema;
exports.default = _default;