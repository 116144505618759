import {Typography} from "@mui/material"
import Layout from "./Layout"


const ResetPassword = () => {

    return (
        <Layout>
            <Typography>
                Reset Password
            </Typography>
        </Layout>        
    )
}

export {ResetPassword}
export default ResetPassword