//@ts-nocheck
import React from "react"
import {HZNForm} from "../Form/Form"

import {Retainer} from "portal-datatypes" 
import { withFormDrawer } from "../Form/Drawer"

const EditRetainer = (props : any) => {
    return(
      <HZNForm
        Model = {Retainer}
        fields = {Retainer.getFormFields()}
        title = "Edit Retainer"
        buttonText = "Update Retainer"
        {...props}
        action = "edit"
        successMessage = "Retainer Updated"
        showAddAnotherButton = {false}
      />
    )
}

const EditRetainerDrawer = withFormDrawer(EditRetainer)

export default EditRetainer
export {EditRetainer, EditRetainerDrawer}