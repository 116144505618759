import { Cancel, Delete } from "@mui/icons-material"
import { Box, Button, IconButton } from "@mui/material"
import { GridActionsCellItem } from "@mui/x-data-grid-pro"
import { MouseEventHandler } from "react"

const DeleteAction = (props : {
    showConfirm : boolean,
    onClick : MouseEventHandler
    item : any
  }) => {

    if(props.showConfirm){
      return (
      <Box>
        <Button
          variant="outlined"
          color="warning"
          startIcon={<Delete />}
          size = "small"
          onClick={ (event) => {
            props.item.delete()
            props.onClick(event)
          }} 
        >
          Confirm?  
        </Button>

        <IconButton
          size = "small"
          edge = {false}
          onClick={props.onClick} 
        >
          <Cancel />
        </IconButton>
      </Box>
      )
    }

    return (
      <GridActionsCellItem onClick={props.onClick} icon={<Delete />} label="Delete" />
    )
  }

  export default DeleteAction
  export {DeleteAction}