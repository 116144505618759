//@ts-nocheck

// react
import { useState } from 'react';

// 3rd party
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
//import { generatePath } from "react-router";

// data§
import { ProcessStates, OpportunityStatuses, OpportunityStatusesTaxonomy, OpportunityStages } from 'portal-constants';
import {Opportunity as Model} from "portal-datatypes"

// components
import {Archive, VIEWS} from "../../Components/Archive";
import { Opportunities as Columns } from "../../Components/DataGrids/Columns/Opportunities"

import { AddItemControl } from '../../Components/Form/DrawerControl';
import { AddOpportunityDrawer } from "./../../Components/Forms/AddOpportunity"
import { Stack } from '@mui/material';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';

import {Frame} from "./../../Components/Frame"


let Opportunities = ( props : any ) => {

	const history = useHistory();

	const [visibilityModel, setVisibilityModel] = useState(props.columnVisibilityModel ?? {
		[GRID_CHECKBOX_SELECTION_COL_DEF.field] : true,
		title: true,
		type: true,
		actions: true,
		status: true,
		id : false,
	})

    let [selectedView, setSelectedView] = useState(VIEWS.GRID)


    //let [filters, setFilters] = useState({})
	const [status, setStatus] = useState([OpportunityStatusesTaxonomy[props.match?.params?.status]?.value ?? OpportunityStatusesTaxonomy.ACTIVE])

    const loaded = useSelector( (state) => {
      return (state.app.populating.state === ProcessStates.FINISHED)
    })
    
    if(!loaded){
      return(<>Loading...</>) 
    }
	

	let query = {
		status : status,
	}
   
	const updateStatus = (status) => {
		setStatus(status)
		let path = generatePath("/opportunities/:status", { 
			"type" : ProjectTypesTaxonomy[type[0]].slug,
			"status" : ProjectStatusTaxonomy[status].slug,
		})
		history.push(path)
	}
	

    return (
	<Frame>
      <Archive
        title = "Opportunities"
		Model = {Model}
		query = { query }
		statusMenu = { OpportunityStatuses }
		setStatus = {updateStatus}
		enableViewSwitcher = {true}
		selectedView = {selectedView}
		setSelectedView = {setSelectedView}
		gridProps = {{
			columns : (props.columns ?? Columns),
			columnVisibilityModel : visibilityModel,
			onColumnVisibilityModelChange : (newModel) => {
				setVisibilityModel(newModel)
			},
			disableColumnMenu : false,
		}}
		kanbanProps = {{
			columns : OpportunityStages
		}}
        controls = {(
            <Stack
                spacing={2}
                direction="row"
            >
                <AddItemControl 
                    label = "Add Opportunity"
                    form = {AddOpportunityDrawer}
                />
            </Stack>
          )}
        />
		</Frame>
    )
}

export default Opportunities
export {Opportunities}