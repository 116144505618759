"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ProjectStatusesTaxonomy = exports.ProjectStatuses = void 0;

var _Taxonomy = require("./Utils/Taxonomy");

const ProjectStatuses = [{
  value: 0,
  title: "Completed",
  color: "success",
  slug: "complete"
}, {
  value: 1,
  title: "In Progress",
  color: "info",
  slug: "in_progress"
}, {
  value: 2,
  title: "Cancelled",
  color: "error",
  slug: "cancelled"
}, {
  value: 3,
  title: "Paused",
  color: "info",
  slug: "paused"
}, {
  value: 4,
  title: "Waiting For Payment",
  color: "warning",
  slug: "waiting_for_payment"
}];
exports.ProjectStatuses = ProjectStatuses;
const ProjectStatusesTaxonomy = new _Taxonomy.Taxonomy(ProjectStatuses);
exports.ProjectStatusesTaxonomy = ProjectStatusesTaxonomy;
var _default = ProjectStatuses;
exports.default = _default;