"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Departments = void 0;
const Departments = [{
  value: 0,
  title: "Sales"
}, {
  value: 1,
  title: "Marketing"
}, {
  value: 2,
  title: "Ops"
}, {
  value: 3,
  title: "Production"
}, {
  value: 4,
  title: "Finance"
}];
exports.Departments = Departments;
var _default = Departments;
exports.default = _default;