"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Schema = void 0;

var _lodash = require("lodash");

var _portalModel = require("portal-model");

var _Model = require("./../Companies/Model");

var _Model2 = require("./../Projects/Model");

var _Model3 = require("./RetainerPeriod/Model");

const Schema = {
  'id': (0, _portalModel.id)(),
  project: (0, _portalModel.belongsTo)(_Model2.Project),
  company: (0, _portalModel.hasMany)(_Model.Company),
  status: (0, _portalModel.attr)(),
  type: (0, _portalModel.attr)(),
  'period': (0, _portalModel.attr)(),
  'hours': (0, _portalModel.attr)(),
  'overage_rate': (0, _portalModel.attr)(),
  'base_rate': (0, _portalModel.attr)(),
  'base_cost': (0, _portalModel.attr)(),
  'allowed_rollover': (0, _portalModel.attr)(),
  'retainer_periods': (0, _portalModel.foreignKey)(_Model3.RetainerPeriod, "retainer")
};
exports.Schema = Schema;
var _default = Schema;
exports.default = _default;