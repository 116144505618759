import {Button, Menu as MUIMenu} from "@mui/material"
import {KeyboardArrowDown} from '@mui/icons-material';

const Menu = (props) => {

	const {
		label,
		handleOpen,
		handleClose,
		setAnchorElm,
		anchorElm,
		setMenuOpen,
		anchorOrigin = null,
		transformOrigin = null,
		TriggerComponent = null,
		component = null,
		...restProps // eslint-disable-line no-unused-vars
	} = props

	if(component){
		console.error("Deprecated Prop 'component' used in Menu. Change to TriggerComponent")
	}

    return (
        <>
        { !props.component && !props.TriggerComponent && (
            <Button
                variant="contained"
                disableElevation
                endIcon={<KeyboardArrowDown />}
                onClick={handleOpen}
            >
                {label}
            </Button>
        )}
        { props.component && (
            <props.component
				label = {label} 
                onClick={handleOpen}
            />
        )}

		{ TriggerComponent && (
            <TriggerComponent
				label = {label} 
                handleOpen={handleOpen}
            />
        )}

        <MUIMenu
            open = {props.open}
            anchorEl={props.anchorElm}
            onClose={handleClose}
            anchorOrigin={ anchorOrigin ?? {
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={ transformOrigin ?? {
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            { props.children}
        </MUIMenu>
        </>
    )
}

export {Menu}
export default Menu