import { Drawer, Toolbar, Box } from "@mui/material"

const Sidebar = (props) => {

    const drawerWidth = 240;

    return (
        <Drawer
            variant="permanent"
            anchor="left"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
        >
            <Toolbar></Toolbar>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "space-between"
                }}
            >
                {props.children}
            </Box>
        </Drawer>
    )
}

export default Sidebar
export {Sidebar}