"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Schema = void 0;

var _Projects = require("./../Projects");

var _Companies = require("./../Companies");

var _portalModel = require("portal-model");

const Schema = {
  'id': (0, _portalModel.id)(),
  'reference': (0, _portalModel.attr)(),
  'project': (0, _portalModel.belongsTo)(_Projects.Project, "invoices"),
  'company': (0, _portalModel.belongsTo)(_Companies.Company, "invoices"),
  'freeagent': (0, _portalModel.attr)(),
  'total_value': (0, _portalModel.attr)(),
  'status': (0, _portalModel.attr)(),
  'due_on': (0, _portalModel.attr)(),
  'issued_on': (0, _portalModel.attr)(),
  'paid_on': (0, _portalModel.attr)()
};
exports.Schema = Schema;
var _default = Schema;
exports.default = _default;