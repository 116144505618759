//@ts-nocheck
import React from "react"
import {HZNForm} from "../Form/Form"

import {Invoice} from "portal-datatypes" 
import { withFormDrawer } from "../Form/Drawer"

const EditInvoice= (props : any) => {
    return(
      <HZNForm
        Model = {Invoice}
        fields = {Invoice.getFormFields()}
        title = "Edit Invoice"
        buttonText = "Update Invoice"
        {...props}
        action = "edit"
        successMessage = "Invoice Updated"
        showAddAnotherButton = {false}
      />
    )
}

const EditInvoiceDrawer = withFormDrawer(EditInvoice)

export default EditInvoice
export {EditInvoice, EditInvoiceDrawer}