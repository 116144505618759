import { createSlice } from "@reduxjs/toolkit"
import { LoginStates } from "portal-constants"

const AuthSlice = createSlice({
    name: 'auth',
    initialState: {
      auth: {
        status : LoginStates.UNKNOWN,
      },
      user : {}
    },
    reducers: {
      setAuthState :  (state, action) => {
          state.auth.status = action.payload
      },
      setUserData :  (state, action) => {
          state.user = action.payload
      },
      setLoggedIn : (state, action) => {
        state.auth.status = LoginStates.LOGGED_IN
      },
      setLoggedOut : (state, action) => {
        state.auth.status = LoginStates.LOGGED_OUT
      },
      setLoggingIn : (state, action) => {
        state.auth.status = LoginStates.LOGGING_IN
      }
    }
})

export default AuthSlice
export  {AuthSlice}