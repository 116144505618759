"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ContactSlice = void 0;

var _portalRedux = require("portal-redux");

// selector helping
// firebase slice
const ContactSlice = new _portalRedux.FirebaseSlice({
  table: "contacts",
  name: 'contacts'
});
exports.ContactSlice = ContactSlice;
var _default = ContactSlice;
exports.default = _default;