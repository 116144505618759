export const routes = {
	"/integrations/auth/:service/connect" : () => {
		console.log("connect hit")
	},
	"/integrations/auth/:service/success" : () => {
		console.log("success hit")
	},
	"/integrations/auth/:service/error" : () => {
		console.log("error hit")
	},
}