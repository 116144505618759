"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.timeSlice = exports.default = void 0;

var _portalRedux = require("portal-redux");

// selector helping
// firebase slice
const timeSlice = new _portalRedux.FirebaseSlice({
  table: "time_sheets",
  name: 'time_sheets'
});
exports.timeSlice = timeSlice;
var _default = timeSlice;
exports.default = _default;