import { Typography, Grid } from "@mui/material"
import Stat from "../Stat"
import {isUndefined} from "lodash"
   
const RetainerCurrentPeriod = ( props : {
    model : any
} ) => {

    if(isUndefined(props.model)){
        return null;
    }

    return (
        <>
   <Typography
                component = "h2"
                variant="h4"
                gutterBottom
             >
                Current Period
            </Typography>
            <Grid
                container
                spacing={2}
                sx={{mb: 4}}
            >
                <Grid item xs={3}>
                    <Stat 
                        label = "available"
                        value = {props.model.available}
                    />
                </Grid>

                <Grid item xs={3}>
                    <Stat 
                        label = "used"
                        value = {props.model.used}
                    />
                </Grid>

                <Grid item xs={3}>
                    <Stat 
                        label = "overage"
                        value = {props.model.overage}
                    />
                </Grid>

                <Grid item xs={3}>
                    <Stat 
                        label = "rolling over"
                        value = {props.model.rolling_over}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export {RetainerCurrentPeriod}
export  default RetainerCurrentPeriod