import { GridRowParams } from "@mui/x-data-grid-pro"
import { useState } from "react"

import DeleteAction from "../Actions/Delete"
import EditAction from "../Actions/Edit"

interface ActionSetProps extends GridRowParams {
    editForm: any;
    disableDelete ?: boolean
}

const ActionSet = (props : ActionSetProps) => {
    
    let [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false)

    const {
        disableDelete = false
    } = props
    
    const toggleShowDeleteConfirm = () => { 
      setShowDeleteConfirm(!showDeleteConfirm) 
    }

    return (
      <>
        {showDeleteConfirm && (
          <DeleteAction item={props.row} showConfirm={showDeleteConfirm} onClick={toggleShowDeleteConfirm} />
        )}

        {!showDeleteConfirm && (
          <>
            {!disableDelete && (
                <DeleteAction item={props.row}  showConfirm={showDeleteConfirm} onClick={toggleShowDeleteConfirm} />
            ) }
            <EditAction item = {props.row} form={props.editForm} />
          </>
        )}
      </>
    )
  }

  export default ActionSet
  export { ActionSet }