import { Box, Grid, Typography } from "@mui/material"


const Section = (props : {
    title : string,
    children ?: JSX.Element | JSX.Element[];
    controls ?: JSX.Element | JSX.Element[];
}) => {
    return(
        <Box>
            <Grid
                container
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
            >    
                <Typography
                    component = "h2"
                    variant="h4"
                    gutterBottom
                >
                    {props.title}
                </Typography>
                <Box>
                    { props.controls }
                </Box>
            </Grid>
            { props.children }
        </Box>
    )
}

export default Section
export {Section}