import { GridColTypeDef, GridValueFormatterParams } from "@mui/x-data-grid-pro";
import CurrencyOutput from "../../../Core/utils/CurrencyOutput";
 const Currency: GridColTypeDef = {
    type: 'number',
    width: 130,
    valueFormatter: (params:GridValueFormatterParams ) => {
        return CurrencyOutput(Number(params.value))
    },
    cellClassName: 'font-tabular-nums',
};

export default Currency
export {Currency}