"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Schema = void 0;

var _portalModel = require("portal-model");

var _portalConstants = require("portal-constants");

var _Model = require("./../Projects/Model");

var _Model2 = require("./../Invoices/Model");

var _Model3 = require("./../Opportunity/Model");

var _Model4 = require("./../Contact/Model");

const Schema = {
  'id': (0, _portalModel.id)(),
  title: (0, _portalModel.attr)(),
  type: (0, _portalModel.picked)(_portalConstants.CompanyLifeCycleStages),
  domain: (0, _portalModel.attr)(),
  projects: (0, _portalModel.hasMany)(_Model.Project, "company"),
  invoices: (0, _portalModel.hasMany)(_Model2.Invoice, "company"),
  opportunities: (0, _portalModel.foreignKey)(_Model3.Opportunity, "company"),
  contacts: (0, _portalModel.foreignKey)(_Model4.Contact, "company"),
  remote_id: (0, _portalModel.attr)()
};
exports.Schema = Schema;
var _default = Schema;
exports.default = _default;