"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultReducers = exports.default = void 0;
const defaultReducers = {
  add: (state, action) => {
    state.items[action.payload.id] = action.payload;
  },
  remove: (state, action) => {
    delete state.items[action.payload];
  },
  populate: (state, action) => {
    state.items = action.payload;
  },
  replace: (state, action) => {
    state.items[action.payload.id] = action.payload;
  },
  update: (state, action) => {
    state.items[action.payload.id] = { ...state.items[action.payload.id],
      ...action.payload
    };
  },
  clear: (state, action) => {
    state.items = [];
  }
};
exports.defaultReducers = defaultReducers;
var _default = defaultReducers;
exports.default = _default;