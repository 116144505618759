/**
 * MUI Dependencies
 */
import { ButtonGroup, Button } from '@mui/material';

/**
 * Internal Dependencies
 */
import {ViewKanbanIcon, ViewListIcon} from "./../Icons"

export const VIEWS = {
	"GRID" : 0,
	"COLS" : 1,
}

const ButtonToggles = (props) => {
    return (
        <ButtonGroup>
            { props.options.map( (option, index) => (

                <Button	
                    key = {index}
                    variant = {( props.value === option.value ? "contained" : "outlined" )}
                    onClick={() => {
                        props.onChange(option.value)
                    }}
					disableElevation
                >
                    <option.icon />
                </Button>

             ) ) }
            
        </ButtonGroup>
    )
}

export const ViewSwitcher = (props) => {
	return (
		<ButtonToggles 
			value = {props.selectedView}
			onChange = {props.setSelectedView}
			options = {[
				{
					label : "Grid",
					value : VIEWS.GRID,
					icon : ViewListIcon
				},
				{
					label : "Cols",
					value : VIEWS.COLS,
					icon : ViewKanbanIcon
				}
			]}
		/>
	)
}

export default ViewSwitcher