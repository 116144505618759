import { createTheme } from '@mui/material/styles';
import * as colours from '@mui/material/colors';
import { darken } from '@mui/material/styles';


function getAllPalletColours(){

	const calculated_colours = {}

	Object.keys(colours).filter( key => key !== "common").forEach( (key) => {
		
		calculated_colours[key] = {
			light : colours[key]["200"],
			main : colours[key]["300"],
			dark : colours[key]["900"],
			contrastText : darken(colours[key]["900"], 0.5)
		}
	})

	return calculated_colours
}

const blue = {
    light : '#f5f8f8',
    main : '#4CB4B8',
    dark : '#3f9a9d',
    contrastText : '#ffffff'
}

const green = {
	light : '#D2F3B9',
	main : '#9BE564',
	dark : '#66C021',
	contrastText : '#ffffff'
}

const FallBackFontFamily = [
    'Roboto',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
]

const DefaultFontFamily = [
    '"Apercu Pro"',
    ...FallBackFontFamily
]

const HeadingFontFamily = [
    '"Osmose"',
    ...DefaultFontFamily
]

const InputFontFamily = [
    '"Apercu Mono Pro"',
    ...DefaultFontFamily
]


const headingDef =  {
	fontWeight: 700,
	fontFamily: HeadingFontFamily.join(','),
	letterSpacing: 0.5,
}

const theme = createTheme({
    palette: {
        primary: blue,
		success: green,
		neutral: {
			main: '#64748B',
			contrastText: '#fff',
		},
		...getAllPalletColours()
    },
    shape : {
        borderRadius: '10px'
    },
    typography: {
        fontFamily: DefaultFontFamily.join(','),
        families : {
            default : DefaultFontFamily.join(','),
            heading : HeadingFontFamily.join(','),
            input : InputFontFamily.join(','),
        },
		h4: headingDef,
		h5: headingDef,
		h6: headingDef,
    },
    components:{
        MuiTextField : {
            color : blue
        },
		MuiChip : {
			styleOverrides: {
				// Name of the slot
				label: {
				  // Some CSS
				  textTransform: 'uppercase',
				  fontWeight: 700,
				  fontSize: "10px",
				  lineHeight:"12px",
				  verticalAlign:"middle"
				},
			},
		},
		MuiDataGrid : {
			styleOverrides: {
				root: {
					lineHeight : "1.4"
				},
				columnHeaderTitle : {
					fontWeight: 700
				}
			}
		}

    }
});

export default theme
export {theme}