import {createContext} from "react"


let SearchState = {
    isModalOpen : false
}

const SearchContext = createContext(SearchState);

export {SearchContext, SearchState}
export default SearchContext