import { TableContainer, Paper, Table, TableBody } from "@mui/material"

const OverviewTable = (props : {
    children ?: JSX.Element|JSX.Element[];
}) => (
    <TableContainer component={Paper} sx={{
        mb : '2rem'
    }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
                {props.children}
            </TableBody>
        </Table>
    </TableContainer>
)

export {OverviewTable}
export default OverviewTable