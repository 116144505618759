import { addSlice } from "../Redux"
import { AuthSlice, Auth } from "./"


export function createAuth(){

    addSlice("auth", AuthSlice)

    let auth = new Auth()
    auth.init()

    return auth
}