/**
 * External Dependencies
 */
import { isNil } from "lodash";
import {useState } from "react"

/**
 * MUI Dependencies
 */
import { Button, TextField, Stack, Typography } from "@mui/material";


export const NoteInput = (props) => {

    const [fieldTouched, setFieldTouched] = useState(false);
    const [inputValue, setInputValue] = useState(null);

    const showUnsaved = (
        fieldTouched && (
            !( isNil(props.value) && (inputValue === "") )
        )
    )

    return (
        <>
            <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    label = "Opportunity Description"
                    margin="normal"
                    value = {inputValue ?? props.value ?? ""}
                    onChange = { (event) => {
                        setFieldTouched(true)
                        setInputValue(event.target.value)
                    }}
                    
                />
                <Stack
                    direction = "row"
                    justifyContent="space-between"
                    alignItems =  "center"
                >
                    <Typography>
                        { showUnsaved && (<>
                            Unsaved
                        </>) }
                    </Typography>
                    <Stack
                         direction = "row"
                         spacing={2}
                    >
                        <Button
                          
                            onClick = { () => {
                                props.onClose()
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            variant = "contained"
                            onClick = { () => {
                                if(fieldTouched){
                                    props.onSave(inputValue)
                                }
                                setFieldTouched(false)
                                props.onClose()
                            }}
                        >
                        Save
                        </Button>
                    </Stack>
                </Stack>
        </>
    )
}

export default NoteInput