import { FormControl, ListItemText, Checkbox, InputLabel, Select as MuiSelect, MenuItem } from "@mui/material"
import { isArray, isUndefined, isNil, isEmpty } from "lodash"


const Select = (props) => {


    // props
    let {
        options = [], 
        label,
        id, 
        name,
        multiple = false,
        value, 
        touched, 
        errors 
    } = props



    // calculate value

    if((multiple) && (isUndefined(value))){
        value = []
    } else if((multiple) && (!isArray(value))){
        if(isNil(value[name])){
            value = []
        }else if(value[name] === ""){
            value = []
        } else {
            value = [value[name]]
        }
    } else if(isUndefined(value)) {
        value = ''
    } 



    const isSelected = (option) => {

        if(!multiple){
            return (option === value)
        }
        
        return value.includes(option)
    }



    const onChange = (event, value, option) => {
        props.setValue(name, event.target.value)
    }

    const renderValue = (selected) => {

        if(isNil(selected)){
            return null
        }

        if(multiple){

            if(isEmpty(selected)){
                return null
            }
            
            let newValues = selected.map( (select) => {
                return props.options[select].title
            })

            return newValues.join(', ')
        
            
        } else {

            return props.options[selected].title
        }

    }


    return (
        <FormControl fullWidth margin="normal" >

            <InputLabel id={ id + "-label" } label ={label}>
                { label }
            </InputLabel>

            <MuiSelect
                label={ label }
                id={ id }
                labelId={props.id + "-label"}
                value={value}
                onChange={ onChange }
                renderValue = {renderValue}
                multiple = { multiple }
                error={touched && Boolean(errors)}
            >
                { options.map( (option, index) => {
                    
                    return (
                        <MenuItem key={index} value={option.value}>
                            <Checkbox checked={isSelected(option.value)} size="small"  />
                            <ListItemText primary={option.title} />
                        </MenuItem>
                    )
                })}
            </MuiSelect>

        </FormControl>
    )

}

export default Select
export {Select}