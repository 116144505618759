import {withHelmetFromModel} from "./withHelmet";
import withLoaded from "./withLoaded";
import withModel from "./withModel";

export const withView = (WrappedComponent, Model) => {
	return (props) => {

		WrappedComponent =  withHelmetFromModel(WrappedComponent)
		WrappedComponent =  withLoaded(WrappedComponent)
		WrappedComponent =  withModel(WrappedComponent, Model)

		return (
			<WrappedComponent 
			{...props}
		/>)
	}
}
