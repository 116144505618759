"use strict";

require("core-js/modules/web.dom-collections.iterator.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Opportunity = void 0;

require("core-js/modules/es.array.includes.js");

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = require("lodash");

var yup = _interopRequireWildcard(require("yup"));

var _portalModel = require("portal-model");

var _Slice = _interopRequireDefault(require("./Slice.js"));

var _Model = _interopRequireDefault(require("./../Companies/Model"));

var _Model2 = _interopRequireDefault(require("./../Notes/Model"));

var _portalConstants = require("portal-constants");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//@ts-nocheck 
//external
// data
class Opportunity extends _portalModel.Model {
  // these make typescript stop complaining
  // TODO : Make Model parent typescript and use these better
  // I'm overriding the constructor at the moment 
  // to stop the getters being get from the data array
  constructor(args) {
    super(args, false);
    (0, _defineProperty2.default)(this, "removeContact", contact => {
      console.log(contact);
      this.data.contacts = this.data.contacts.filter(c => {
        return c !== contact;
      });
      this.save();
    });
    (0, _defineProperty2.default)(this, "addNote", (note, user) => {
      let data = new _Model2.default();
      data.setMany({
        "content": note,
        "parent": this.getID(),
        "user": user.uid
      });
      data.save();
    });
    (0, _defineProperty2.default)(this, "removeNote", id => {
      let data = _Model2.default.get(id)();

      data.delete();
      console.log("remove note", id, data); //this.data.notes = this.data.notes.filter( n => n.id !== id)
    });
  }

  static loadSchema() {
    this.schema = require("./Schema.js").Schema;
  }

  get static() {
    return this.constructor;
  }

  getTypeLabel() {
    if ((0, _lodash.isUndefined)(this.type)) {
      return "-";
    }

    let type = _portalConstants.OpportunityTypes.find(t => {
      return t.value === this.type;
    });

    if (!(0, _lodash.isUndefined)(type)) {
      return type.title;
    } else {
      return this.type;
    }
  }

  getEnumItem() {
    let index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    let options = arguments.length > 1 ? arguments[1] : undefined;
    let item = options.find(t => {
      return t.value === index;
    });
    return item;
  }

  getEnumLabel() {
    let index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    let options = arguments.length > 1 ? arguments[1] : undefined;

    if ((0, _lodash.isUndefined)(index)) {
      return "-";
    }

    if (index === null) {
      return "-";
    }

    if (index === false) {
      return "-";
    }

    let item = this.getEnumItem(index, options);

    if (!(0, _lodash.isUndefined)(item)) {
      return item.title;
    } else {
      return index;
    }
  }

  getEnumColor() {
    let index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    let options = arguments.length > 1 ? arguments[1] : undefined;
    let item = this.getEnumItem(index, options);

    if (!(0, _lodash.isUndefined)(item)) {
      return item.color;
    } else {
      return 'primary';
    }
  }

  getStatusLabel() {
    return this.getEnumLabel(this.status, _portalConstants.OpportunityStatuses);
  }

  getStatusColor() {
    return this.getEnumColor(this.status, _portalConstants.OpportunityStatuses);
  }

  getStageLabel() {
    return this.getEnumLabel(this.stage, OpportunityStages);
  }

  getStageColor() {
    return this.getEnumColor(this.status, OpportunityStages);
  }

  estimateAverage() {
    return (parseInt(this.data.budget_high_estimate) + parseInt(this.data.budget_low_estimate)) / 2;
  }

  get value() {
    var _this$data$budget_kno, _ref, _this$data$budget_kno2;

    //return this.estimateAverage()
    let budget = (_this$data$budget_kno = this.data.budget_known) !== null && _this$data$budget_kno !== void 0 ? _this$data$budget_kno : this.data.value;
    let estimate = this.estimateAverage();

    if (budget === "") {
      return estimate;
    } else {
      return budget;
    }

    return (_ref = (_this$data$budget_kno2 = this.data.budget_known) !== null && _this$data$budget_kno2 !== void 0 ? _this$data$budget_kno2 : this.estimateAverage()) !== null && _ref !== void 0 ? _ref : 0;
  }

  updateStatus(status) {
    console.log(_portalConstants.OpportunityStatusesTaxonomy);
    this.set("status", status);
    let closedStatuses = [_portalConstants.OpportunityStatusesTaxonomy.WON, _portalConstants.OpportunityStatusesTaxonomy.LOST, _portalConstants.OpportunityStatusesTaxonomy.GHOSTED, _portalConstants.OpportunityStatusesTaxonomy.ABANDONED, _portalConstants.OpportunityStatusesTaxonomy.UNQUALIFIED];

    if (closedStatuses.includes(status)) {
      this.set("stage", _portalConstants.OpportunityStagesTaxonomy.CLOSED);
    }
  }

}

exports.Opportunity = Opportunity;
(0, _defineProperty2.default)(Opportunity, "schema", void 0);
(0, _defineProperty2.default)(Opportunity, "urlPath", "opportunities");
(0, _defineProperty2.default)(Opportunity, "label", "Opportunity");
(0, _defineProperty2.default)(Opportunity, "form", {
  title: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'title',
      name: 'title',
      label: "Name",
      placeholder: "Acme Inc.",
      helperText: "company name",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  type: {
    type: "string",
    form: true,
    field: {
      type: "select",
      id: 'type',
      name: 'type',
      label: "Opportunity Type",
      helperText: "Opportunity Type",
      validation: yup.array().min(1).required(),
      order: 1,
      multiple: true,
      options: _portalConstants.OpportunityTypes
    }
  },
  status: {
    type: "string",
    form: true,
    field: {
      type: "select",
      id: 'status',
      name: 'status',
      label: "Opportunity Status",
      helperText: "Opportunity Status",
      validation: yup.string().required(),
      order: 1,
      options: _portalConstants.OpportunityStatusesTaxonomy.collection,
      initialValue: _portalConstants.OpportunityStatusesTaxonomy.ACTIVE
    }
  },
  stage: {
    type: "string",
    form: true,
    field: {
      type: "select",
      id: 'stage',
      name: 'stage',
      label: "Opportunity Stage",
      helperText: "Opportunity Stage",
      validation: yup.string().required(),
      order: 1,
      options: _portalConstants.OpportunityStagesTaxonomy.collection,
      initialValue: _portalConstants.OpportunityStagesTaxonomy.NEW
    }
  },
  company: {
    type: "relationship",
    form: true,
    field: {
      type: "autocomplete",
      id: 'company',
      name: 'company',
      label: "Company",
      placeholder: "Acme Inc.",
      helperText: "company name",
      order: 1,
      model: _Model.default
    }
  },
  value: {
    type: "string",
    form: true,
    field: {
      type: "currency",
      id: 'value',
      name: 'value',
      label: "Budget",
      placeholder: "£10,000",
      helperText: "Expected Budget",
      order: 1,
      initialValue: ""
    }
  }
});
(0, _defineProperty2.default)(Opportunity, "slice", _Slice.default);
var _default = Opportunity;
exports.default = _default;