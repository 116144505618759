"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.OpportunityTypes = void 0;
const OpportunityTypes = [{
  value: 0,
  title: "Build"
}, {
  value: 1,
  title: "Consult"
}, {
  value: 2,
  title: "Retainer"
}, {
  value: 3,
  title: "Design"
}, {
  value: 4,
  title: "Fixes"
}, {
  value: 5,
  title: "Other"
}];
exports.OpportunityTypes = OpportunityTypes;
var _default = OpportunityTypes;
exports.default = _default;