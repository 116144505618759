"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.withSchema = withSchema;

var _lodash = require("lodash");

function withSchema(Model) {
  if ((0, _lodash.isUndefined)(Model.schema)) {
    Model.loadSchema();
  }
}

var _default = withSchema;
exports.default = _default;