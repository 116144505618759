
import {Companies} from "./Companies"
import {Projects} from "./Projects"
import {Retainers} from "./Retainers"
import {Invoices} from "./Invoices"
import {Opportunities} from "./Opportunities"
import {Contacts} from "./Contacts"

let features = { Companies, Projects, Retainers, Invoices, Opportunities, Contacts}
export default features
export {Companies, Projects, Retainers, Invoices, Opportunities, Contacts}