
import {Stack, Typography} from "@mui/material"

import { Accordion } from "./../../Accordion"
import { EntityIcon } from "./../../EntityIcon"
import { Company } from "portal-datatypes"
import { AutoCompleteFromModel } from "./../../Fields/AutoComplete"

const CompanyAccordion = (props) => {

    const CompanyAccordionSummary = (props) => {
        return (<Stack
            direction = "row"
            alignItems={"center"}
            spacing = {1}
        >
                <Typography fontWeight={700}>Company:</Typography>
                {props.model.companies && (<>
                    <EntityIcon size="small" model= {props.model.companies} />
                    <Typography fontWeight={700}>{props.model.companies.title}</Typography>
                    </>)}
            </Stack>)
    }

    return (
        <Accordion
            id="accordion-company"
            summary = {<CompanyAccordionSummary model = {props.model} />}
            defaultExpanded = {true}
        >
            <Stack
                direction="row"
                spacing = {2}
                alignItems={"center"}
            >
                        
                <EntityIcon model= {props.model.companies} />
                <AutoCompleteFromModel
                    name  = "opp-company-select"
                    model = {Company}
                    value = {props.model.companies.id}
                    setValue = {(value, name) => {
                        props.model.set({"company" : value})
                        props.model.save()
                    }}
                />
 
            </Stack>
        </Accordion>
    )
}


export {CompanyAccordion}
export default CompanyAccordion