//@ts-nocheck



import { GridRowParams } from "@mui/x-data-grid-pro"

import {Link as LinkColumnType} from "./../ColumnTypes/Link"
import {Picked as PickedColumnType} from "./../ColumnTypes/Picked"
import ActionSet from "../ActionSets/ActionSet";
import { EditUserDrawer } from "../../Forms/EditUser";


const Users = [
    { 
      field: 'id', 
      headerName: 'ID', 
      width: 70,
      hide: true
    }, 
    { 
      field: 'displayName', 
      headerName: 'Name', 
      minWidth: 170,
      ...LinkColumnType
    },
    { 
        field: 'role', 
        headerName: 'Role', 
        minWidth: 170,
        ...PickedColumnType
    },
    { 
        field: 'department', 
        headerName: 'Department', 
        minWidth: 170,
        flex: 1,
        ...PickedColumnType               
    },
    { 
        field: 'last_login', 
        headerName: 'Last Login', 
        minWidth: 170,
        flex: 1,               
    },
    { 
        field: 'actions', 
        headerName: 'Actions', 
        type: "actions", 
       
        getActions: (params: GridRowParams) => [(<ActionSet
            editForm = { EditUserDrawer }
            disableDelete = {true}
          {...params}   
        />)]
    }  
  ];
  
  

  export default Users
  export {Users}