"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Schema = void 0;

var _portalModel = require("portal-model");

var _portalConstants = require("portal-constants");

var _Model = require("./../Companies/Model");

var _Model2 = require("./../Invoices/Model");

var _Model3 = require("./../Retainers/Model");

var _Model4 = require("./../Time/Model");

const Schema = {
  'id': (0, _portalModel.id)(),
  title: (0, _portalModel.attr)(),
  budget: (0, _portalModel.attr)(),
  company: (0, _portalModel.belongsTo)(_Model.Company, "projects"),
  invoices: (0, _portalModel.hasMany)(_Model2.Invoice),
  status: (0, _portalModel.picked)(_portalConstants.ProjectStatuses),
  type: (0, _portalModel.picked)(_portalConstants.ProjectTypes),
  freeagent: (0, _portalModel.attr)(),
  harvest_project_id: (0, _portalModel.attr)(),
  retainer: (0, _portalModel.belongsTo)(_Model3.Retainer, "project"),
  time: (0, _portalModel.foreignKey)(_Model4.Time, "project")
};
exports.Schema = Schema;
var _default = Schema;
exports.default = _default;