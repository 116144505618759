//@ts-nocheck
import {useState} from "react"

import {Contact} from "portal-datatypes"

// mui
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';

// components
import {Archive, Frame} from "../../Components";
import { Contacts as ContactsColumns } from "./../../Components/DataGrids/Columns/Contacts"

import { AddItemControl } from './../../Components/Form/DrawerControl';
import { AddContactDrawer } from "./../../Components/Forms/AddContact"




const Contacts = ( props : any ) => {

	const [visibilityModel, setVisibilityModel] = useState(props.columnVisibilityModel ?? {
		[GRID_CHECKBOX_SELECTION_COL_DEF.field] : true,
		title: true,
		actions: true,
	})

	let query = {}
	
    return (
		<Frame>
        <Archive
            title = {props.title ?? "Contacts"}
            controls = {(
                <AddItemControl 
                    label = "Add Contact"
                    form = {AddContactDrawer}
                />
            )}
			Model = { Contact }
			query = { query }
			gridProps = {{
				columns : (props.columns ?? ContactsColumns),
				columnVisibilityModel : visibilityModel,
				onColumnVisibilityModelChange : (newModel) => {
					setVisibilityModel(newModel)
				},
				disableColumnMenu : false,
			}}
        />
	</Frame>
    )
}

export default Contacts
export {Contacts}