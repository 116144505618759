
import { GridRowParams } from "@mui/x-data-grid-pro";

import {Link as LinkColumnType} from "./../ColumnTypes/Link"
import {RelatedLink as RelatedLinkColumnType} from "./../ColumnTypes/RelatedLink"
import ActionSet from "../ActionSets/ActionSet";

const Contacts = [
    { 
      field: 'id', 
      headerName: 'ID', 
      width: 70,
      hide: true
    }, 
    { 
      field: 'title', 
      headerName: 'Name', 
      width: 170,       
      ...LinkColumnType 
    },
    { 
        field: 'companies', 
        headerName: 'Company', 
        width: 170,       
        ...RelatedLinkColumnType
    },
    { 
        field: 'actions', 
        headerName: 'Actions', 
        type: "actions", 
        width: 170,
        getActions: (params: GridRowParams) => [(<ActionSet
            editForm = { null }
          {...params}   
        />)]
    }  
  ];
  
  

  export default Contacts
  export {Contacts}