import { Paper, Typography } from "@mui/material"

const Stat = ( props : {
    label : string,
    value : string,
}) => {
    return (
        <Paper
            sx = {{p : 3}}
        >
            <Typography
                component="h5"
                variant="overline"
                gutterBottom
                sx={{ textAlign: 'center' }}
            >
                {props.label}
            </Typography>
            <Typography
                component="p"
                variant="h1"
                sx={{ textAlign: 'center' }}
            >
                {props.value}
            </Typography>
            <Typography
                component="p"
                variant="caption"
                sx={{ textAlign: 'center' }}
            >
                Hours
            </Typography>
        </Paper>
    )
}

export default Stat
export {Stat}