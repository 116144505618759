//@ts-nocheck
import React from "react"
import {HZNForm} from "../Form/Form"

import {Project} from "portal-datatypes" 
import { withFormDrawer } from "../Form/Drawer"

const EditMultipleProjects = (props : any) => {

	let {
		items
	} = props

	items = Project.get(items)().toArray()
	const itemCount = items.length
	

    return(
      <HZNForm

        Model = {Project}
        fields = {Project.getFormFields("FormEditMultiple")}
		//validation = {Project.getFormFieldsValidation("FormEditMultiple")}
		validation = {false}
		
		title = {`Edit ${itemCount} Projects`}
        buttonText = {`Update ${itemCount} Projects`}
		successMessage = "Projects Updated"

        {...props}
		items = {items}

        action = "editMultiple"
        showAddAnotherButton = {false}
		useModelInitialValues = {false}
      />
    )
}

const EditMultipleProjectsDrawer = withFormDrawer(EditMultipleProjects)

export default EditMultipleProjects
export {EditMultipleProjects, EditMultipleProjectsDrawer}