import { AppBar, Toolbar, Typography, Box, IconButton, Button } from "@mui/material"

import { Search, Settings } from "@mui/icons-material";
import { UserMenu } from "./Header/Menus/index.js"
import { Link as RouterLink} from 'react-router-dom';

import {SpeedDial} from "./../Components/SpeedDial"
import {SearchContext} from "./../Components/Search"
import { useContext } from "react";

import {useMenu, Menu, MenuItem} from "./Menu"

import {Nav} from "../Core/Navigation/Nav.tsx";

const LocalMenu = (props) => {

    const {
        title, 
        items,
        path = false
    } = props.menu

    const menu = useMenu()

    if(!items){
        return (
            <Button
                variant="contained" 
                disableElevation
                component = {RouterLink}
                to = {path}
            >
                {title}
            </Button>
        )
    }

    return(

        <Menu
            label = {title}
            {...menu}
        >
            
            {items.map( (item, key) => (
                <MenuItem to={item.path} divider={item.divider ?? false} key={key}>
                    {item.title}
                </MenuItem>
            ) ) }
        </Menu>
    )
}

const Frame = (props) => {

    const SearchButton = (props) => {

        const search = useContext(SearchContext)

        return (
            <IconButton 
                aria-label="search"
                onClick = {search.toggleSearchModel}
            >
                <Search />
            </IconButton>
        )
    }

	const showSpeedDial = false

    return (<>
    
        <AppBar position="fixed" sx={{ 
			zIndex: (theme) => theme.zIndex.drawer + 1,
			boxShadow: "none"
			}} >
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 0, marginRight: "2rem" }}>
                    Portal
                </Typography>
                <Box sx={{ flexGrow: 1 }}>
                    { Nav.map( (menu, key) => (
                        <LocalMenu menu={menu} key={key}/>
                    ))}
                </Box>

                <SearchButton />

                <IconButton 
                    aria-label="settings"
                    component = {RouterLink}
                    to = "/settings"
                >
                    <Settings />
                </IconButton>

                <UserMenu />
            </Toolbar>
        </AppBar>
        <Box sx={{display: "flex" }}>
            
            <Box component="main" sx={{ display:"flex", flexDirection:"column", minHeight:"100vh", flexGrow: 1, overflow: "hidden"}}>
                <Toolbar />
                {props.children}
            </Box>
        </Box>
		{showSpeedDial && (
        	<SpeedDial />
		)}
    
    </>)
}

export default Frame
export {Frame}