"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = filterArray;

var _falsyValue = _interopRequireDefault(require("./falsyValue"));

function filterArray(func, items) {
  if (func) {
    return items.filter(func);
  }

  const result = [];

  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];

    if (!(0, _falsyValue.default)(item)) {
      result.push(item);
    }
  }

  return result;
}