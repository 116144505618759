"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Config = void 0;
var Config = {
  type: "time-tracking",
  label: "Harvest",
  name: "harvest",
  url: "https://harvest.com",
  enabled: true,
  oauth: {
    authorizationURL: 'https://id.getharvest.com/oauth2/authorize',
    tokenURL: 'https://id.getharvest.com/api/v2/oauth2/token',
    clientID: 'TNkpW8HjJHIQ79_bZFCFz1x_',
    //callbackURL: 'http://localhost:5001/pbc-portal-150c3/us-central1/server/harvest/auth/callback'
    callbackURL: 'https://fe4b-2a01-4c8-1143-520f-bd83-33b9-49d8-419c.eu.ngrok.io/pbc-portal-150c3/us-central1/server/harvest/auth/callback'
  }
};
exports.Config = Config;
var _default = Config;
exports.default = _default;