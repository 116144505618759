"use strict";

require("core-js/modules/web.dom-collections.iterator.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Contact = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var yup = _interopRequireWildcard(require("yup"));

var _portalModel = require("portal-model");

var _Model = _interopRequireDefault(require("./../Companies/Model"));

var _Slice = _interopRequireDefault(require("./Slice.js"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//@ts-nocheck 
//external
// data
class Contact extends _portalModel.Model {
  // these make typescript stop complaining
  // TODO : Make Model parent typescript and use these better
  // I'm overriding the constructor at the moment 
  // to stop the getters being get from the data array
  constructor(args) {
    super(args, false);
  }

  static loadSchema() {
    this.schema = require("./Schema.js").Schema;
  }

  get static() {
    return this.constructor;
  }

  hasIcon() {
    return false;
  }

}

exports.Contact = Contact;
(0, _defineProperty2.default)(Contact, "schema", void 0);
(0, _defineProperty2.default)(Contact, "urlPath", "contact");
(0, _defineProperty2.default)(Contact, "label", "Contact");
(0, _defineProperty2.default)(Contact, "slice", _Slice.default);
(0, _defineProperty2.default)(Contact, "form", {
  title: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'title',
      name: 'title',
      label: "Name",
      placeholder: "Jo doe",
      helperText: "What are they called?",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  company: {
    type: "relationship",
    form: true,
    field: {
      type: "autocomplete",
      id: 'company',
      name: 'company',
      label: "Company",
      placeholder: "Acme Inc.",
      helperText: "company name",
      order: 1,
      model: _Model.default
    }
  }
});
var _default = Contact;
exports.default = _default;