import {  GridRenderCellParams, GridComparatorFn, GridFilterItem, getGridStringOperators, GridValueGetterParams} from "@mui/x-data-grid-pro";
import { isUndefined, isArray, isEmpty } from "lodash";
import { IconLink } from "./RenderIconLink";


const titleCompatator: GridComparatorFn<any> = (v1, v2) => {
	return v1.title.localeCompare(v2.title)
}

const makeFilterOperators = () => {

	let operators = getGridStringOperators().filter( (op) => {
		return !["isEmpty", "isNotEmpty"].includes(op.value)
	})

	return [
		...operators,
		{
			"label" : "Is Empty",
			"value" : "isEmpty",
			getApplyFilterFn : (filterItem: GridFilterItem) => {
				return (params:any): boolean => {
					return isEmpty(params.value)
				};
			}
		},
		{
			"label" : "Is Not Empty",
			"value" : "isNotEmpty",
			getApplyFilterFn : (filterItem: GridFilterItem) => {
				return (params:any): boolean => {
					return !isEmpty(params.value)
				};
			}
		}
	]
}

const RelatedLink = {
	sortComparator: titleCompatator,
	valueGetter: (params : GridValueGetterParams) => {
		return params.value?.title
	},
    renderCell: (params: GridRenderCellParams) => {

		
        if( isUndefined(params.row[params.field])) {
          return "-"
        }

		let value = params.row[params.field]
        let item
        if(isArray(value)){

            if( isUndefined(value[0])) {
                return "-"
            } else {
                item = value[0]
            }
            
        } else {
            item = value
        }

        return (
           <IconLink model={item} />
        )
    },
	filterOperators: makeFilterOperators()
}

export default RelatedLink
export {RelatedLink}