import { TableRow, TableCell } from "@mui/material"
const Row = (props) => {

    return (
        <TableRow>
            <TableCell sx={{"width" : '150px'}}>{props.label}</TableCell>
            <TableCell>{props.value}</TableCell>
        </TableRow>
    )

}

export {Row}
export default Row