"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.invoiceSlice = exports.default = void 0;

var _portalRedux = require("portal-redux");

// selector helping
// firebase slice
const invoiceSlice = new _portalRedux.FirebaseSlice({
  table: "invoices",
  name: 'invoices'
});
exports.invoiceSlice = invoiceSlice;
var _default = invoiceSlice;
exports.default = _default;