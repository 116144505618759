import {GridValueGetterParams } from "@mui/x-data-grid-pro";

import IconLink from "./RenderIconLink";

 const Link = {
    renderCell: (params: GridValueGetterParams) => {
        return (<IconLink model={params.row} />)
    }
}

export default Link
export {Link}