//@ts-nocheck
import React from "react"
import {HZNForm} from "../Form/Form"

import {Project} from "portal-datatypes" 
import { withFormDrawer } from "../Form/Drawer"

import{ isEmpty, isObject} from "lodash"

const EditProject = (props : any) => {

	let {
		items = [],
		item = null,
		...restProps
	} = props
	
	if(!isEmpty(items)){
		if(items.length > 1){
			console.error("Too many Items passed to the edit form. Use Either the `item` prop with one model or the `items` prop as an array with one Model")
		} else {
			item = items[0]
		}
	}
	
	if(!isObject(item)){
		item = Project.get(item)()
	}


    return(
      <HZNForm
        Model = {Project}
        fields = {Project.getFormFields()}
        title = "Edit Project"
        buttonText = "Update Project"
		{...restProps}
		item = {item}
        action = "edit"
        successMessage = "Project Updated"
        showAddAnotherButton = {false}
      />
    )
}

const EditProjectDrawer = withFormDrawer(EditProject)

export default EditProject
export {EditProject, EditProjectDrawer}