"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Config = void 0;
var Config = {
  type: "finance",
  label: "Xero",
  name: "xero",
  url: "https://xero.com",
  enabled: false,
  oauth: {
    authorizationURL: 'https://id.getharvest.com/oauth2/authorize',
    tokenURL: 'https://id.getharvest.com/api/v2/oauth2/token',
    clientID: 'TNkpW8HjJHIQ79_bZFCFz1x_'
  }
};
exports.Config = Config;