//@ts-nocheck
import { GridRowParams } from "@mui/x-data-grid-pro";
import { ProjectTypes, ProjectStatuses } from "portal-constants";

import {Currency as CurrencyColumnType} from "../ColumnTypes/Currency"
import ActionSet from "../ActionSets/ActionSet";
import { EditProjectDrawer } from "../../Forms/EditProject";

import {Link as LinkColumnType} from "./../ColumnTypes/Link"
import {RelatedLink as RelatedLinkColumnType} from "./../ColumnTypes/RelatedLink"
import {Picked as PickedColumnType} from "./../ColumnTypes/Picked"
import {Actions as ActionsColumnType} from "./../ColumnTypes/Actions"

import {convertTitleToLabel} from "./Utils"

const Projects = [
    
    { 
      field: 'title', 
      headerName: 'Name', 
      width: 170,
      ...LinkColumnType
    },{
      field: 'companies', 
      headerName: 'Company', 
      width: 170,
      ...RelatedLinkColumnType
    },{
      field: 'type', 
      headerName: 'Type', 
      width: 170,
	  ...PickedColumnType,
	  valueOptions: convertTitleToLabel(ProjectTypes)
    },{
      field: 'status', 
      headerName: 'Status', 
      width: 170,
      ...PickedColumnType,
	  valueOptions: convertTitleToLabel(ProjectStatuses)
    },{
      field: 'budget', 
      headerName: 'Budget', 
      width: 170,
      ...CurrencyColumnType
    },{
      field: 'invoiced', 
      headerName: 'Invoiced', 
      width: 170,
      ...CurrencyColumnType
    },{
      field: 'paid', 
      headerName: 'Recieved', 
      width: 170,
      ...CurrencyColumnType
    },{
      field: 'remaining', 
      headerName: 'Remaining', 
      width: 170,
      ...CurrencyColumnType
    },{
      field: 'due', 
      headerName: 'Due', 
      width: 170,
      ...CurrencyColumnType
    },{
       	...ActionsColumnType,
    	...{
			getActions: (params: GridRowParams) => [(<ActionSet 
          		{...params} 
          		editForm={EditProjectDrawer} 
        	/>)]
		}
    }
  ];

  export default Projects
  export {Projects}