"use strict";

require("core-js/modules/web.dom-collections.iterator.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.User = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = require("lodash");

var _portalModel = require("portal-model");

var _portalConstants = require("portal-constants");

var _Slice = _interopRequireDefault(require("./Slice.js"));

var yup = _interopRequireWildcard(require("yup"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//@ts-nocheck 
// data
class User extends _portalModel.Model {
  // these make typescript stop complaining
  // TODO : Make Model parent typescript and use these better
  // I'm overriding the constructor at the moment 
  // to stop the getters being get from the data array
  constructor(args) {
    super(args, false);
  }

  static loadSchema() {
    this.schema = require("./Schema.js").Schema;
  }

  get title() {
    return this.displayName;
  }

  hasIcon() {
    return !(0, _lodash.isNil)(this.data.photoUrl) || this.data.photoUrl !== "";
  }

  getIconSrc() {
    return this.data.photoUrl;
  }

  get photo() {
    return this.data.photoURL;
  }

}

exports.User = User;
(0, _defineProperty2.default)(User, "schema", void 0);
(0, _defineProperty2.default)(User, "form", {
  displayName: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'displayName',
      name: 'displayName',
      label: "Name",
      placeholder: "Acme Inc.",
      helperText: "company name",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  role: {
    type: "string",
    form: true,
    field: {
      type: "select",
      id: 'role',
      name: 'role',
      label: "User Role",
      helperText: "Permissions",
      order: 2,
      initialValue: "",
      options: _portalConstants.UserRoles,
      multiple: false
    }
  },
  department: {
    type: "string",
    form: true,
    field: {
      type: "select",
      id: 'department',
      name: 'department',
      label: "Department",
      placeholder: "Department",
      options: _portalConstants.Departments,
      multiple: true,
      initialValue: "",
      order: 3
    }
  },
  email: {
    type: "string",
    form: true,
    field: {
      type: "text",
      initialValue: "",
      order: 4,
      label: "Email"
    }
  },
  active: {
    type: "boolean",
    form: true,
    field: {
      type: "switch",
      initialValue: true,
      order: 1,
      label: "Active"
    }
  }
});
(0, _defineProperty2.default)(User, "slice", _Slice.default);
var _default = User;
exports.default = _default;