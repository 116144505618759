import {useMenu, Menu} from "./../Menu"
import { isArray, isUndefined } from "lodash"

import {Term} from "./../../Core/types"
import {InDropDownTitle, PickerItem, PickerTrigger} from "./";

import {Picker as PickerProps} from "./types"

const Picker = ( props : PickerProps ) => {
    
    const menu = useMenu()
    let {
		multiple = false,
		value = [],
		label,
		options = [],
		TriggerComponent = null
	} = props

    if(multiple && (!isArray(value))){
        console.error("Picker Requires the value prop to be an Array when using multiple select", value)
    }

    const isSelected = (option: number) => {
        return value.some( (v:number) => {
            return v === option
        } )
    }

	//@ts-ignore
	label = value?.reduce( (label:any, value:Term, index) : string => {
		
		if(isUndefined(value)){
			return ""
		}
		
		// in somecases value here is a Term and somecases a number, use a fallack and save it into a veriable
		let targetValue = value.value ?? value
		
		let item:Term|null = options.find( (o:Term) => {
			return ( o.value === targetValue ) 
		}) ?? null
		
		if(!item){
			return label
		}

		if( label === ""){
			return item.title
		}
		label = label + ", "
		label = label + item?.title
		return label
	}, "")
	

    const onClick = (status : number) => {
		
        if(!multiple){
			props.onChange(status)
        } else {
			
            let newValue = []

            if(value.includes(status)){
                newValue = value.filter( (val) => {
                    return (val !== status)
                } )
            } else {
                newValue = [...value, status] 
            }

            props.onChange(newValue)
        }

        if(!props.multiple){
            menu.handleClose()
        }
    }

	

	return (
		<Menu
			label = {label}
			{...menu}
			anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
			TriggerComponent = {TriggerComponent ?? PickerTrigger}
		>
			<InDropDownTitle label = {props.label} />
			{ options.map( (status : Term, index : number) => (
				<PickerItem 
					key = {index}
					index = {index} 
					value = {status.value}
					onClick = {onClick}
					title = {status.title}
					isSelected = {isSelected}
					multiple = {multiple}
				/>
			) ) }
		</Menu>
	)
}


export {Picker}
export default Picker