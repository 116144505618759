"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultSelectors = exports.default = void 0;

require("core-js/modules/es.array.includes.js");

var _collect = require("collect.js");

var _lodash = require("lodash");

const defaultSelectors = {
  getAll: state => {
    return { ...state.items
    };
  },
  get: (state, id) => {
    if (Object.keys(state.items).length === 0) {
      return null;
    }

    return (0, _lodash.cloneDeep)(state.items[id]);
  },
  getTopLevel: state => {
    let items = new _collect.Collection(state.items);
    let top_level = items.where("parent", false);
    return top_level.toArray();
  },
  getByRelation: (state, query) => {
    let items = new _collect.Collection(state.items);
    let hasKey = items.whereNotUndefined(query.key);
    let hasRelationship = hasKey.whereNotNull(query.key);
    let hasRelationshipWithQueried = hasRelationship.where(query.key + "." + query.id, true);
    return hasRelationshipWithQueried.toArray();
  },
  getByAttr: (state, query) => {
    let items = new _collect.Collection(state.items);
    let hasAttr = items.whereNotNull(query.attr);
    let hasAttrWithValue;

    if (Array.isArray(query.value)) {
      hasAttrWithValue = hasAttr.whereIn(query.attr, query.value);
    } else {
      hasAttrWithValue = hasAttr.where(query.attr, query.value);
    }

    return hasAttrWithValue.toArray();
  },
  getByAttrMap: (state, query) => {
    let items = new _collect.Collection(state.items);
    let hasAttr = items.whereNotNull(query.attr);
    let hasAttrWithValue;

    if (Array.isArray(query.value)) {
      hasAttrWithValue = hasAttr.whereIn(query.attr, query.value);
      return hasAttrWithValue.toArray();
    } else {
      //let key = query.attr + "." + query.value
      //hasAttrWithValue = hasAttr.where(key, true)
      hasAttrWithValue = hasAttr.filter(item => {
        if ((0, _lodash.isArray)(item[query.attr])) {
          return item[query.attr].includes(query.value);
        } else if ((0, _lodash.isObject)(item[query.attr])) {
          return item[query.attr][query.value] === true;
        } else {
          return item[query.attr] === query.value;
        }
      });
      return hasAttrWithValue.toArray();
    }
  },
  getAllQueried: (state, query) => {
    let items = new _collect.Collection(state.items);

    if ((0, _lodash.isNil)(query)) {
      return items.toArray();
    }

    if ((0, _lodash.isEmpty)(query)) {
      return items.toArray();
    }

    let filtered = items;
    Object.keys(query).forEach(key => {
      let value = query[key];

      if ((0, _lodash.isEmpty)(value) || (0, _lodash.isNil)(value)) {
        return;
      }

      filtered = filtered.filter(item => {
        if ((0, _lodash.isNil)(item[key])) {
          return false;
        } else if ((0, _lodash.isArray)(value) && (0, _lodash.isArray)(item[key])) {
          return !(0, _lodash.isEmpty)((0, _lodash.intersection)(value, item[key]));
        } else if ((0, _lodash.isArray)(value)) {
          return value.includes(item[key]);
        } else if ((0, _lodash.isArray)(item[key])) {
          return item[key].includes(value);
        } else {
          return item[key] === value;
        }
      });
      /*
      if(isArray(value)){
          filtered = filtered.whereIn(key, value)
      } else {
          filtered = filtered.where(key, value)
      }
      */
    }); //let hasKey = items.whereNotUndefined(query.key)
    //let hasRelationship = hasKey.whereNotNull(query.key)
    //let hasRelationshipWithQueried = hasRelationship.where(query.key + "." + query.id, true)

    return filtered.toArray(); //return []
  },
  query: (state, query) => {
    let items = new _collect.Collection(state.items);

    if ((0, _lodash.isEmpty)(query)) {
      return items.toArray();
    }

    items = items.filter(item => {
      let found = null;
      Object.keys(query).forEach(key => {
        if (found === false) {
          return;
        }

        if ((0, _lodash.isArray)(query[key]) && (0, _lodash.isArray)(item[key])) {
          let intersect = (0, _lodash.intersection)(query[key], item[key]);
          found = !(0, _lodash.isEmpty)(intersect);
        } else if ((0, _lodash.isArray)(query[key])) {
          found = query[key].includes(item[key]);
        } else {
          found = item[key] === query[key];
        }
      });
      return found;
    });
    return items.toArray();
  }
};
exports.defaultSelectors = defaultSelectors;
var _default = defaultSelectors;
exports.default = _default;