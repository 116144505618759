import {Box, CircularProgress} from "@mui/material"

const Loader = () => {
    return (
        <Box 
            sx = {{
                width: "100vw",
                height: "100vh",
                display: 'flex'
            }}
            flexDirection={"row"}
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
        >
            <CircularProgress />
        </Box>
    )
}

export {Loader}
export default Loader