"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.OpportunityStagesTaxonomy = exports.OpportunityStages = void 0;

var _Taxonomy = require("./Utils/Taxonomy");

const OpportunityStages = [{
  value: 0,
  title: "New",
  order: 0,
  slug: "new"
}, {
  value: 1,
  title: "Qualified",
  order: 1,
  slug: "qualified"
}, {
  value: 2,
  title: "Requirements Gathering",
  order: 2,
  slug: "requirements"
}, {
  value: 3,
  title: "Costing",
  order: 3,
  slug: "costsing"
}, {
  value: 4,
  title: "Negotiating",
  order: 5,
  slug: "negotiating"
}, {
  value: 5,
  title: "Closed",
  order: 6,
  slug: "closed"
}, {
  value: 6,
  title: "Proposal Sent",
  order: 4,
  slug: "proposal_sent"
}];
exports.OpportunityStages = OpportunityStages;
const OpportunityStagesTaxonomy = new _Taxonomy.Taxonomy(OpportunityStages);
exports.OpportunityStagesTaxonomy = OpportunityStagesTaxonomy;
var _default = OpportunityStages;
exports.default = _default;