//@ts-nocheck
import React from "react"
import {HZNForm} from "../Form/Form"

import {Opportunity} from "portal-datatypes" 
import { withFormDrawer } from "../Form/Drawer"

const EditOpportunity= (props : any) => {
    return(
      <HZNForm
        Model = {Opportunity}
        fields = {Opportunity.getFormFields()}
        title = "Edit Opportunity"
        buttonText = "Update Opportunity"
        {...props}
        action = "edit"
        successMessage = "Opportunity Updated"
        showAddAnotherButton = {false}
      />
    )
}

const EditOpportunityDrawer = withFormDrawer(EditOpportunity)

export default EditOpportunity
export {EditOpportunity, EditOpportunityDrawer}