//@ts-nocheck
import {Projects as Columns} from "../../Components/DataGrids/Columns/Projects"
import {Projects} from "./Projects"

const Builds = ( props : any ) => {

	console.log("Build")
	
    return (
      <Projects
        title = "Build"
		columns = {Columns}
		{...props}
      />    
    )
}

export default Builds
export {Builds}