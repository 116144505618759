import {Button} from "@mui/material"
import {DownArrowIcon} from "./../Icons"
import {PickerTrigger as PickerTriggerProps} from "./types"

export const PickerTrigger = (props : PickerTriggerProps ) => {

	const {
		label,
		handleOpen,
		variant = "contained"
	} = props
	
	return (
		<Button
			variant={variant}
			disableElevation
			endIcon={<DownArrowIcon />}
			onClick={handleOpen}
		>
			{label}
		</Button>
	)
}
