//@ts-nocheck
import { useEffect, useState, useCallback } from "react"
import { Route } from "react-router-dom";
import SidebarFrame from "../../Components/SidebarFrame"
import SettingsSidebar from "../../Components/Settings/SettingsSidebar"
import Team from "./Team"
import {Connections} from "./Connections"
import { categories as integration_categories } from "./../../Core/Integrations"
import { FireFunctions } from "./../../Core/Firebase";



import {AlertsContainer} from "./../../Components/Alerts"


const Settings = () => {

    const serviceLoginStatusCall = FireFunctions.httpsCallable('serviceLoginStatus');

    const [loginStatus, setLoginStatus] = useState(false)
    const [gettingLoginStatus, setGettingLoginStatus] = useState(false)
    const [gotLoginStatus, setGotLoginStatus] = useState(false)
    const [error, setError] = useState(false)

    const getLoginStatus = useCallback (() => {

        serviceLoginStatusCall().then( (result) => {        
            setLoginStatus(result.data)
            setGotLoginStatus(true)
            setGettingLoginStatus(false)

        }).catch((error) => {
            setError(error.message)
        })
    },[serviceLoginStatusCall])
    
    useEffect( () => {

        if(gettingLoginStatus || gotLoginStatus || error){
            return
        }

        setGettingLoginStatus(true)
        getLoginStatus()

    }, [gettingLoginStatus, gotLoginStatus, error, getLoginStatus])
    
    return (
        <SidebarFrame
            title = "Settings"
            Sidebar = {SettingsSidebar}
        >
			<AlertsContainer />

            {Object.values(integration_categories).map( (category, index) => (
                <Route path={`/settings/connections/${category.key}`} key={index} >
                    <Connections title={category.label} type={category.key} loginStatus = {loginStatus} onLoginStatusChange = {getLoginStatus} />
                </Route>
            ))}
            
            <Route path="/settings/team/members">
                <Team />
            </Route>
           
        </SidebarFrame>
    )
}
export default Settings
export {Settings}