import {Paper} from "@mui/material"

const DealMeta = (props) => {
    return (
        <Paper
            elevation = {0}
            sx = {{
                width:"25%",
                minWidth: '400px',
                maxWidth: '500px',
                backgroundColor: '#f5f5f5',
                maxHeight:'100%',
                overflow: "scroll"
            }}
            square
        >
            {props.children}
        </Paper>
    )
}


export {DealMeta}
export default DealMeta