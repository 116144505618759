import {useState} from "react"

import { SearchContext, SearchModal } from "."

const SearchProvider = (props) => {
    
    let [isSearchModalOpen, setSearchModalOpen ] = useState(false)
    let [searchTerm, setSearchTerm ] = useState("")

    return (
        <SearchContext.Provider value={{
            isSearchModalOpen : isSearchModalOpen,
            openSearchModel : () => setSearchModalOpen(true),
            closeSearchModel : () => setSearchModalOpen(false),
            toggleSearchModel : () => setSearchModalOpen(!isSearchModalOpen),
            searchTerm : searchTerm,
            setSearchTerm : setSearchTerm,
            clearSearchTerm : () => setSearchTerm(""),
        }}>
            {props.children}

        {isSearchModalOpen && (
            <SearchModal />
        )}
        </SearchContext.Provider>
    )
}

export {SearchProvider}
export default SearchProvider