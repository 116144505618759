"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Schema = void 0;

var _portalModel = require("portal-model");

var _Model = require("./../Companies/Model");

var _Model2 = require("./../Invoices/Model");

var _Model3 = require("./../Opportunity/Model");

const Schema = {
  'id': (0, _portalModel.id)(),
  title: (0, _portalModel.attr)(),
  company: (0, _portalModel.belongsTo)(_Model.Company)
};
exports.Schema = Schema;
var _default = Schema;
exports.default = _default;