"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.noteSlice = exports.default = void 0;

var _portalRedux = require("portal-redux");

// selector helping
// firebase slice
const noteSlice = new _portalRedux.FirebaseSlice({
  table: "notes",
  name: 'notes'
});
exports.noteSlice = noteSlice;
var _default = noteSlice;
exports.default = _default;