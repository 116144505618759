"use strict";

require("core-js/modules/web.dom-collections.iterator.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Retainer = void 0;

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var yup = _interopRequireWildcard(require("yup"));

var _moment = _interopRequireDefault(require("moment"));

var _portalConstants = require("portal-constants");

var _Slice = _interopRequireDefault(require("./Slice.js"));

var _portalModel = require("portal-model");

var _portalRedux = require("portal-redux");

var _Model = _interopRequireDefault(require("./../Companies/Model"));

var _Model2 = _interopRequireDefault(require("../Projects/Model.js"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//@ts-nocheck
//external
// constants
// data
class Retainer extends _portalModel.Model {
  // these make typescript stop complaining
  // TODO : Make Model parent typescript and use these better
  get allowed_rollover() {
    //@ts-ignore
    return this.data.allowed_rollover / 100;
  }

  // I'm overriding the constructor at the moment 
  // to stop the getters being get from the data array
  constructor(args) {
    super(args, false);
    this.cached = {};
  }

  static loadSchema() {
    this.schema = require("./Schema.js").Schema;
  }

  get history() {
    console.log("history", this);
    /*
    //@ts-ignore
    if(_.isUndefined(this.data.retainer_periods)){
    		let period = new RetainerPeriod({
    		"retainer" : this.getID(),
    		"period" : this.data.period,
    		"base_rate": this.data.base_rate,
    		"base_cost": this.data.base_cost,
    		"overage_rate": this.data.overage_rate,
    		"hours": this.data.hours,
    		"allowed_rollover": this.data.allowed_rollover
    	})
    		await period.save();
    	
    		//@ts-ignore
    	this.data.retainer_periods = {}
    	//@ts-ignore
    	this.data.retainer_periods[period.getID()] = true
    		await this.update()
    }
    */

    return this.cacheCall("history", () => {
      //@ts-ignore
      let history = new RetainerPeriodCollection(this.getRelated("retainer_periods"));
      return history.orderByDateNewestFirst();
    }, []);
  }

  getCurrentPeriod() {
    return this.history.getNewest();
  }

  create() {
    var _this = this;

    return (0, _asyncToGenerator2.default)(function* () {
      let data = _this.getDataForSave();

      data.created_on = (0, _moment.default)();
      data.created_on = data.created_on.format();

      if (!data.project) {
        let project = _Model2.default.init();

        project.setMany({
          "title": data.title,
          "company": data.company,
          "status": data.status,
          "type": _portalConstants.ProjectTypesTaxonomy.RETAINER
        });
        yield project.save();
        data.project = project.getID();
      }

      let created = yield _portalRedux.store.dispatch(_this.slice.addItem(data));
      _this.id = created.id;

      _this.projects.set("retainer", _this.id);

      yield _this.projects.save();
      return created;
    })();
  }

  update() {
    var _this2 = this;

    return (0, _asyncToGenerator2.default)(function* () {
      let data = { ..._this2.getDataForSave()
      };
      data.modified_on = (0, _moment.default)();
      data.modified_on = data.modified_on.format();
      console.log("for Update", data);
      let res = yield _portalRedux.store.dispatch(_this2.slice.updateItem(data));
      yield _this2.handleCascadingSaves();
      return res;
    })();
  }

}

exports.Retainer = Retainer;
(0, _defineProperty2.default)(Retainer, "schema", void 0);
(0, _defineProperty2.default)(Retainer, "form", {
  title: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'title',
      name: 'title',
      label: "Name",
      placeholder: "Acme Inc.",
      helperText: "project name",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  company: {
    type: "relationship",
    form: true,
    field: {
      type: "autocomplete",
      id: 'company',
      name: 'company',
      label: "Company",
      placeholder: "Acme Inc.",
      helperText: "company name",
      order: 1,
      model: _Model.default,
      options: () => {
        return _Model.default.getAll();
      },
      createOptions: {
        type: _portalConstants.CompanyLifeCycleStages[0].value
      }
    }
  },
  period: {
    type: "string",
    form: true,
    field: {
      type: "select",
      id: 'period',
      name: 'period',
      label: "Period",
      helperText: "Billing Period",
      validation: yup.string().required(),
      order: 1,
      initialValue: _portalConstants.RetainerPeriods[1].value,
      options: _portalConstants.RetainerPeriods
    }
  },
  hours: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'hours',
      name: 'hours',
      label: "Hours",
      placeholder: "10",
      helperText: "Hours Purchased Each Period",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  base_rate: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'base_rate',
      name: 'base_rate',
      label: "Base Hourly Rate",
      placeholder: "£85",
      helperText: "Cost per Extra Hour Purchased",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  overage_rate: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'overage_rate',
      name: 'overage_rate',
      label: "Overage Rate",
      placeholder: "£85",
      helperText: "Cost per Extra Hour Used",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  base_cost: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'base_cost',
      name: 'base_cost',
      label: "Base Per Period",
      placeholder: "£1,000.",
      helperText: "Cost per Period",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  allowed_rollover: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'allowed_rollover',
      name: 'allowed_rollover',
      label: "Allowed Rollover",
      placeholder: "50",
      helperText: "What % of purchased hours can be rolled over ",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  status: {
    type: "string",
    form: true,
    field: {
      type: "select",
      id: 'status',
      name: 'status',
      label: "Status",
      helperText: "Project Status",
      validation: yup.string().required(),
      order: 1,
      initialValue: "",
      options: _portalConstants.ProjectStatusesTaxonomy.collection,
      initialValue: _portalConstants.ProjectStatusesTaxonomy.IN_PROGRESS
    }
  }
});
(0, _defineProperty2.default)(Retainer, "slice", _Slice.default);

class RetainerPeriodCollection {
  constructor(items) {
    (0, _defineProperty2.default)(this, "items", []);
    this.items = items;

    if (_.isUndefined(this.items[0])) {
      return;
    }

    if (_.isUndefined(this.items[0].iteration)) {
      this._setIterationValues();
    }

    if (_.isUndefined(this.items[0].links)) {
      this._setLinks();
    }
  }

  toArray() {
    return this.items;
  }

  orderByDateNewestFirst() {
    return new this.constructor(this.items.sort((a, b) => {
      return Date.parse(b.period_start) - Date.parse(a.period_start);
    }));
  }

  orderByDateOrdestFirst() {
    return new this.constructor(this.items.sort((a, b) => {
      return Date.parse(a.period_start) - Date.parse(b.period_start);
    }));
  }

  _setIterationValues() {
    this.items.sort((a, b) => {
      return Date.parse(a.period_start) - Date.parse(b.period_start);
    }).forEach((period, index) => {
      period.iteration = index + 1;
    });
  }

  _setLinks() {
    this.items.sort((a, b) => {
      return Date.parse(a.period_start) - Date.parse(b.period_start);
    }).forEach((period, index) => {
      period.links = {};
      period.links.isFirst = index === 0;
      period.links.isLast = index === this.items.length - 1;

      if (!period.links.isLast) {
        period.links.next = this.items[index + 1];
      }

      if (!period.links.isFirst) {
        period.links.previous = this.items[index - 1];
      }
    });
  }

  getNewest() {
    return this.items.find(item => {
      return item.links.isLast;
    });
  }

}

var _default = Retainer;
exports.default = _default;