import {Typography} from "@mui/material"

import Layout from "./Layout"


const RequestPassword = () => {

    return (
        <Layout>
            <Typography>
                Lost [password]
            </Typography>
        </Layout>        
    )
}

export {RequestPassword}
export default RequestPassword