//@ts-nocheck
import {useState} from 'react';

import {Invoice as Model} from "portal-datatypes"

import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import {Button} from "@mui/material"

import { Archive, Frame } from "../../Components";
import {Invoices as Columns} from "../../Components/DataGrids/Columns/Invoices"

const Invoices = ( props : any ) => {

	const [visibilityModel, setVisibilityModel] = useState(props.columnVisibilityModel ?? {
		[GRID_CHECKBOX_SELECTION_COL_DEF.field] : true,
		title: true,
		actions: true,
	})

	const query = {}

	return (
		<Frame>
			<Archive
				title = "Invoices"
				controls = { (
					<Button
						variant="contained"
						onClick = { () => Model.attachToCompanies() }
					>
						Attach Imported Companies
					</Button>
        		)}
				Model = { Model }
				query = { query }
				gridProps = {{
					columns : (props.columns ?? Columns),
					columnVisibilityModel : visibilityModel,
					onColumnVisibilityModelChange : (newModel) => {
						setVisibilityModel(newModel)
					},
					disableColumnMenu : false,
				}}
			/>
		</Frame>
	)
}

export default Invoices
export {Invoices}