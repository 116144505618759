import {Route as RouteType} from "./../Core/types"
import {Contacts as ListView} from '../Views/Contacts/Contacts'
import {Contact as SingleView} from '../Views/Contacts/Contact'

const Routes: Array<RouteType> = [
    {
        "path" : "/contacts/:guid",
        "view" : SingleView
    },
    {
        "path" : "/contacts",
        "view" : ListView
    }  
]

const Contacts = {
    routes : Routes
} 

export {Contacts}