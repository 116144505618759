import {Button as MUIButton} from "@mui/material";
import {styled, ThemeProvider } from '@mui/material/styles';
import {theme} from "./Theme"


const StyledButton = styled(MUIButton)(({ theme }) => ({
    fontFamily : theme.typography.families.input,
    textTransform: "capitalize",
    letterSpacing: '-1px',
    paddingTop : 15,
    paddingBottom : 13,
}));

export default function Button(props) {
    return (
        <ThemeProvider theme={theme}>
            <StyledButton 
                variant="contained" 
                disableElevation
                size="large"
                {...props} 
            />
        </ThemeProvider>
    )
}

export {Button}