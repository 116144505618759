import { isNil } from "lodash"

export default function withModel(WrappedComponent, Model = null){

    return (props) => {
		if(isNil(Model) && isNil(props.Model)){
			return <>No Model Specificied</>
		}
		
        return (<WrappedComponent 
            {...props}
            Model = {Model ?? props.Model}
        />)

    }
}

export {withModel}