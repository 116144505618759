import { Link as RouterLink } from "react-router-dom";
import { Link as MUILink } from "@mui/material";
import { EntityIcon } from "../../EntityIcon";
import { isEmpty } from "lodash";

const IconLink = (props) => {
    
    const {model} = props

    if(isEmpty(model)){
        return null
    }

    return (
            <>
            { model.hasIcon() && (
                <MUILink component={RouterLink} to={ model.link() } underline="none" >
                    <EntityIcon model={model} size={props.size ?? "small"} />
                </MUILink>
            ) }
                <MUILink component={RouterLink} to={ model.link() } > 
                    {model.title}
                </MUILink>
            </>
    )
}

export default IconLink
export {IconLink}