
import {ListItemText, ListItem, ListItemAvatar, Avatar,  IconButton, Stack, Typography, AvatarGroup} from "@mui/material"

import {Accordion} from "./../../Accordion"
import {Link} from "./../../Link.jsx"
import {EntityIcon} from "./../../EntityIcon.jsx"


import { Contact} from "portal-datatypes"
import { AutoCompleteFromModel } from "./../../Fields/AutoComplete"
import {Delete} from '@mui/icons-material';

const ContactTease = (props) => {
    return (
        <ListItem
            secondaryAction={
                <IconButton 
                    edge="end" 
                    aria-label="delete"
                    onClick = { () => { props.onDelete(props.model.id) } }
                >
                    <Delete />
                </IconButton>
            }
        >
            <ListItemAvatar>
                <Link to = {props.model.link()} underline="none">
                    <Avatar alt={props.model.title} src={props.model.hasIcon() ? props.model.getIconSrc() : null } >
                        {props.model.initials()}
                    </Avatar>
                </Link>
            </ListItemAvatar>
            <ListItemText>
                <Link to = {props.model.link()}>
                    {props.model.title}
                </Link>
            </ListItemText>
        </ListItem>
    )
} 

const ContactAccordionSummary = (props) => {
    return (<Stack
        direction = "row"
        alignItems={"center"}
        spacing = {1}
    >
            <Typography fontWeight={700}>Contacts:</Typography>
            <AvatarGroup max={3}>
                {props.model.contacts.map( (c) => (
                    <EntityIcon model={c} />
                ))}
            </AvatarGroup>
        </Stack>)
}

const ContactsAccordion = (props) => {

    return (
        <Accordion
            id="accordion-company"
            summary = {<ContactAccordionSummary model = {props.model} />}
        >

                        {props.model.contacts.map( (contact, index) => (
                            <ContactTease model = {contact} onDelete = { props.model.removeContact } key={index}/>
                         ) ) }

                        <AutoCompleteFromModel
                            name  = "opp-contact-select"
                            model = {Contact}
                            value = {props.model.contacts.map(c => c.id)}
                            label = "Contacts"
                            multiple = {true}
                            setValue = {(name, value) => {
                                console.log("setValue", value)
                                props.model.set({"contacts" : value})
                                props.model.save()
                            }}
                        />
                    </Accordion>
    )
}

export {ContactsAccordion}
export default ContactsAccordion