import {Loader} from "./../Components/Loader"
import {Frame} from "./../Components/Frame"

import {isEmpty} from "lodash"
import { useSelector } from "react-redux"

export default function withLoaded(WrappedComponent){
    
    return (props) => {
  
        const guid = props.match.params.guid
        const model = useSelector(props.Model.get(props.match.params.guid))

        if(isEmpty(model)){
            return (<Frame><Loader /></Frame>)
        }

        return (<WrappedComponent 
            {...props}
            guid = {guid}
            model = {model}  
        />)  

    }
}

export {withLoaded}