import {LoginStates} from "portal-constants"
import {Loader} from './../../Views/Loader'
import {useSelector} from 'react-redux'
//import {useCreateDefaultUser} from "./Hooks/CreateDefaultUser"

const AuthProvider = (props) => {
	
	//useCreateDefaultUser();

	let auth = useSelector( (state) => state.auth )
	let loginState = auth.auth.status

    if(loginState === LoginStates.LOGGING_IN){
        return (<Loader />)
    }

    if(loginState === LoginStates.UNKNOWN){
        return (<Loader />)
    }
    
    return props.children
}

export default AuthProvider
export {AuthProvider}