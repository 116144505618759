"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Schema = void 0;

var _portalModel = require("portal-model");

const Schema = {
  'id': (0, _portalModel.id)(),
  'spent_date': (0, _portalModel.attr)(),
  'hours': (0, _portalModel.attr)()
};
exports.Schema = Schema;
var _default = Schema;
exports.default = _default;