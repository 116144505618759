
import { GridRowParams } from "@mui/x-data-grid-pro";
import {Currency as CurrencyColumnType} from "../ColumnTypes/Currency"

import {Link as LinkColumnType} from "./../ColumnTypes/Link"
import {EditCompanyDrawer} from "./../../Forms/EditCompany"
import ActionSet from "../ActionSets/ActionSet";

const Prospects = [
    { 
      field: 'id', 
      headerName: 'ID', 
      width: 70,
      hide: true
    }, 
    { 
      field: 'title', 
      headerName: 'Name', 
      width: 170,
      ...LinkColumnType
        
    },

    { 
        field: 'value', 
        headerName: 'Value', 
        width: 170,
        
        ...CurrencyColumnType
      },
  
      {
        field: 'actions', 
        headerName: 'Actions', 
        type: "actions", 
        width: 170,
        getActions: (params: GridRowParams) => [(<ActionSet 
          {...params} 
          editForm={EditCompanyDrawer} 
        />)]
    }
  ];
  
  

  export default Prospects
  export {Prospects}