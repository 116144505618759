"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Schema = void 0;

var _portalModel = require("portal-model");

var _Model = require("./../Model");

const Schema = {
  'id': (0, _portalModel.id)(),
  'project': (0, _portalModel.attr)(),
  retainer: (0, _portalModel.belongsTo)(_Model.Retainer),
  status: (0, _portalModel.attr)(),
  type: (0, _portalModel.attr)(),
  'period': (0, _portalModel.attr)(),
  'hours': (0, _portalModel.attr)(),
  'overage_rate': (0, _portalModel.attr)(),
  'base_rate': (0, _portalModel.attr)(),
  'base_cost': (0, _portalModel.attr)(),
  'allowed_rollover': (0, _portalModel.attr)(),
  'period_start': (0, _portalModel.attr)()
};
exports.Schema = Schema;
var _default = Schema;
exports.default = _default;