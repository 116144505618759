"use strict";

require("core-js/modules/web.dom-collections.iterator.js");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Company = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = require("lodash");

var yup = _interopRequireWildcard(require("yup"));

var _portalModel = require("portal-model");

var _portalConstants = require("portal-constants");

var _Slice = _interopRequireDefault(require("./Slice.js"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//@ts-nocheck 
//external
// data
class Company extends _portalModel.Model {
  // these make typescript stop complaining
  // TODO : Make Model parent typescript and use these better
  // I'm overriding the constructor at the moment 
  // to stop the getters being get from the data array
  constructor(args) {
    super(args, false);
  }

  static loadSchema() {
    this.schema = require("./Schema.js").Schema;
  }

  hasParent() {
    if (!(0, _lodash.isUndefined)(this.parents) && this.parents && Object.keys(this.parents).length > 0) {
      return true;
    }

    return false;
  }

  get static() {
    return this.constructor;
  }

  get children() {
    let children = [];
    let ids = this.data.children;

    if ((0, _lodash.isUndefined)(ids)) {
      return children;
    }

    let keys = Object.keys(ids);
    keys.forEach(key => {
      children.push(this.static.get(key)());
    });
    return children;
  }

  get parents() {
    let parents = [];
    let ids = this.data.parent;

    if ((0, _lodash.isUndefined)(ids)) {
      return parents;
    }

    let keys = Object.keys(ids);
    keys.forEach(key => {
      parents.push(this.static.get(key)());
    });
    return parents;
  }

  get value() {
    return this.cacheCall("value", () => {
      let value = 0;
      this.invoices.forEach(invoice => {
        value = value + parseInt(invoice.value);
      });
      let invoiced = this.invoiced;
      let paid = this.paid;
      return Math.max(value, invoiced, paid);
    }, 0);
  }

  get invoiced() {
    return this.cacheCall("invoiced", () => {
      let value = 0;
      this.invoices.forEach(invoice => {
        value = value + parseInt(invoice.value);
      });
      return value;
    });
  }

  get paid() {
    return this.cacheCall("paid", () => {
      let value = 0;
      this.invoices.filter(invoice => {
        return invoice.status === "Paid" || invoice.status === "Part written-off";
      }).forEach(invoice => {
        value = value + parseInt(invoice.data.paid_value);
      });
      return value;
    });
  }

  get remaining() {
    return this.cacheCall("remaining", () => {
      let written_off = 0;
      this.invoices.filter(invoice => {
        return invoice.status === "Written-off";
      }).forEach(invoice => {
        written_off = written_off + parseInt(invoice.value);
      });
      return Math.max(this.invoiced - this.paid - written_off, 0);
    });
  }

  get to_invoice() {
    return this.value - this.invoiced;
  }

  get due() {
    return this.cacheCall("due", () => {
      let due = 0;
      this.invoices.filter(invoice => {
        return invoice.status === "Overdue" || invoice.status === "Open";
      }).forEach(invoice => {
        due = due + invoice.due;
      });
      return Math.max(due, 0);
    });
  }
  /*
  get type(){
  
  	if(isUndefined(this.data.type)){
  		return "-"
  	}
  		let type = CompanyLifeCycleStages.find( (t) => {
  		return (t.value === this.data.type)
  	} )
  		if(!isUndefined(type)){
  		return type.title
  	} else {
  		return this.data.type
  	}
  }
  */


  hasIcon() {
    return !(0, _lodash.isNil)(this.data.domain) && this.data.domain !== "";
  }

  getIconSrc() {
    if (!this.hasIcon()) {
      return false;
    }

    return "//logo.clearbit.com/" + this.data.domain;
  }

}

exports.Company = Company;
(0, _defineProperty2.default)(Company, "schema", void 0);
(0, _defineProperty2.default)(Company, "urlPath", "companies");
(0, _defineProperty2.default)(Company, "label", "Company");
(0, _defineProperty2.default)(Company, "form", {
  title: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'title',
      name: 'title',
      label: "Name",
      placeholder: "Acme Inc.",
      helperText: "company name",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  type: {
    type: "string",
    form: true,
    field: {
      type: "select",
      id: 'type',
      name: 'type',
      label: "Company Type",
      helperText: "Company Type",
      validation: yup.array().min(1).required(),
      order: 1,
      initialValue: "",
      options: _portalConstants.CompanyLifeCycleStages,
      multiple: true
    }
  },
  domain: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'domain',
      name: 'domain',
      label: "Primary Domain",
      placeholder: "Acme.com",
      helperText: "Email Domaon",
      order: 1,
      initialValue: ""
    }
  }
});
(0, _defineProperty2.default)(Company, "FormEditMultiple", {
  type: Company.form.type
});
(0, _defineProperty2.default)(Company, "slice", _Slice.default);
var _default = Company;
exports.default = _default;