//@ts-nocheck
import React from "react"
import {HZNForm} from "../Form/Form"

import {Company} from "portal-datatypes" 
import { withFormDrawer } from "../Form/Drawer"
import { isObject, isEmpty } from "lodash"

const EditCompany = (props : any) => {

	let {
		items = [],
		item = null,
		...restProps
	} = props
	
	if(!isEmpty(items)){
		if(items.length > 1){
			console.error("Too many Items passed to the edit form. Use Either the `item` prop with one model or the `items` prop as an array with one Model")
		} else {
			item = items[0]
		}
	}
	
	

	if(!isObject(item)){
		item = Company.get(item)()
	}

	

    return(
      <HZNForm
        Model = {Company}
        fields = {Company.getFormFields()}
        title = "Edit Company"
        buttonText = "Update Company"
        {...restProps}
		item = {item}
        action = "edit"
        successMessage = "Company Updated" 
        showAddAnotherButton = {false}
      />
    )
}

const EditCompanyDrawer = withFormDrawer(EditCompany)

export default EditCompany
export {EditCompany, EditCompanyDrawer}