import {UserMenuTrigger} from "../../Menu"
import {Menu, MenuItem} from "@mui/material"
import {useState} from "react"

import {app} from "./../../../Core/bootstrap"

const UserMenu = () => {

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
	  setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
	  setAnchorEl(null);
	};


    return(
		<>
			<UserMenuTrigger 
				 onClick={handleClick}
			/>
			<Menu 
				label = "User"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			
			>
				<MenuItem onClick = {
					() => {
						console.log("onClick")
						app.Auth.logout()
					}
				}>
					Logout
				</MenuItem>
			</Menu>
		</>
    )
}

export default UserMenu
export {UserMenu}