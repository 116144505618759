import {Drawer, Box} from '@mui/material';


const FormDrawer = (props) => {

	// styles for just this components
	

	// pull things we need from props
	let { onClose } = props

	return (
		<Drawer 
            anchor={"right"} 
            open={props.isOpen} 
            onClose={onClose}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
			<Box sx={{
				 padding: "3rem",
				 width : "600px"
			}} >
				{props.children}
			</Box>
		</Drawer>

	)

}

const withFormDrawer = ( WrappedComponent ) => {
	return (props) => (
		<FormDrawer
			isOpen = {props.isOpen}
			onClose = {props.onClose}
		>
			<WrappedComponent {...props}/>
		</FormDrawer>
	)
}

export {FormDrawer, withFormDrawer}
export default FormDrawer