"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FrontEndServices = void 0;

var _config = require("./freeagent/config");

var _config2 = require("./harvest/config");

var _config3 = require("./xero/config");

var FrontEndServices = {
  "freeagent": _config.Config,
  "harvest": _config2.Config,
  "xero": _config3.Config
};
exports.FrontEndServices = FrontEndServices;