import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { isUndefined } from 'lodash';

import {Typography, MenuList, MenuItem, ListItemButton, Stack} from '@mui/material';

import {Link} from "./../Link"

import { SearchContext, SearchTag } from "."

// Recursively builds JSX output adding `<mark>` tags around matches
const highlight = (value, indices = [], i = 1) => {

    const pair = indices[indices.length - i];

    return !pair ? value : (
      <>
        {highlight(value.substring(0, pair[0]), indices, i+1)}
        <strong>{value.substring(pair[0], pair[1]+1)}</strong>
        {value.substring(pair[1]+1)}
      </>
    );
};

const SearchResults = (props) => {

    const search = useContext(SearchContext)
    let history = useHistory();

    const {results, hasFocus, moveFocusToInput} = props
    
    return (
        <MenuList
            autoFocusItem = {hasFocus}
            variant="menu"
            disableListWrap
           
        >
            {results.map( (result, index) => {
                
                let showCompany = (
                    (result.item.constructor.label === "Project") &&
                    (!isUndefined(result.item.companies[0]))
                )
                
                return (
                <MenuItem 
                    key = {index}
                    disablePadding
                    disableGutters
                    divider={true} 
                    onKeyUp = { (event) => {
                        
                        if(event.key !== "ArrowUp"){
                            return
                        }

                        if(index === 0){
                            moveFocusToInput()
                        }
                    }}

                    onClick = { () => {
                        search.closeSearchModel()
                        history.push(result.item.link())
                    }}
                    
                >
                    <ListItemButton component={Link} to={result.item.link()} onClick={ () => {
                        search.closeSearchModel()
                    }}>
                        <Stack direction="row" justifyContent="space-between" sx={{width:"100%"}}>
                            <Stack direction="row" spacing={1} alignItems={"center"}>
                                
                                <Typography>
                                    {highlight(result.item.title, result.matches[0].indices)}
                                </Typography>

                                { showCompany && (
                                    <Typography variant="overline">({result.item?.companies[0]?.title ?? "-"})</Typography>
                                )}
                            </Stack>
                            <SearchTag size="small" label={ result.item.constructor.label } />
                        </Stack>
                    </ListItemButton>
                </MenuItem>
             )})} 
        </MenuList>
    )
}

export {SearchResults}
export default SearchResults