import { useContext, useState} from 'react';
import { useSelector } from 'react-redux';

import {Dialog, DialogContent, DialogTitle, Typography} from '@mui/material';

import SearchSelector from '../../Core/Search/SearchSelector';
import { SearchContext, SearchInput, SearchResults } from "."

const SearchModal = (props) => {

    const search = useContext(SearchContext)
    const results = useSelector(SearchSelector(search.searchTerm))
    const showResults = (search.searchTerm ?? false)
    const hasResults = (results.length > 0 )
    const [focusOnResults, setFousOnResults] = useState(false)

    const moveFocusToResults = () => {
        setFousOnResults(true)
    }

    const moveFocusToInput = () => {
        console.log("move to inpout")
        setFousOnResults(false)
    }

    return (
        <Dialog 
            onClose={search.closeSearchModel} 
            open={search.isSearchModalOpen} 
            fullWidth={true}
            maxWidth={"sm"}
            scroll="paper"
            PaperProps={{ sx: { 
                maxHeight: "500px",
                position: "absolute",
                top:"10%"
            } }}
        >
            <DialogTitle>
                <SearchInput 
                    moveFocusToResults = {moveFocusToResults}
                    hasFocus = {!focusOnResults}
                />
            </DialogTitle>
        {showResults && (
            <DialogContent
                dividers = {true}
                sx = {{
                    paddingTop : 0
                }}
            >

                {!hasResults && (
                    <Typography>Nothing Found</Typography>
                )}

                {hasResults && (
                    <SearchResults 
                        results = {results}  
                        hasFocus = {focusOnResults}
                        moveFocusToInput = {moveFocusToInput}
                    />
                )}
            </DialogContent>
        )}
        </Dialog>
    )
}

export {SearchModal}
export default SearchModal