//@ts-nocheck

// react
import { useState } from 'react';

// 3rd party
import { useSelector } from 'react-redux'

// data§
import { ProcessStates, OpportunityStages } from 'portal-constants';
import {Opportunity as Model} from "portal-datatypes"

// components
import PortalToolbar from "../../Components/DataGrids/Toolbar";
import Archive from "../../Components/Archive/Archive";
import { Opportunities as OpportunityColumns } from "../../Components/DataGrids/Columns/Opportunities"
import HZNDataGrid from "../../Components/DataGrids/HZNDataGrid";

import { AddItemControl } from '../../Components/Form/DrawerControl';
import { AddOpportunityDrawer } from "./../../Components/Forms/AddOpportunity"
import { Kanban } from "./../../Components/Kanban"
import { Stack } from '@mui/material';

import { OpportunityFilters } from '../../Components/Opportunities';





let Pipeline = ( props : any ) => {

    const VIEW_GRID = 0
    const VIEW_COLS = 1
    
    let [selectedView] = useState(VIEW_COLS)
    let [filters, setFilters] = useState({"status" : [0]})
    let opps =  useSelector(Model.getAllQueried(filters)).toArray()

    const loaded = useSelector( (state) => {
      return (state.app.populating.state === ProcessStates.FINISHED)
    })
    
    if(!loaded){
      return(<>Loading...</>) 
    }

    const updateStage = (item, stage) => {
        item.set({"stage" : stage})
        item.save()
    }

    const updateStatus = (item, status) => {
        item.set({"status" : status})
        item.save()
    }
    
    return (

      <Archive
        title = "Pipeline"
        style = {
            {overflow:"hidden"}
        }
    

        controls = {(
            <Stack
                spacing={2}
                direction="row"
            >
                <AddItemControl 
                    label = "Add Opportunity"
                    form = {AddOpportunityDrawer}
                />

            </Stack>
          )}
        >
        
        <OpportunityFilters
            filters = {filters}
            setFilters = {setFilters}
        />
                    
        { selectedView === VIEW_GRID && (
          <HZNDataGrid
              //@ts-ignore
              rows = {opps}
              columns={OpportunityColumns}
              components={{
                Toolbar: PortalToolbar
              }}
              disableColumnMenu={true}
          />
        )}

        { selectedView === VIEW_COLS && (
            <Kanban 
                columns = {OpportunityStages}
                cards = {opps}
                updateStage = {updateStage}
                updateStatus = {updateStatus}
            />
        )}

      </Archive>
    )
}


function withLoaded (WrappedComponent) {
    return WrappedComponent
}

Pipeline = withLoaded(Pipeline)


export default Pipeline
export {Pipeline}