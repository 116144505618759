//@ts-nocheck
import { DataGridPro } from "@mui/x-data-grid-pro"
import {isEmpty} from "lodash"
import { AddItemControl } from "../Form/DrawerControl"
import { AddRetainerPeriodDrawer } from "../Forms/AddRetainerPeriod"
import { Section } from "../Section"
import { Retainers as RetainerColumns } from "./../DataGrids/Columns/Retainer"

const RetainerHistory = (props : any) => {

   if(isEmpty(props.model)){
    return null
   }

   let retainerIdForCreations = {}
   retainerIdForCreations[props.model.getID()] = true
    return(
      

        <Section
            title = "History"
            controls = {(
                <AddItemControl
                    label = "Add Retainer Period"
                    form = {AddRetainerPeriodDrawer}
                    formProps = {{
                        initialValues : {
                            retainer: retainerIdForCreations
                        }
                    }}
                />
            )}
        >
            <DataGridPro
                //@ts-ignore
                rows={props.model.history.toArray()}
                columns={RetainerColumns}
                pageSize={25}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                autoHeight
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
            />
        </Section>
    )
}

export default RetainerHistory
export {RetainerHistory}