import { Link } from "@mui/material";
import { GridValueGetterParams, GridRowParams } from "@mui/x-data-grid-pro";
import { Link as RouterLink } from "react-router-dom";
import {Currency as CurrencyColumnType} from "../ColumnTypes/Currency";
import {EditOpportunityDrawer} from "./../../Forms/EditOpportunity"
import ActionSet from "../ActionSets/ActionSet";
import {RelatedLink as RelatedLinkColumnType} from "./../ColumnTypes/RelatedLink"
import {DateColumnType} from "./../ColumnTypes/Date"
import {Picked as PickedColumnType} from "./../ColumnTypes/Picked"



const Opportunities = [
    { 
      field: 'id', 
      headerName: 'ID', 
      width: 70,
      hide: true
    }, 
    { 
      field: 'title', 
      headerName: 'Name', 
      width: 270,
      renderCell: (params: GridValueGetterParams) => {
        return (<Link component={RouterLink} to={"/opportunities/" + params.row.id}>{params.row.title}</Link>)
      }
        
    },{
        field: 'companies', 
        headerName: 'Company', 
        width: 250,
        ...RelatedLinkColumnType
    },
    {
        field: 'status', 
        headerName: 'Status', 
        width: 150,
        ...PickedColumnType
    },
    {
        field: 'stage', 
        headerName: 'Stage', 
        width: 200,
        ...PickedColumnType
    },
    {
      field: 'type', 
      headerName: 'Type', 
      width: 170,
      ...PickedColumnType
    },

    { 
        field: 'value', 
        headerName: 'Value', 
        width: 170,
        ...CurrencyColumnType
    },

    { 
        field: 'date_created', 
        headerName: 'Inbound Date', 
        width: 170,
        ...DateColumnType     
    },

    { 
        field: 'date_closed', 
        headerName: 'Close Date', 
        width: 170,
        ...DateColumnType     
    },

    { 
        field: 'date_expected_close', 
        headerName: 'Expected Close Date', 
        width: 170,
        ...DateColumnType     
    },

	{ 
        field: 'users', 
        headerName: 'Assigned To', 
        width: 170,
        ...RelatedLinkColumnType
    },

    {
        field: 'actions', 
        headerName: 'Actions', 
        type: "actions", 
        width: 170,
        getActions: (params: GridRowParams) => [(<ActionSet 
          {...params} 
          editForm={EditOpportunityDrawer} 
        />)]
    }
      /*
      { 
        field: 'Invoiced', 
        headerName: 'Invoiced', 
        width: 170,
        ...CurrencyColumnType
      },
      { 
        field: 'Received', 
        headerName: 'Received', 
        width: 170,
        valueGetter:(params: GridValueGetterParams) => {
          return params.row.paid
        },
        ...CurrencyColumnType
      },
      { 
        field: 'Received', 
        headerName: 'Received', 
        width: 170,
        valueGetter:(params: GridValueGetterParams) => {
          return params.row.paid
        },
        ...CurrencyColumnType
      },
      { 
        field: 'Unpaid / Outstanding', 
        headerName: 'Unpaid', 
        width: 170,
        valueGetter:(params: GridValueGetterParams) => {
          return params.row.remaining
        },
        ...CurrencyColumnType
      },
      { 
        field: 'to_invoice', 
        headerName: 'To Invoice', 
        width: 170,
        valueGetter:(params: GridValueGetterParams) => {
          return params.row.to_invoice
        },
        ...CurrencyColumnType
      },
      {
        field: 'actions', 
        headerName: 'Actions', 
        type: "actions", 
        width: 170,
        getActions: (params: GridRowParams) => [(<ActionSet 
          {...params} 
          editForm={EditCompanyDrawer} 
        />)]
    }
    */
  ];
  
  

  export default Opportunities
  export {Opportunities}