import { FormControl, Autocomplete as MuiAutoComplete, TextField } from "@mui/material"
import { isArray, isUndefined, isEmpty, isObject, isString } from "lodash"
import {createFilterOptions } from '@mui/material/Autocomplete';
import { useSelector } from "react-redux";
import { useState } from "react";

const filter = createFilterOptions();

const AutoCompleteFromModel = (props) => {

    const {model, ...restProps} = props
    const options = useSelector(model.getAll()).toSelectObjects()

    const createNew = async (data) => {
        let item = model.init(data)
		item.setMany(props.createOptions ?? {})
        await item.save()
        return item
    }

    return (
        <AutoComplete 
            { ...restProps }
            options = {options}
            createNew = {createNew}
        />
    ) 
}



const AutoComplete = (props) => {
	/*
	const [inputValue, setInputValue] = useState("")

	const handleInputChange = (event) => {
		setInputValue(event.target.value)
	}
	*/
	
    // props
    let {
        options, 
        label,
        id, 
        name,
        touched,
        errors,
		error,
        value = null,
        multiple = false,
        freeSolo = false,
    } = props

    let {
        createNew = false,
		createOptions = {},
        setValue,
		helperText,
        ...restProps
    } = props


    // calculate value
    if((multiple) && (isUndefined(value))){
        value = []
    } else if((multiple) && (!isArray(value))){
        if(value === ""){
            value = []
        } else {
            value = [value]
        }
    } else if( (!multiple) && (isArray(value)) ) {
        value  = value[0]
    } else if(isUndefined(value)) {
        value = null
    } else {
        //value = value
    }
	
    const onChange = async (event, value) => {

        let newValue 
        if(multiple){

            newValue = value.map( async (v) => {

                if(isString(v)){ return v }

                if((isObject(v)) && (createNew) && (!isUndefined(v.inputValue))){ 
                    let item = await createNew({title : v.inputValue})
                    return item.getID();
                }
                if(isObject(v)){ return v.id }
            })

            await Promise.all(newValue).then((r) => {
                newValue = r
            })
            
        } else {

            if(!isUndefined(value.id)){
                newValue = value.id
            } else if(
                (!isUndefined(value.inputValue)) &&
                (createNew)
            ) {
				console.log("doing Create New")
                let item = await createNew({title : value?.inputValue})
                newValue = item.getID()
				console.log("did Create New")
            }
        
        }

        props.setValue(name, newValue)


        /*
        if(!value){
            newValue = null
        } else if( isUndefined(value?.id) && (createNew)){
            let item = await createNew({title : value?.inputValue})
            newValue = item.getID()
        } else {
            newValue = value.id
        }

        props.setValue(newValue, name)
        */

    }

    /*
    const renderValue = (selected) => {
        if(isNil(selected)){
            return null
        }

        if(multiple){

            if(isEmpty(selected)){
                return null
            }
            
            let newValues = selected.map( (select) => {
                return props.options[select].title
            })

            return newValues.join(', ')
        
            
        } else {
            return props.options[selected].title
        }

    }   
    */

    return (
        <FormControl fullWidth margin="normal" >

            <MuiAutoComplete

                id={ id }
				multiple = { multiple }
				options = {options ?? []}

                value={ value }
                label={ label }
                freeSolo = {freeSolo}
				//inputValue = {inputValue}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
				renderInput={(params) => {
					return ( <TextField {...params} label={label} />)
				}}
                error={touched && Boolean(errors)}

                onChange={ onChange }
                //renderValue = {renderValue}
                isOptionEqualToValue = { (option, value) => {

                    if(isUndefined(value)){
                        return false
                    }

                    if(isArray(value)){
                        return value.includes(option.id)
                    }
                    if(isObject(value)){
                        return (value.id === option.id)
                    }

                    return (value === option.id)
                }}

                
      

                getOptionLabel={ (option) => { 

                    if (typeof option === 'string') {
                        return options.find( (o) => (o.id === option) ).title;
                    } else if (isArray(option)){
                        if(isEmpty(option)){
                            return ""
                        }
						return option[0].title

                    } else if(isObject(option)){
                        return option.title
                    }

                    return null
                }}

                filterOptions={(options, params) => {
                    let filtered = filter(options, params);
          
                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        title: `Add "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                }}
                
                {...restProps}

            />
        </FormControl>
    )

}

export default AutoComplete
export {AutoComplete, AutoCompleteFromModel}