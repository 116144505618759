import { useState } from "react"

export default function useMenu() {

    const [open, setMenuOpen] = useState(false)
    const [anchorElm, setAnchorElm] = useState(null)
  
    const handleClose = () => {
        setMenuOpen(false)
    }

    const handleOpen = (event) => {
        setMenuOpen(true)
        setAnchorElm(event.currentTarget);
    }

	const toggleOpen = (event) => {
        setMenuOpen(!open)
        setAnchorElm(event.currentTarget);
    }

    return {
        open,
        setMenuOpen,
        anchorElm,
        setAnchorElm,
        handleClose,
        handleOpen,
		toggleOpen
    }
}

export {useMenu}