"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Config = void 0;

require("core-js/modules/web.dom-collections.iterator.js");

require("core-js/modules/web.url.js");

require("core-js/modules/web.url-search-params.js");

//import icon from "./logo.svg"
var Config = {
  type: "finance",
  label: "FreeAgent",
  name: "freeagent",
  url: "https://freeagent.com",
  icon: "icon",
  enabled: true,
  oauth: {
    authorizationURL: 'https://api.freeagent.com/v2/approve_app',
    tokenURL: 'https://api.freeagent.com/v2/token_endpoint',
    clientID: 'pjbgb_t5jhyBQiR2v4roCQ',
    callbackURL: 'http://localhost:5001/pbc-portal-150c3/us-central1/server/freeagent/auth/callback'
  }
};
exports.Config = Config;
Config.signin_url = new URL(Config.oauth.authorizationURL);
Config.signin_url.searchParams.set("client_id", Config.oauth.clientID);
Config.signin_url.searchParams.set("response_type", "code");
Config.signin_url.searchParams.set("redirect_uri", Config.oauth.callbackURL);