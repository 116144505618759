import {useState} from "react"
import {SpeedDial as MUISpeedDial, SpeedDialIcon, SpeedDialAction} from '@mui/material';

import {  
    BusinessOutlined, 
    PersonOutlineOutlined,
    WorkOutlined,
    BusinessCenterOutlined
} from '@mui/icons-material'

import { AddCompanyDrawer } from "./../../Components/Forms/AddCompany"
import { AddContactDrawer } from "./../../Components/Forms/AddContact"
import { AddProjectDrawer } from "./../../Components/Forms/AddProject"
import { AddOpportunityDrawer } from "./../../Components/Forms/AddOpportunity"

import withDrawerControl from "../../Hooks/withDrawerControl"

const Action = (props) => {

    const {toggleDrawer, drawerOpen, item, form, ...restProps} = props
    return(
        <>
            <SpeedDialAction 
                
                onClick={ () => {
                    toggleDrawer()
                    props.onClick()
                }}
                {...restProps}

            />
            <props.form
                isOpen = { drawerOpen } 
                onClose = { toggleDrawer } 
                item = { item }
                {...props.formProps ?? null}
            />
        </>
    )
}

const AddItemControl = withDrawerControl(Action)

const SpeedDial = () => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const actions = [
        { icon: <WorkOutlined />, name: 'Project', form : AddProjectDrawer },
        { icon: <BusinessCenterOutlined />, name: 'Opportunity', form : AddOpportunityDrawer },
        { icon: <PersonOutlineOutlined />, name: 'Contact', form : AddContactDrawer },
        { icon: <BusinessOutlined />, name: 'Company', form : AddCompanyDrawer },
      ];

    return (
        <>
            <MUISpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
            >
                {actions.map((action) => (
                    <AddItemControl
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        form = {action.form}
                       
                    />
                ))}
        </MUISpeedDial>
      </>
    )
}

export {SpeedDial}
export default SpeedDial