import {  Settings } from "@mui/icons-material"
import {  Grid, IconButton,  Stack, Typography } from "@mui/material"
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid-pro"
import React, { useState } from "react"
//@ts-ignore
import { styled, alpha, lighten, darken } from '@mui/material/styles';

import {DeleteAction} from "./Toolbar/ActionDelete"
import {EditAction} from "./Toolbar/ActionEdit"


interface GridSettingsToolbarProps extends React.HTMLAttributes<HTMLDivElement> {
	innerRef?: React.Ref<HTMLDivElement>;
  }

const GridSettingsToolbarRoot = styled('div', {
	name: 'MuiDataGrid',
	slot: 'Toolbar',
	overridesResolver: (props, styles) => styles.toolbar,
}) (({ theme }) => {

	const borderColor =
    theme.palette.mode === 'light'
      ? lighten(alpha(theme.palette.divider, 1), 0.88)
      : darken(alpha(theme.palette.divider, 1), 0.68);

	return {
		borderBottom: `1px solid ${borderColor}`,
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	};
})

const GridSettingsToolbar = React.forwardRef<HTMLDivElement, GridSettingsToolbarProps>(
	function GridSettingsToolbar(props, ref) {

    	const [open, setOpen] = useState(false)



    	return (
        <Stack
			direction = "row"
		>
          
          	{ open && (
            	<>
					<GridToolbarContainer ref={ref}>
      					<GridToolbarColumnsButton />
      					<GridToolbarDensitySelector />
      					<GridToolbarExport />
    				</GridToolbarContainer>
            	</>
          	)}
  
          	<IconButton color="primary" aria-label="settings" onClick = { () => setOpen(!open) }>
            	<Settings />
          	</IconButton>
        </Stack>
    	)
	}
)


const PortalToolbarActions = (props : {
	rows : Array<any>,
	editForm : JSX.Element | null
	editMultipleForm : JSX.Element | null
}) => {

	
	const {
		rows = [],
		editMultipleForm,
		editForm
	} = props

	

	let form
	if(rows.length > 1){
		form = editMultipleForm
	} else {
		form = editForm
	}

	const rowCount = rows.length


	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
	return (
		<Stack
			direction = "row"
			alignContent={"center"}
			alignItems={"center"}
			spacing = {2}
			sx = {{
				paddingLeft: 1.5,
				paddingRight: 1.5
			}}
		>
			<Typography>{rowCount} selected</Typography>
			<Stack
				direction = "row"
				spacing = {1}
			>
				{!showDeleteConfirmation && (form) && (
					<EditAction
						items = {rows}
						form = {form}
					 />
				)}
				
				<DeleteAction
					showConfirm = {showDeleteConfirmation}
					setShowConfirmation = {setShowDeleteConfirmation}
					items = {rows}
					onClick = { () => {
						setShowDeleteConfirmation(true)
					}}
				/>
			</Stack>
			
		</Stack>
	)
}

const PortalToolbarControls = (props : {
}) => {
	return (
		<Grid
				container
				direction="row"
				justifyContent="space-between"
				alignItems="center" 
			>
          		<GridToolbarContainer >
        	    	<GridToolbarFilterButton />    
					{/*
					<TextField
						variant="standard"
						// value={props.value}
						// onChange={props.onChange}
						placeholder="Search…"
						//className={classes.textField}
						InputProps={{
							startAdornment: <Search fontSize="small" />,
							endAdornment: (
								<IconButton
									title="Clear"
									aria-label="Clear"
									size="small"
									style={{ visibility: props.value ? 'visible' : 'hidden' }}
									onClick={props.clearSearch}
								>
									<Clear fontSize="small" />
								</IconButton>
							),
						}}
					/>
					*/}
      	    	</GridToolbarContainer>
      
          		<GridToolbarContainer >
            		<GridSettingsToolbar />
          		</GridToolbarContainer>
        	</Grid>
	)

}

const PortalToolbar = ( props : {
  children ?: any
  clearSearch?: React.MouseEventHandler<HTMLButtonElement>;
  value?:any;
  hasSelectedRows: boolean;
  rows ?: Array<any>
  editMultipleForm ?: JSX.Element
  editForm ?: JSX.Element
}) => {

	const {
		hasSelectedRows = false,
		rows = [],
		editMultipleForm = null,
		editForm = null
	} = props
	
      return (
		<GridSettingsToolbarRoot >
			{hasSelectedRows ? (
				<PortalToolbarActions 
					rows = {rows} 
					editMultipleForm = {editMultipleForm}
					editForm = {editForm}
				/>
			) : (
				<PortalToolbarControls />
			)}
			
		</GridSettingsToolbarRoot>
      )
}

/*

const MiniFilterButton = ( props : {
    label : string
    open :  boolean
    onClick : React.MouseEventHandler<HTMLButtonElement>

}) => {

    return (
        <Button
            id="demo-customized-button"
            aria-controls="demo-customized-menu"
            aria-haspopup="true"
            aria-expanded={props.open ? 'true' : undefined}
            variant="contained"
            disableElevation
            size="small"
            onClick= { props.onClick }
            endIcon={<KeyboardArrowDown />}
        >
            {props.label}
        </Button>
    )
}


const MiniFilter = (props : {
      label : string,
      items : Array<any>
      value : any
      column : string
      operation : string
      setValue : any
      setFilter : any
  }) => {

    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClose = () => { setOpen(false) }
    
    let selected = props.items.find( (item : any) => {
        return item.value === props.value
    })

    if(isUndefined(selected)){
        selected = props.items[0]
    }

      return (
          <>
        <MiniFilterButton
            label = {`${props.label} : ${selected.title} `}
            open = {open}
            onClick = { (event) => {
                setOpen(!open)
                setAnchorEl(event.currentTarget);
            }}
        />

        <Menu
            id="demo-customized-menu"
            MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            { props.items.map( (item : any, index: number) => (
                <MenuItem key={index} disableRipple onClick={ () => {
                    props.setValue(item.value) 
                    props.setFilter(props.column, props.operation, item.value) 
                    handleClose()
                } } >

                {item.title}
                </MenuItem>
            ))}
        </Menu>
      </>
    )
}


  const ProjectsToolbar = ( props : any) => {

    const [status, setStatus] = React.useState("all")
 
    const statusItems = [
      {
        value : -1,
        title : "All"
    },
       ...ProjectStatuses,

    ]
       
    const makeFilter = (column : string, operation : string, value : any) => {
        
        props.setFilters({ 
            columnField: column, 
            operatorValue: operation, 
            value: value,
            id : "quick_" + column
        })
    }

    return (
        <PortalToolbar >
            <ButtonGroup>
                <MiniFilter
                    label = "Status"
                    items = {statusItems}
                    value = {status}
                    column = "status"
                    operation = "="  
                    setValue = { setStatus }
                    setFilter = { makeFilter }
                />
            </ButtonGroup>
        </PortalToolbar>
    )
  }
  */

  export {PortalToolbar}
  export default PortalToolbar