//@ts-nocheck
import React from "react"
import {HZNForm} from "../Form/Form"

import {User} from "portal-datatypes" 
import { withFormDrawer } from "../Form/Drawer"

const EditUser = (props : any) => {
    return(
      <HZNForm
        Model = {User}
        fields = {User.getFormFields()}
        title = "Edit User"
        buttonText = "Update User"
        {...props}
        action = "edit"
        successMessage = "User Updated"
        showAddAnotherButton = {false}
      />
    )
}

const EditUserDrawer = withFormDrawer(EditUser)

export default EditUser
export {EditUser, EditUserDrawer}