
//@ts-ignore
import {User} from "portal-datatypes"

import Archive from "../../Components/Archive/Archive"
import {Users as UserColumns} from "../../Components/DataGrids/Columns/User";
import { AddUserDrawer } from "../../Components/Forms/AddUser"
import { EditUserDrawer } from "../../Components/Forms/EditUser"
import { AddItemControl } from '../../Components/Form/DrawerControl';

import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import {useState} from "react"

const Team = (props : any) => {

	const [visibilityModel, setVisibilityModel] = useState(props.columnVisibilityModel ?? {
		[GRID_CHECKBOX_SELECTION_COL_DEF.field] : true,
		title: true,
		type: true,
		actions: true,
		status: true,
		id : false,
	})

    return (
		<Archive 
			title = "Team Members"
			Model = { User }
			query = { {} }
			controls = {(
                <AddItemControl 
                    label = "Add User"
                    form = {AddUserDrawer}
                />
            )}
			gridProps = {{
				columns : UserColumns,
				columnVisibilityModel : visibilityModel,
				EditItemDrawer : EditUserDrawer,
				onColumnVisibilityModelChange : (newModel :any) => {
					setVisibilityModel(newModel)
				},
				disableColumnMenu : false,
			}}
		/>
	)
		
	/*
    return(
        <Archive
            title = "Team Members"
            controls = {(
                <AddItemControl 
                    label = "Add User"
                    form = {AddUserDrawer}
                />
            )}
			Model = {User}
        >
            <HZNDataGrid
                //@ts-ignore
                rows = {rows}
                columns={props.columns ?? UserColumns}
                disableColumnMenu={true}
            />
        </Archive>
    )
    */
}


export {Team}
export default Team