import {List} from "@mui/material"
import {Note} from "./Note"

const sortByDateCreated = (a,b) => {
	return (Date.parse(a.created_on) - Date.parse(b.created_on))
}

export const Notes = (props) => {
    return (
        <List>
            {props.notes.sort(sortByDateCreated).map( (note, key) => (
                <Note note={note} owner={props.model} key={key}/>
            ))}
        </List>
    )
}

export default Notes 