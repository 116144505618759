let firebaseConfig = {
    apiKey: "AIzaSyASgjAiYeek3F4eEN_YMZs6kb6bxhmI8mY",
    authDomain: "pbc-portal-150c3.firebaseapp.com",
    databaseURL: "https://pbc-portal-150c3-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "pbc-portal-150c3",
    storageBucket: "pbc-portal-150c3.appspot.com",
    messagingSenderId: "371586895016",
    appId: "1:371586895016:web:ac25266ae76cc0ea80469d",
    measurementId: "G-S2TXJDXCZM"
};

export default firebaseConfig
export {firebaseConfig}