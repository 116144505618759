"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = falsyValue;

function falsyValue(item) {
  if (Array.isArray(item)) {
    if (item.length) {
      return false;
    }
  } else if (item !== undefined && item !== null && typeof item === 'object') {
    if (Object.keys(item).length) {
      return false;
    }
  } else if (item) {
    return false;
  }

  return true;
}