"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ProcessStates = void 0;
const ProcessStates = {
  NOT_STARTED: 0,
  RUNNING: 1,
  FINISHED: 2,
  ERROR: 3
};
exports.ProcessStates = ProcessStates;
var _default = ProcessStates;
exports.default = _default;