import {Route as RouteType} from "./../Core/types"
import {Invoices as ListView} from '../Views/Invoices/Invoices'
import {Invoice as SingleView} from '../Views/Invoices/Invoice'

const Routes: Array<RouteType> = [
    {
        "path" : "/invoices/:guid",
        "view" : SingleView
    },
    {
        "path" : "/invoices",
        "view" : ListView
    }  
]

const Invoices = {
    routes : Routes
} 

export {Invoices}