import { useSelector } from 'react-redux'
import { GridRowModel } from "@mui/x-data-grid-pro";
import { isEqual,isNil,isArray } from "lodash";
import type {Collection} from "collect.js"

//@ts-ignore
import { ProcessStates } from 'portal-constants';

// ui
import { Box, Stack} from "@mui/material"
import HZNDataGrid from "../DataGrids/HZNDataGrid";
import {Heading} from "./Heading"
import {VIEWS} from "./ViewSwitcher"
import { Kanban } from "./../../Components/Kanban"


import {withHelmet} from "./../../Hooks/withHelmet"

interface FilterQueryObject {
	[index: string]: string|Array<string|number>;
}

let RawArchive = (props : {
  title : string
  controls ?: JSX.Element
  children ?: JSX.Element | JSX.Element[] 
  titleMenu ?:  JSX.Element|JSX.Element[]
  statusMenu ?: JSX.Element|JSX.Element[]
  Model : any
  gridProps ?: any
  kanbanProps ?: any
  query ?: any
  setStatus ?: Function,
  enableViewSwitcher ?: boolean,
  selectedView?: number,
  setSelectedView?: Function
}) => {
	
	
	const {
		children = false, 
		gridProps = false,
		kanbanProps = false,
		query = {},
		Model,
		selectedView = VIEWS.GRID,
		setSelectedView,
		...controlProps
		
	} = props

	const loading = useSelector( (state:any) => {
		return (state.app.populating.state !== ProcessStates.FINISHED)
	})

	let filteredQuery:FilterQueryObject = {}
	Object.keys(query).forEach( (key) => {
		// if the value of the key is empty, null, undefined or -1 (All) then dont set the filter query
		if((isNil(query[key])) || (query[key] === -1) ){
			return
		}
		// if the valye from the key is an array and thgat array contains -1(All) then skip it
		if(isArray(query[key]) && query[key].includes(-1)){
			return
		}
		filteredQuery[key] = query[key]
	})

	const selector = Model.Query(filteredQuery)
	const rows:GridRowModel = useSelector( selector, ( left:Collection<any>, right:Collection<any>) => {
    	return isEqual(left.toArray(), right.toArray())
  	} ).toArray()
	  

	const updateStage = (item: any, stage : number) => {
        item.set("stage", stage)
        item.save()
    }

	const updateStatus = (item: any, status : number) => {
        item.updateStatus(status)
    }

	
  return (

		<Box sx={{
			flexGrow: "1",
			overflow: "hidden",
			flexDirection: "column",
			display: "flex"
		}}>
     
		<Heading  
			{...controlProps} 
			selectedView={selectedView} 
			setSelectedView={setSelectedView} 
			status={props.query?.status}
			id = "box"
		/>

		<Box 
			flexGrow={1} 
			sx = {{
				paddingLeft: 2,
				paddingRight: 2,
				paddingBottom: 2
				
			}}
		>
			<Stack sx={{ height: "100%" }}>

				{(selectedView === VIEWS.GRID) && (
					<HZNDataGrid
						loading = {loading}
						rows = {rows}
						{...gridProps}
					/>
				)}

				{(selectedView === VIEWS.COLS) && (
					 <Kanban
					 	cards = {rows}
						updateStage = {updateStage}
						updateStatus = {updateStatus}
					 	{...kanbanProps}
				 	/>
				)}
				
      			
			</Stack> 
	  	</Box>
      </Box>
  )
}

//@ts-ignore
let Archive = withHelmet(RawArchive, (props: any) => {
	return {
		pageTitle : props.title
	}
})

export default Archive
export {Archive}