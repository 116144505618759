import React, {useState, useEffect} from 'react';
import {isUndefined} from 'lodash';
// material UI
import {Button, Typography, Snackbar, Grid, Box} from '@mui/material';
import { useFormikContext } from 'formik';
import {Alert} from '@mui/material';


const Container = ( props : any) => {
	
	let [showSnackBar, setShowSnackBar] = useState(false)
  	const formik = useFormikContext()

	  
  	const closeSnackBar = () => {
    	setShowSnackBar(false)
    	formik.setStatus(formik.initialStatus)
  	}

  

  useEffect( () => {
    
    if(
      (!isUndefined(formik.status)) &&
      (formik.status === "succeeded")
    ){
      setShowSnackBar(true)
    }
  }, [formik.status])


  return (
    
        <form onSubmit={formik.handleSubmit}>
            <Grid
                direction="column"
                container
            >
            <Typography color="textPrimary" variant="h5" gutterBottom >
            {props.title}
            </Typography>

            <Snackbar open={showSnackBar} autoHideDuration={1000} onClose={closeSnackBar}>
                <Alert severity="success">
                    {props.successMessage}
                </Alert>
            </Snackbar>

			{/**
			{formik.errors && (
				<Stack spacing={2}>
					{ Object.keys(formik.errors).map( (key) => (
						//@ts-ignore
						<Alert severity="error">{formik.errors[key]}</Alert>
					) ) }
				</Stack>
			)}
			 */}
            {props.children}

            <Box>
                <Button
                variant = "contained" 
                color = "primary"
                style= {{
                    marginRight : "1rem"
                }}
                type="submit"
                onClick = { (event) => {
                    console.log("Clicked", event)
                    formik.setFieldValue('action', props.action)
                    formik.setFieldValue('closeForm', true)
                    //@ts-ignore
                    formik.handleSubmit(event);
                }}
                >
                { props.buttonText }
                </Button>

                { props.showAddAnotherButton && (
                <Button
                    variant = "contained" 
                    color = "secondary"
                    type="submit"
                    onClick = { (event) => {
                    formik.setFieldValue('action', props.action)
                    formik.setFieldValue('closeForm', false)
                    //@ts-ignore
                    formik.handleSubmit(event);
                    }}
                >
                    Add Another
                </Button>
                )}
                </Box>
            </Grid>
        </form>
    
  )

}

export {Container}
export default Container;