import { GridColDef, GridValueFormatterParams, GridValueGetterParams } from "@mui/x-data-grid-pro";

import CurrencyOutput from "../../../Core/utils/CurrencyOutput";
import {RelatedLink as RelatedLinkColumnType} from "../ColumnTypes/RelatedLink"
import {Link as LinkColumnType} from "../ColumnTypes/Link"
import {DateColumnType} from "./../ColumnTypes/Date"

const Invoices: GridColDef[] = [
    
    { 
      field: 'reference', 
      headerName: '#', 
      width: 100,
      ...LinkColumnType   
	  
    },
	{ 
		field: 'companies', 
		headerName: 'Company', 
		width: 170,
		...RelatedLinkColumnType
	  },  
    { 
      field: 'projects', 
      headerName: 'Project', 
      width: 170,
      ...RelatedLinkColumnType
    },
   
    { 
      field: 'value', 
      headerName: 'Value', 
      width: 140,
      valueFormatter: (params: GridValueFormatterParams) => {
          return CurrencyOutput(params.value as number)
      }
  },
  { 
    field: 'status', 
    headerName: 'Status', 
    width: 140,
    valueGetter:  (params: GridValueGetterParams ) => {
        return params.row.status
    }}, 
  { 
    field: 'paid', 
    headerName: 'Ammount Paid', 
    width: 140,
    valueFormatter: (params: GridValueFormatterParams) => {
        return CurrencyOutput(params.value as number)
    }
  },
  { 
    field: 'due', 
    headerName: 'Due', 
    width: 140,
    valueFormatter: (params: GridValueFormatterParams) => {
        return CurrencyOutput(params.value as number)
    }
  },
  { 
    field: 'due_on', 
    headerName: 'Due Date', 
    width: 140,
	...DateColumnType
  },
  { 
    field: 'issued_on', 
    headerName: 'Issued Date', 
    width: 140,
	...DateColumnType   
  },
  { 
    field: 'paid_on', 
    headerName: 'Paid On', 
    width: 140,
	...DateColumnType   
  },
 
  ];

  export default Invoices
  export {Invoices}