import {Paper, Stack, Typography, Divider, Chip, Box} from "@mui/material";
import CardContainer from "./CardContainer"
import Card from "./Card"
import CurrencyOutput from "../../Core/utils/CurrencyOutput";

import { useDrop } from 'react-dnd'

import {isNil} from "lodash"

import { AddItemControl } from "../Form/DrawerControl";
import { AddOpportunityDrawer } from "./../Forms/AddOpportunity"

const ColumnHeader = (props) => {
    return (
        <>
        <Stack 
            direction="row"
            justifyContent="space-between"
        >
            <Typography
                variant="h6" 
                component="h3"
                fontWeight={"bold"}
            > 
                {props.heading} 
            </Typography>
            <Chip label = {props.count} />
        </Stack>
        <Divider />
        </>
    )
}

const ColumnFooter = (props) => {
    return (
        <>
        <Divider />
        <Stack
            direction="row"
            justifyContent="center"
        >
            <Typography>
                Total : {props.value}
            </Typography>
        </Stack>
        </>
    )
}

const AddButton = (props) => (
    
    <AddItemControl 
        label = "Add"
        form = {AddOpportunityDrawer}
        ButtonProps = {{
            size : "small",
            variant : "outlined"
        }}
        formProps = {{
            initialValues : {
                stage: [props.stage],
                status: [0],
            }
        }}
    />
)



const Column = (props) => {

    const [dropProps, drop] = useDrop( () => ({
        "accept" : "CARD",
        drop: (item, monitor) => {
            props.updateStage(item, props.index)
        },
        collect : (monitor, props) => {
            return {
                ...props,
                isHovered : monitor.isOver(),
                item : monitor.getItem()
            }
        },
       
    }))


    return (
            <Paper
                ref={drop} 
                elevation={1} 
                sx={{
                    "minWidth" : "300px",
                    backgroundColor: dropProps.isHovered ? "primary.light" : 'rgba(0,0,0,0.01)',
                    ":last-of-type" : {
                        marginRight: 1.5
                    }
                }} 
            >
                <Stack
                    spacing={2} 
                    direction="column"
                    sx = {{
                        padding: 2,
                        height:'100%'
                    }}
                >   
                    <ColumnHeader 
                        heading =  {props.heading} 
                        count = {props.cards.length}
                    />
                    
                    
                
                    <Box flexGrow={1}>
                    

                    <CardContainer 
                        index = {props.index} 
                        updateStage = {props.updateStage}
                        AddControl = {<AddButton stage={props.index}/>}
                        
                    >
                        
                    {!isNil(dropProps.item) && (dropProps.item.stage !== props.index) && dropProps.isHovered && (
                            <Card model = {dropProps.item} key={0}/>
                    )}

                    { props.cards.map( (card, index) => (
                        <Card model = {card} key={index} />
                    ))}

                    </CardContainer>

                  
                    </Box>
                    <ColumnFooter 
                        alignSelf = "flex-end"
                        value = {CurrencyOutput(props.cards.reduce( (value, card ) => {
                            return (value + parseInt(card.value))
                        }, 0))}
                    />

                </Stack>

            </Paper>
    )
}


export {Column}
export default Column