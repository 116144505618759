import { Cancel, Delete } from "@mui/icons-material"
import { Box, Button, IconButton } from "@mui/material"
import { MouseEventHandler } from "react"

const DeleteAction = (props : {
    showConfirm : boolean
    onClick : MouseEventHandler
	setShowConfirmation : Function
    items : any
  }) => {
	
    if(props.showConfirm){
      return (
      <Box>
        <Button
          variant="outlined"
          color="warning"
          startIcon={<Delete />}
          size = "small"
          onClick={ (event) => {
            props.items.delete()
            props.onClick(event)
          }} 
        >
          Confirm? (Delete {props.items.length} items?)
        </Button>

        <IconButton
          	size = "small"
          	edge = {false}
          	onClick={() => {
			  props.setShowConfirmation(false)
			}} 
        >
          <Cancel />
        </IconButton>
      </Box>
      )
    }

    return (
		<Button
			variant="contained"
			disableElevation
			size="small"
			startIcon = {<Delete />}
			onClick = {() => {
				props.setShowConfirmation(true)
			}}
		>
			Delete
		</Button>
    )
  }

  export default DeleteAction
  export {DeleteAction}