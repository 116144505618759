"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.attr = attr;
exports.belongsTo = belongsTo;
exports.foreignKey = foreignKey;
exports.hasMany = hasMany;
exports.id = id;
exports.picked = picked;

function attr() {
  return {
    'type': "attr"
  };
}

function picked(options) {
  return {
    'type': "picked",
    "options": options
  };
}

function id() {
  return {
    'type': "id"
  };
}

function hasMany(model) {
  let key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  let relationship = {
    'type': "relationship",
    'relationship': {
      'type': "hasMany",
      'model': model
    }
  };

  if (key) {
    relationship["relationship"].key = key;
  }

  return relationship;
}

function foreignKey(model, foreignKey) {
  return {
    'type': "relationship",
    'relationship': {
      'type': "foreignKey",
      'model': model,
      'foreignKey': foreignKey
    }
  };
}

function belongsTo(model, foreignKey) {
  return {
    'type': "relationship",
    'relationship': {
      'type': "belongsTo",
      'model': model,
      'foreignKey': foreignKey
    }
  };
}