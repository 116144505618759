//@ts-nocheck

import { Stack } from "@mui/material"
import {Opportunity as Model} from "portal-datatypes"
import {withView} from "../../Hooks/withView"


import Frame from "../../Components/Frame"

import {DealRecords, Overview, DealLeftSidebar} from "./../../Components/Opportunities"


let Opportunity = (props : any) => {

    const {model} = props
	
    return (
        <Frame> 
		
            <Overview {...props} />
            <Stack 
                direction="row"
                overflow={"hidden"} 
                flexGrow = {1}
            >
                <DealLeftSidebar model = {model}/>
                <DealRecords model = {model} />
                
            </Stack>
        </Frame>
    )
}



Opportunity = withView(Opportunity, Model)

export default Opportunity
export { Opportunity }