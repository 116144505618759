import { firebaseConfig } from "./config"

//import { enableMultiTabIndexedDbPersistence, initializeFirestore, CACHE_SIZE_UNLIMITED, getFirestore, collection } from "firebase/firestore"; 
import { connectFunctionsEmulator } from "firebase/functions";
import { connectFirestoreEmulator } from "firebase/firestore";
//import { getAuth } from "firebase/auth";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";

let FireBase;
let FireStore;
let FireFunctions;
let FireAuth;

function createFirebase(){
    console.log("init FireBase")
    // Firebase
    FireBase = firebase.initializeApp(firebaseConfig);

    // FireStore
    //FireStore = initializeFirestore(FireBase, {
    //    cacheSizeBytes: CACHE_SIZE_UNLIMITED
    //});
    //enableMultiTabIndexedDbPersistence(FireStore)
 
	FireStore = FireBase.firestore()

    // Auth
    FireAuth = FireBase.auth()

    // CloudFunctions
    FireFunctions = FireBase.functions()
    if (process.env.NODE_ENV === 'development') {
        connectFunctionsEmulator(FireFunctions, "localhost", 5001);
		//connectFirestoreEmulator(FireStore, "localhost", 5002)
    }

    return FireBase
}

export default FireBase;
export {
	FireBase, 
	FireStore,
	FireFunctions, 
	FireAuth, 
	createFirebase
}
