import {  useFormikContext } from 'formik';

function withFormik( WrappedComponent ){


   return ( props ) => {  

        // hooks
        let formik = useFormikContext()

        // formik props
        let { 
            values, 
            touched, 
            errors 
        } = formik
        
        return(
            <WrappedComponent 
                {...props} 
                formik= {formik}  
                value = {values[props.name]} 
                touched = {touched[props.name]} 
                errors  = {errors[props.name]} 
                setValue = {formik.setFieldValue}
            />
        )
   }
}

export {withFormik}
export default withFormik