"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Schema = void 0;

var _portalModel = require("portal-model");

var _Users = require("./../Users");

const Schema = {
  'id': (0, _portalModel.id)(),
  'content': (0, _portalModel.attr)(),
  'user': (0, _portalModel.belongsTo)(_Users.User),
  'parent': (0, _portalModel.attr)(),
  'created_on': (0, _portalModel.attr)()
};
exports.Schema = Schema;
var _default = Schema;
exports.default = _default;