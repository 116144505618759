//@ts-nocheck

// components
import { Companies as CompaniesColumns } from "./../../Components/DataGrids/Columns/Companies"
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';

import {Companies} from "./Companies"

const Clients = ( props ) => {

    return (
        <Companies
            title = "Clients"
            columns = {CompaniesColumns}
			columnVisibilityModel={{
				[GRID_CHECKBOX_SELECTION_COL_DEF.field] : true,
				title: true,
				type: true,
				actions: true,
				domain: false,
				id : false,
				value : true,
				invoiced : true,
				received : true,
				unpaid : true,
				to_invoice: true
			}}
            {...props}
        />
    )

}

export default Clients
export {Clients}