import { useHotkeys } from 'react-hotkeys-hook';
import {useContext} from "react"

import { SearchContext } from '../../Components/Search';

const KeyboardShortcutProvider = (props) => {
    
    const search = useContext(SearchContext)
    useHotkeys('cmd+k', () => search.openSearchModel() );

    return (props.children)
}

export {KeyboardShortcutProvider}
export default KeyboardShortcutProvider