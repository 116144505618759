"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Project = void 0;

require("core-js/modules/es.array.includes.js");

require("core-js/modules/web.dom-collections.iterator.js");

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = require("lodash");

var yup = _interopRequireWildcard(require("yup"));

var _portalConstants = require("portal-constants");

var _portalModel = require("portal-model");

var _Slice = _interopRequireDefault(require("./Slice.js"));

var _Model = _interopRequireDefault(require("./../Companies/Model"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//@ts-nocheck 
//external
// types
// data
//import InvoiceCollection from "./../Invoices/InvoiceCollection"
// views
class Project extends _portalModel.Model {
  // these make typescript stop complaining
  // TODO : Make Model parent typescript and use these better
  static loadRoutes() {//this.routes = require("./Routes").Routes
  }

  // I'm overriding the constructor at the moment 
  // to stop the getters being get from the data array
  constructor(args) {
    super(args, false);
    this.cached = {};
  }

  static loadSchema() {
    this.schema = require("./Schema.js").Schema;
  }

  hasParent() {
    if (!(0, _lodash.isUndefined)(this.parents) && this.parents && Object.keys(this.parents).length > 0) {
      return true;
    }

    return false;
  }

  get static() {
    return this.constructor;
  }

  get invoiceCollection() {
    //let ic = new InvoiceCollection();
    return this.invoices;
  }

  get children() {
    let children = [];
    let ids = this.data.children;

    if ((0, _lodash.isUndefined)(ids)) {
      return children;
    }

    let keys = Object.keys(ids);
    keys.forEach(key => {
      children.push(this.static.get(key)());
    });
    return children;
  }

  get parents() {
    let parents = [];
    let ids = this.data.parent;

    if ((0, _lodash.isUndefined)(ids)) {
      return parents;
    }

    let keys = Object.keys(ids);
    keys.forEach(key => {
      parents.push(this.static.get(key)());
    });
    return parents;
  }

  getStatusLabel() {
    if ((0, _lodash.isUndefined)(this.status)) {
      return "-";
    }

    let status = ProjectStatuses.find(stat => {
      return stat.value === this.status;
    });

    if (!(0, _lodash.isUndefined)(status)) {
      return status.title;
    } else {
      return this.status;
    }
  }

  getTypeLabel() {
    if ((0, _lodash.isUndefined)(this.type)) {
      return "-";
    }

    let type = ProjectTypes.find(t => {
      return t.value === this.type;
    });

    if (!(0, _lodash.isUndefined)(type)) {
      return type.title;
    } else {
      return this.type;
    }
  }

  get budget() {
    return this.cacheCall("budget", () => {
      if (this.data.budget && this.data.budget !== 0) {
        return this.data.budget;
      }

      return this.value;
    }, 0);
  }

  get value() {
    return this.cacheCall("value", () => {
      let value = 0;
      this.invoices.forEach(invoice => {
        value = value + parseInt(invoice.value);
      });
      return value;
    }, 0);
  }

  get invoiced() {
    return this.cacheCall("invoiced", () => {
      let value = 0;
      this.invoices.forEach(invoice => {
        value = value + parseInt(invoice.value);
      });
      return value;
    }, 0);
  }

  get paid() {
    return this.cacheCall("paid", () => {
      let value = 0;
      this.invoices.filter(invoice => {
        return invoice.status === "Paid" || invoice.status === "Part written-off";
      }).forEach(invoice => {
        value = value + parseInt(invoice.data.paid_value);
      });
      return value;
    }, 0);
  }

  get remaining() {
    let written_off = 0;
    this.invoices.filter(invoice => {
      return invoice.status === "Written-off" || invoice.status === "Part written-off";
    }).forEach(invoice => {
      if (invoice.status === "Written-off") {
        written_off = written_off + parseInt(invoice.value);
      } else {
        written_off = written_off + parseInt(invoice.due);
      }
    });
    return this.budget - this.paid - written_off;
  }

  get due() {
    let due = 0;
    this.invoices.filter(invoice => {
      return invoice.status === "Overdue" || invoice.status === "Open";
    }).forEach(invoice => {
      due = due + invoice.due;
    });
    return due;
  }

  static attachToCompanies() {
    const projects = this.getAll()().toArray();
    let promises = projects.filter(project => {
      if ((0, _lodash.isUndefined)(project.data.company)) {
        return false;
      }

      return true;
    }).map(project => {
      const company = _Model.default.get(project.data.company)();

      console.log(company.data.projects);

      if ((0, _lodash.isEmpty)(company.data.projects)) {
        company.data.projects[0] = project.id;
        return company.save();
      } else if ((0, _lodash.isUndefined)(company.data.projects[0])) {
        company.data.projects[0] = project.id;
        return company.save();
      } else if ((0, _lodash.isObject)(company.data.projects[0])) {
        console.log(company.data.projects[0]);
        company.data.projects[0] = project.id;
        let r = company.save();
        console.log("updated", company);
        return r;
      } else if (company.data.projects.length > 0) {
        if (!company.data.projects.includes(project.id)) {
          company.data.projects.push(project.id);
          console.log("addProject");
          return company.save();
        }
      }
    });
    Promise.all(promises);
  }

  isRetainer() {
    if (this.type[0].value === 1) {
      return true;
    }

    return false;
  }

  get retainer() {
    if (!this.isRetainer()) {
      return false;
    }

    console.log("isRetainer");
    /*
    if(isUndefined(this.data.retainer)){
    	console.log("this retainer doesn't exist")
    	let retainer = new Retainer({
    		"project" : this.getID()
    	})
    		retainer.save();
    	this.data.retainer = {}
    	this.data.retainer[retainer.getID()] = true
    	this.update()
    }
    */

    let retainer = this.getRelated("retainer");
    console.log(retainer);
    return retainer !== null && retainer !== void 0 ? retainer : null;
  }

}

exports.Project = Project;
(0, _defineProperty2.default)(Project, "schema", void 0);
(0, _defineProperty2.default)(Project, "routes", void 0);
(0, _defineProperty2.default)(Project, "urlPath", "project");
(0, _defineProperty2.default)(Project, "label", "Project");
(0, _defineProperty2.default)(Project, "form", {
  title: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'title',
      name: 'title',
      label: "Name",
      placeholder: "Acme Inc.",
      helperText: "project name",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  company: {
    type: "relationship",
    form: true,
    field: {
      type: "autocomplete",
      id: 'company',
      name: 'company',
      label: "Company",
      placeholder: "Acme Inc.",
      helperText: "company name",
      order: 1,
      model: _Model.default,
      options: () => {
        return _Model.default.getAll();
      },
      createOptions: {
        type: _portalConstants.CompanyLifeCycleStages[0].value
      }
    }
  },
  type: {
    type: "string",
    form: true,
    field: {
      type: "select",
      id: 'type',
      name: 'type',
      label: "Type",
      helperText: "Project Type",
      validation: yup.string().required(),
      order: 1,
      initialValue: "",
      options: _portalConstants.ProjectTypesTaxonomy.collection
    }
  },
  budget: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'budget',
      name: 'budget',
      label: "Budget",
      placeholder: "£1,000,000.",
      helperText: "Project Cost",
      validation: yup.string().required(),
      order: 1,
      initialValue: ""
    }
  },
  harvest_project_id: {
    type: "string",
    form: true,
    field: {
      type: "text",
      id: 'harvest_project_id',
      name: 'harvest_project_id',
      label: "Harvest Project ID",
      placeholder: "28671602",
      helperText: "Harvest Project ID",
      order: 1,
      initialValue: ""
    }
  },
  status: {
    type: "string",
    form: true,
    field: {
      type: "select",
      id: 'status',
      name: 'status',
      label: "Status",
      helperText: "Project Status",
      validation: yup.string().required(),
      order: 1,
      options: _portalConstants.ProjectStatusesTaxonomy.collection,
      initialValue: _portalConstants.ProjectStatusesTaxonomy.IN_PROGRESS
    }
  }
});
(0, _defineProperty2.default)(Project, "slice", _Slice.default);
(0, _defineProperty2.default)(Project, "FormEditMultiple", {
  type: Project.form.type,
  status: Project.form.status
});
var _default = Project;
exports.default = _default;