//@ts-nocheck
import React from "react"
import {HZNForm} from "../Form/Form"

import {Company} from "portal-datatypes" 
import { withFormDrawer } from "../Form/Drawer"

const EditMultipleCompanies = (props : any) => {

	let {
		items
	} = props

	items = Company.get(items)().toArray()
	const itemCount = items.length
	
    return(
      <HZNForm
        Model = {Company}
        fields = {Company.getFormFields("FormEditMultiple")}
		validation = {Company.getFormFieldsValidation("FormEditMultiple")}
        title = {`Edit ${itemCount} Companies`}
        buttonText = {`Update ${itemCount} Companies`}
		successMessage = "Companies Updated" 

        {...props}
		items = {items}
        action = "editMultiple"
        showAddAnotherButton = {false}
		useModelInitialValues = {false}
      />
    )
}

const EditMultipleCompaniesDrawer = withFormDrawer(EditMultipleCompanies)

export default EditMultipleCompanies
export {EditMultipleCompanies, EditMultipleCompaniesDrawer}