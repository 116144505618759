"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userSlice = exports.default = void 0;

var _portalRedux = require("portal-redux");

// selector helping
// firebase slice
const userSlice = new _portalRedux.FirebaseSlice({
  table: "users",
  name: 'users'
});
exports.userSlice = userSlice;
var _default = userSlice;
exports.default = _default;