
import {RouteCollection, ActionRouteCollection} from "../types"

import Settings from "../../Views/Settings/Settings"
import Dashboard from "../../Views/Dashboard"
import RequestPassword from "../../Views/Auth/RequestPassword"
import ResetPassword from "../../Views/Auth/ResetPassword"
import ConnectHarvestProjects from "../../Views/Settings/ConnectHarvestProjects"

let ActionRoutes : ActionRouteCollection = {}

function getActionRoutes(){
	return ActionRoutes
}

function addActionRoute(route : string, action : Function){
	ActionRoutes[route] = action
}

function addActionRoutes(routes : ActionRouteCollection){
	ActionRoutes = {
		...ActionRoutes,
		...routes
	}
}

const Routes: RouteCollection = [
    {
        "path" : "/settings/harvest",
        "view" : ConnectHarvestProjects,
        "private" : true
    },
    {
       "path" : "/settings",
       "view" : Settings,
       "private" : true
    },
    {
        "path" : "/auth/request-password",
        "view" : RequestPassword,
        "private" : false
    },
    {
        "path" : "/auth/reset-password",
        "view" : ResetPassword,
        "private" : false
    },
    {
        "path" : "/",
        "view" : Dashboard,
        "private" : true
    }
]



export {Routes, getActionRoutes, addActionRoutes, addActionRoute}
export default Routes

