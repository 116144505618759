import { Stack, Typography, Box } from "@mui/material"
import CurrencyOutput from "../../Core/utils/CurrencyOutput"
import {OpportunityTypes, OpportunityStages, OpportunityStatuses} from "portal-constants"
import {Link} from "../../Core/Navigation/Routing"
import {ChipList} from "../ChipList"
import EntityIcon from "../EntityIcon"

const Overview = (props) => {

    const {model} = props

    return (
        <Stack 
            direction="row" 
            alignContent = "center"
            alignItems = "center"
            justifyContent = "space-between"
            sx = {{
                padding:"2rem",
                backgroundColor: '#eee',
            }}
        >
                <Stack spacing={2}>
                    <Stack 
                        direction="row" 
                        spacing={1}
                        alignContent = "center"
                        alignItems = "center"
                    >
                        <Typography
                            component = "h1"
                            variant = "h2"
                            fontWeight={700}
                        >{model.title}</Typography>
                        <Typography
                             component = "span"
                             variant = "h3"
                             fontWeight={700}
                        >
                            :
                        </Typography>
                        <Typography
                             component = "span"
                             variant = "h3"
                             fontWeight={700}
                        >
                            {CurrencyOutput(model.value)}
                        </Typography>
                    </Stack>
                    <Stack 
                        direction="row" 
                        spacing={2}
                        alignContent = "center"
                        alignItems = "center"
                    >
                        <EntityIcon model={model.companies} />
                        
                        <Typography
                            component = "span"
                            variant = "h4"
                        >
                            
                            { model.companies.link && (
                                <Link to={ model.companies.link() }>{model.companies.title}</Link>
                            )}
                        </Typography>

                        
                    
                        <ChipList 
                            types = {props.model.status}
                            options = {OpportunityStatuses}
                        />

                        <ChipList 
                            types = {props.model.stage}
                            options = {OpportunityStages}
                        />

                        <ChipList 
                            types = {props.model.type}
                            options = {OpportunityTypes}
                        />

                    </Stack>
                </Stack>
                <Box>
                    <></>
                </Box>
            </Stack>
    )
}

export {Overview}
export default Overview