import { useState } from "react"

import { GridActionsCellItem } from "@mui/x-data-grid-pro"
import { Edit } from "@mui/icons-material"

const EditAction = (props : {
  item : any,
  form: any
}) => {
    
    let [showEdit, setShowEdit] = useState<boolean>(false)
    return (
        <>
        <GridActionsCellItem 
            icon={<Edit />} 
            label="Edit" 
            onClick = {() => {
                setShowEdit(!showEdit)
            }}
            
        />

        {showEdit && (
          <props.form 
            isOpen = {true}
            onClose = { () => {setShowEdit(false)}}
            item = {props.item}
          />
        )}
      </>
    )
  }

  export default EditAction
  export {EditAction}


